import { useCallback, useState } from 'react'
import { StartRecInterviewVideoUploadResponse } from '@blue-agency/proton/web/v2/my_hutt_bff'
import assert from 'assert'
import {
  useRequestCompleteRecInterviewVideoUpload,
  useRequestStartRecInterviewVideoUpload,
} from '@/services/bffService'
import { storeAnswerOnLocalStorage } from '../../storeAnswer'
import { Guid } from '../../types'

type Args = {
  recInterviewGuid: Guid
  selectionStepGuid: Guid
  questionGuid: Guid
}

export function useMultipartUpload(args: Args) {
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const { requestStartRecInterviewVideoUpload } =
    useRequestStartRecInterviewVideoUpload()
  const { requestCompleteRecInterviewVideoUpload } =
    useRequestCompleteRecInterviewVideoUpload()

  const upload = useCallback(
    async (blob: Blob) => {
      setProgress(0)
      setIsError(false)
      setIsUploaded(false)
      setIsUploading(true)
      const parts = splitBlob(blob)

      let startUploadRes: StartRecInterviewVideoUploadResponse
      try {
        startUploadRes = await requestStartRecInterviewVideoUpload({
          recInterviewGuid: args.recInterviewGuid,
          questionGuid: args.questionGuid,
          contentType: blob.type,
          partsNum: parts.length,
        })
      } catch (err) {
        setIsError(true)
        setIsUploading(false)
        throw err
      }

      type UploadPartResult = {
        partNumber: number
        etag: string
      }

      let uploadPartResults: UploadPartResult[]
      try {
        uploadPartResults = await Promise.all<UploadPartResult>(
          parts.map(async (part, i) => {
            const res = await fetch(startUploadRes.getUrlsList()[i], {
              method: 'PUT',
              body: part,
              headers: {
                'Content-Type': blob.type,
              },
              mode: 'cors',
            })

            setProgress((prev) => (prev += 100 / parts.length))
            const etag = res.headers.get('ETag')
            assert(etag)
            return {
              partNumber: i + 1,
              etag,
            }
          })
        )
      } catch (err) {
        setIsError(true)
        setIsUploading(false)
        throw err
      }

      try {
        await requestCompleteRecInterviewVideoUpload({
          key: startUploadRes.getKey(),
          parts: uploadPartResults,
          uploadId: startUploadRes.getUploadId(),
          recInterviewGuid: args.recInterviewGuid,
        })
      } catch (err) {
        setIsError(true)
        setIsUploading(false)
        throw err
      }

      storeAnswerOnLocalStorage(args.selectionStepGuid, args.questionGuid, {
        questionGuid: args.questionGuid,
        kind: 'Video',
        key: startUploadRes.getKey(),
      })
      setIsError(false)
      setIsUploading(false)
      setIsUploaded(true)
      return startUploadRes.getKey()
    },
    [
      args.questionGuid,
      args.selectionStepGuid,
      args.recInterviewGuid,
      requestCompleteRecInterviewVideoUpload,
      requestStartRecInterviewVideoUpload,
    ]
  )

  const resetState = () => {
    setIsError(false)
    setIsUploading(false)
    setIsUploaded(false)
    setProgress(0)
  }

  return { progress, upload, isUploading, isError, isUploaded, resetState }
}

function splitBlob(blob: Blob): Blob[] {
  const partSize = 5 * 1024 * 1024 // 5MB/chunk
  const partNum = Math.ceil(blob.size / partSize)
  const parts: Blob[] = []
  for (let i = 0; i < partNum; i++) {
    const startByte = partSize * i
    const endByte = partSize * (i + 1)
    const part = blob.slice(startByte, endByte)
    parts.push(part)
  }

  return parts
}
