import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { assertIsDefined } from '@/assertions'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'

export const dateFormatString = 'yyyy/MM/dd(E) HH:mm'

export function formatTime(d: Date): string {
  return format(d, dateFormatString, { locale: ja })
}

export function assertIsRecInterviewDefined(
  state: State
): asserts state is NonNullable<Required<State>> {
  assertIsDefined(state.organization)
  assertIsDefined(state.deadline)
  assertIsDefined(state.recInterviewStatus)
  assertIsDefined(state.introMessage)
  assertIsDefined(state.outroMessage)
}
