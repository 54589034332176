import React, { useEffect, useRef } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { useStopwatch } from 'react-timer-hook'
import styled, { css } from 'styled-components'
import { Status } from './reducer'

type Props = {
  status: Status
}

export const Stopwatch: React.VFC<Props> = ({ status }) => {
  const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false })
  const startedRef = useRef(false)
  const pausedRef = useRef(false)

  useEffect(() => {
    if (status === 'capturing' && !startedRef.current) {
      start()
      startedRef.current = true
    }
    if (status === 'captured' && !pausedRef.current) {
      pause()
      pausedRef.current = true
    }
  }, [status, start, pause])

  if (status === 'captured') {
    return null
  }

  return (
    <StopwatchTxt>
      {`${zeroPadding(minutes)}:${zeroPadding(seconds)}`}
    </StopwatchTxt>
  )
}

const zeroPadding = (num: number) => (num + '').padStart(2, '0')

const StopwatchTxt = styled(Txt).attrs({ color: theme.color.white[1] })`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: 26px;
        `
      : css`
          font-size: 22px;
        `}
`
