let audioContext: AudioContext

export const getAudioContext = () => {
  if (!audioContext) {
    audioContext = new (window.AudioContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any).webkitAudioContext as AudioContext))()
  }
  return audioContext
}
