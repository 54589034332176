import React from 'react'
import { Icon, Txt, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { PlaybackRate, PlaybackRateOption, Rate } from './PlaybackRate'
import { SeekBar } from './SeekBar'
import { VolumeRange } from './VolumeRange'

export type PlayerControlsProps = PropsWithClassName<{
  currentTime: number
  duration: number
  isPlaying: boolean
  isMuted: boolean
  /** range: [0.0, 1.0] */
  volume: number
  playbackRate: Rate
  playbackRateOptions: PlaybackRateOption[]
  isFullscreen: boolean
  onSeekChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSeekEnd: () => void
  onSeekStart: () => void
  onVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPlay: () => void
  onPause: () => void
  onMuteToggle: () => void
  onPlaybackRateChange: (rate: Rate) => void
  onFullscreenToggle: () => void
}>

export const PlayerControls: React.VFC<PlayerControlsProps> = ({
  className,
  currentTime,
  duration,
  isPlaying,
  isMuted,
  volume,
  playbackRate,
  playbackRateOptions,
  isFullscreen,
  onSeekChange,
  onSeekEnd,
  onSeekStart,
  onVolumeChange,
  onPlay,
  onPause,
  onMuteToggle,
  onPlaybackRateChange,
  onFullscreenToggle,
}) => {
  return (
    <Wrapper className={className}>
      <SeekBarWrapper $isFullscreen={isFullscreen}>
        <SeekBar
          duration={duration}
          currentTime={currentTime}
          onSeekChange={onSeekChange}
          onSeekEnd={onSeekEnd}
          onSeekStart={onSeekStart}
        />
      </SeekBarWrapper>
      <PlayPause
        onClick={isPlaying ? onPause : onPlay}
        $isFullscreen={isFullscreen}
      >
        <PlayPauseIcon
          name={isPlaying ? 'pause' : 'play'}
          $isFullscreen={isFullscreen}
        />
      </PlayPause>
      <Bottom>
        <Left>
          <Txt color={theme.color.white[1]}>
            {toHms(currentTime)} / {toHms(duration)}
          </Txt>
        </Left>
        <Right>
          <VolumeRange
            volume={volume}
            isMuted={isMuted}
            onVolumeChange={onVolumeChange}
          />
          <IconWrapper onClick={onMuteToggle}>
            <Icon name={isMuted ? 'mute' : 'volume'} />
          </IconWrapper>
          <IconWrapper onClick={onFullscreenToggle}>
            <Icon name={isFullscreen ? 'exit-fullscreen' : 'fullscreen'} />
          </IconWrapper>
        </Right>
      </Bottom>
      <PlaybackRateWrapper>
        <PlaybackRate
          rate={playbackRate}
          options={playbackRateOptions}
          onChange={onPlaybackRateChange}
        />
      </PlaybackRateWrapper>
    </Wrapper>
  )
}

const toHms = (seconds: number) => {
  const sec = Math.floor(seconds) % 60
  const min = Math.floor(seconds / 60) % 60
  const hour = Math.floor(seconds / 3600)
  return [
    ('00' + hour).slice(-2),
    ('00' + min).slice(-2),
    ('00' + sec).slice(-2),
  ].join(':')
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 12px;
  width: 100%;
  padding: 0 12px 0 20px;
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.color.white[1]};
`

const PlayPause = styled.div<{ $isFullscreen: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  color: ${theme.color.white[1]};
  cursor: pointer;
  ${(props) =>
    props.$isFullscreen
      ? css`
          width: 40px;
          height: 40px;
        `
      : css`
          width: 32px;
          height: 32px;
        `}
`

const PlayPauseIcon = styled(Icon)<{ $isFullscreen: boolean }>`
  ${(props) =>
    props.$isFullscreen
      ? css`
          width: 40px;
          height: 40px;
        `
      : css`
          width: 32px;
          height: 32px;
        `}
`

const SeekBarWrapper = styled.div<{ $isFullscreen: boolean }>`
  position: absolute;
  ${(props) =>
    props.$isFullscreen
      ? css`
          bottom: 56px;
          width: calc(100% - 40px);
          margin: 0 20px;
        `
      : css`
          width: 100%;
          bottom: -4px;
        `}
`

const PlaybackRateWrapper = styled.div`
  margin: 12px;
  display: flex;
  justify-content: flex-end;
`
