import { theme, Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Box } from '@/components/Box'
import { Spacer } from '@/components/Spacer'

type Props = PropsWithClassName<{
  limitation: limitation
}>
type limitation = {
  maxDurationSeconds: number
  remainsDurationSeconds: number
  maxRecCount: number
  remainsRecCount: number
}
export const Limitation: React.VFC<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      {props.limitation.maxDurationSeconds > 0 && (
        <Box display="flex" alignItems="baseline">
          <RedTxt>残：</RedTxt>
          <Limit>
            {formatRemainsDuration(props.limitation.remainsDurationSeconds)}
          </Limit>
        </Box>
      )}
      {props.limitation.maxRecCount > 0 && (
        <Box display="flex" alignItems="baseline">
          <RedTxt>残：</RedTxt>
          <Limit>{props.limitation.remainsRecCount}</Limit>
          <RedTxt>回</RedTxt>
        </Box>
      )}
    </Wrapper>
  )
}

export const LimitationExplanation: React.VFC<Props> = (props) => {
  const duration = formatDuration(props.limitation.maxDurationSeconds)
  return (
    <LimitationExplanationWrapper>
      <Spacer mt="4px" />
      <ResponsiveTxt>
        <Bold>この設問には制限事項があります</Bold>
      </ResponsiveTxt>
      <Spacer mt="6px" />
      <LimitItems>
        {props.limitation.maxDurationSeconds !== 0 && (
          <ItemsWrapper>
            <ResponsiveTxt>・撮影開始までの時間　</ResponsiveTxt>
            <ResponsiveTxt>
              <Bold>{duration.minutes}</Bold>
              <Unit>分</Unit>
              <Bold>{duration.seconds}</Bold>
              <Unit>秒</Unit>
              <br />
            </ResponsiveTxt>
          </ItemsWrapper>
        )}
        {props.limitation.maxRecCount !== 0 && (
          <ItemsWrapper>
            <ResponsiveTxt>・撮影できる回数</ResponsiveTxt>
            <ResponsiveTxt>
              <Bold>{props.limitation.maxRecCount}</Bold>
              <Unit>回</Unit>
            </ResponsiveTxt>
          </ItemsWrapper>
        )}
      </LimitItems>
    </LimitationExplanationWrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.red[2]};
  border-radius: 4px;
  max-height: 51px;
  width: 81px;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 4px 5px;
`

const RedTxt = styled(Txt).attrs({ color: theme.color.red[2], size: 's' })``

const Limit = styled(Txt).attrs({ color: theme.color.red[2] })`
  line-height: 1;
  margin-left: auto;
  font-size: 18px;
`

export const formatRemainsDuration = (seconds: number): string => {
  const duration = formatDuration(seconds)
  return `${duration.minutes}:${duration.seconds}`
}

type Duration = {
  minutes: string
  seconds: string
}

const formatDuration = (seconds: number): Duration => {
  // NOTE:時間制限の上限は600秒
  // https://blue-agency.atlassian.net/browse/PIM-3230
  if (600 < seconds) {
    throw new Error('duration seconds for video answer limitation out of range')
  }

  if (seconds < 0) {
    const duration: Duration = {
      minutes: '00',
      seconds: '00',
    }
    return duration
  }

  const ss = `0${seconds % 60}`.slice(-2)
  const mm = `0${Math.floor((seconds % (60 * 60)) / 60)}`.slice(-2)
  const duration: Duration = {
    minutes: mm,
    seconds: ss,
  }

  return duration
}

const LimitationExplanationWrapper = styled.div`
  text-align: center;
`

const Bold = styled.span`
  font-weight: bold;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: ${theme.fontSize.l};
        `}
`

const Unit = styled.span`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.s};
        `
      : css`
          font-size: ${theme.fontSize.xs};
        `}
`

const LimitItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`

const ResponsiveTxt = styled(Txt)`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 244px;
`
