import { useCallback, useEffect, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm, useWatch } from 'react-hook-form'
import {
  Guid,
  RecInterviewQuestion,
  TextAnswer,
  WithDispatch,
} from '@/pages/SelectionRecInterviewPage/types'
import { storeAnswerOnLocalStorage } from '../../storeAnswer'
import { optionalTextValidator, requiredTextValidator } from './validator'

type Form = TextAnswer

type Params = WithDispatch<{
  selectionStepGuid: Guid
  questionGuid: Guid
  question: RecInterviewQuestion
  answer: TextAnswer | undefined
}>

export const useTextQuestionForm = ({
  dispatch,
  selectionStepGuid,
  questionGuid,
  question,
  answer,
}: Params) => {
  const validator = question.required
    ? requiredTextValidator
    : optionalTextValidator

  const defaultValues: TextAnswer = useMemo(
    () => ({
      questionGuid,
      kind: 'Text',
      content: answer?.content ?? '',
    }),
    [answer?.content, questionGuid]
  )

  const { handleSubmit, formState, reset, getValues, control, ...form } =
    useForm<Form>({
      mode: 'onBlur',
      resolver: yupResolver(validator),
      defaultValues,
    })

  useEffect(() => {
    reset(answer ?? defaultValues)
  }, [reset, answer, defaultValues])

  useStoreAnswerOnLocalStorage(
    selectionStepGuid,
    questionGuid,
    control,
    defaultValues
  )

  const onClickNext = useMemo(() => {
    return handleSubmit((data) => {
      dispatch(['SUBMIT_ANSWER', { questionGuid, answer: data }])
    })
  }, [handleSubmit, dispatch, questionGuid])

  const onClickBack = useCallback(() => {
    dispatch(['SET_ANSWER', { questionGuid, answer: getValues() }])
  }, [dispatch, questionGuid, getValues])

  const { errors } = formState

  return {
    ...form,
    errors,
    onClickNext,
    onClickBack,
  }
}

function useStoreAnswerOnLocalStorage(
  selectionStepGuid: Guid,
  questionGuid: Guid,
  control: Control<TextAnswer>,
  defaultValues: TextAnswer
) {
  const content = useWatch({
    control,
    name: 'content',
  })

  useEffect(() => {
    storeAnswerOnLocalStorage(selectionStepGuid, questionGuid, {
      ...defaultValues,
      content,
    })
  }, [content, defaultValues, questionGuid, selectionStepGuid])
}
