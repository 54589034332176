import React, { useCallback } from 'react'
import { theme, Txt, NewTabLink } from '@blue-agency/rogue'
import { Box } from '@/components/Box'
import { Form } from '@/components/Form'
import { StretchedPrimaryButton } from '@/components/StretchedButton'
import { validateRecInterviewStatus } from '@/pages/SelectionRecInterviewPage/reducer'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import { Guid, WithDispatch } from '@/pages/SelectionRecInterviewPage/types'
import { EXTERNAL_URLS } from '@/services/urlService'

type Props = WithDispatch<{
  selectionStepGuid: Guid
  deadline: NonNullable<State['deadline']>
  includeAnswerLimitedQuestion: boolean
}>

export const Complete: React.VFC<Props> = ({
  dispatch,
  selectionStepGuid,
  includeAnswerLimitedQuestion,
  deadline,
}) => {
  const onClickNext = useCallback(async () => {
    const ok = await validateRecInterviewStatus(selectionStepGuid, dispatch)
    if (ok) {
      dispatch(['GO_NEXT'])
    }
  }, [selectionStepGuid, dispatch])

  return (
    <Form.Wrapper>
      <Form.Body>
        <Box padding="20px">
          <Box display="flex" alignItems="center" flexDirection="column">
            <Txt textAlign="center" size="xl">
              ご準備はよろしいですか？
              <br />
              準備ができたら、
              <br />
              本回答を開始しましょう！
            </Txt>
          </Box>
          {/* NOTE: 提出期限の表示ON/OFF機能実装まで一旦消しておく https://blue-agency.atlassian.net/browse/PIM-2912 */}
          {/* <Box mt="20px" p="16px" bg={theme.color.gray[5]}>
            <Box display="flex">
              <CalendarIcon />
              <Txt color={theme.color.gray[1]}>提出期限：</Txt>
              <Txt bold>{formatTime(deadline)}</Txt>
            </Box>
          </Box> */}
          <Box mt="30px" pt="12px" px="15px" pb="8px" bg={theme.color.gray[5]}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Txt size="l" bold>
                回答にあたっての注意事項
              </Txt>
            </Box>
            <Box mt="20px">
              <Txt>
                ・回答開始後は中断できません。必ず最後まで回答を完了させてください。
                <br />
                ・再読込やブラウザの戻る機能は利用しないでください。
              </Txt>
              {includeAnswerLimitedQuestion && (
                <Txt color={theme.color.red[2]}>
                  ・「回答時間」や「撮影回数」に制限がある設問があります。それらの設問には、指定された時間や回数内に回答してください。
                  <br />
                  制限詳細は
                  <NewTabLink
                    href={EXTERNAL_URLS.help.answerLimitation}
                    action="open_answer_limitation_help_page"
                  >
                    こちら
                  </NewTabLink>
                  の記事をご確認ください。
                </Txt>
              )}
            </Box>
            <Box mt="25px">
              <Box mt="12px" display="flex" flex="1">
                <StretchedPrimaryButton
                  comlinkPushParams={{
                    action: 'start_answering_for_rec_interview',
                  }}
                  onClick={onClickNext}
                >
                  回答を開始
                </StretchedPrimaryButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Form.Body>
    </Form.Wrapper>
  )
}
