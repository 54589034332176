import { useCallback } from 'react'
import { GetVideoSeminarContentRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type RequestGetVideoSeminarContentParams = {
  videoSeminarContentGuid: string
  videoSeminarTicketGuid: string
}
export function useRequestGetVideoSeminarContent() {
  const requestGetVideoSeminarContent = useCallback(
    (params: RequestGetVideoSeminarContentParams) => {
      const req = new GetVideoSeminarContentRequest()
      req.setVideoSeminarContentGuid(params.videoSeminarContentGuid)
      req.setVideoSeminarTicketGuid(params.videoSeminarTicketGuid)

      return callMyHuttBffService('getVideoSeminarContent', [req])
    },
    []
  )

  return { requestGetVideoSeminarContent }
}
