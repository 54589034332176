import React from 'react'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { Spacer } from '@/components/Spacer'

type Props = {
  title: string
  content: string | React.ReactNode
}

export const Message: React.VFC<Props> = React.memo(({ title, content }) => {
  return (
    <>
      <BreakWordTxt size="l" bold>
        {title}
      </BreakWordTxt>
      <Spacer mt="8px" />
      <BreakWordTxt>{content}</BreakWordTxt>
    </>
  )
})
