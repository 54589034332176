import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { Spacer } from '@/components/Spacer'
import { VideoPlayer } from '@/components/VideoPlayer'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'

type Props = {
  organization: NonNullable<State['organization']>
  outroMessage: State['outroMessage']
  outroVideoPresignedUrl: State['outroVideoPresignedUrl']
}

export const Outro: React.VFC<Props> = React.memo(
  ({ organization, outroMessage, outroVideoPresignedUrl }) => {
    return (
      <Wrapper padding="20px">
        <Box
          display="flex"
          justifyContent="center"
          color={theme.color.green[4]}
        >
          <CheckIcon />
        </Box>
        <Box display="flex" justifyContent="center" mt="20px">
          <Txt textAlign="center" size="l">
            お疲れさまでした！
            <br />
            回答を提出しました！
          </Txt>
        </Box>
        <Spacer mt="5px" />
        {(outroMessage || outroVideoPresignedUrl) && (
          <Box mt="24px">
            <BreakWordTxt size="m" bold>
              {organization.name}からのメッセージ
            </BreakWordTxt>
          </Box>
        )}
        {outroVideoPresignedUrl && (
          <Box mt="12px" display="flex" justifyContent="center">
            <VideoPlayer
              src={outroVideoPresignedUrl}
              // 16:9
              maxHeight={278}
              maxWidth={494}
            />
          </Box>
        )}
        {outroMessage && (
          <Box mt="12px">
            <BreakWordTxt>{outroMessage}</BreakWordTxt>
          </Box>
        )}
        <Spacer mt="28px" />
        <Txt>以上で終了となりますので、ページを閉じてください。</Txt>
      </Wrapper>
    )
  }
)

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: 44px;
  height: 44px;
`

const Wrapper = styled(Box)`
  width: ${(props) => props.theme.contentWidth};
`
