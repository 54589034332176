import { useCallback, useMemo, useRef } from 'react'

type Args = {
  onRecordingComplete: (blob: Blob) => void
}

export function useMediaRecord({ onRecordingComplete }: Args) {
  const mediaRecorderRef = useRef<MediaRecorder | undefined>()
  const chunksRef = useRef<Blob[]>([])

  const blobType = useMemo(() => {
    return ['video/webm', 'video/mp4'].find((type) =>
      MediaRecorder.isTypeSupported(type)
    )
  }, [])

  const startRecording = useCallback(
    (stream: MediaStream) => {
      const recorder = new MediaRecorder(stream, {
        videoBitsPerSecond: 1024 * 1024, // NOTE:アップロード後に行われる変換の最大ビットレート(1MiB)に合わせる
      })
      mediaRecorderRef.current = recorder

      recorder.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: blobType })
        chunksRef.current = []
        onRecordingComplete(blob)
      }

      recorder.ondataavailable = (e) => {
        chunksRef.current?.push(e.data)
      }

      recorder.start()
    },
    [blobType, onRecordingComplete]
  )

  const stopRecording = useCallback(() => {
    mediaRecorderRef.current?.stop()
  }, [])

  return {
    startRecording,
    stopRecording,
  }
}
