import React from 'react'
import { Txt } from '@blue-agency/rogue'
import { Box } from '@/components/Box'

export const AlreadySubmitted: React.VFC = React.memo(() => {
  return (
    <Box mt="20px">
      <Txt size="l">既に回答を提出済です。</Txt>
    </Box>
  )
})
