import { useBeforeUnload as useHook } from 'react-use'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'

export const useBeforeUnload = (
  recInterviewStatus: State['recInterviewStatus'],
  step: State['step']
) => {
  // ブラウザによってはカスタムメッセージを表示できないが、
  // 今後どうなるかわからないのでとりあえず埋めておく
  useHook(
    isConfirmationNeeded(recInterviewStatus, step),
    '回答途中にページを移動すると、\n' +
      '入力途中のデータが破棄されるなど、正常に動作しないおそれがあります。'
  )
}

const isConfirmationNeeded = (
  recInterviewStatus: State['recInterviewStatus'],
  step: State['step']
): boolean => {
  if (recInterviewStatus === undefined) {
    return false
  }

  switch (recInterviewStatus) {
    case 'Expired':
    case 'Submitted':
      return false
  }

  return step.name !== 'Outro'
}
