import { useQuery } from 'react-query'
import {
  useRequestGetVideoSeminarContent,
  RequestGetVideoSeminarContentParams,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

export const useGetVideoSeminarContent = (
  params: RequestGetVideoSeminarContentParams
) => {
  const { requestGetVideoSeminarContent } = useRequestGetVideoSeminarContent()

  return useQuery(
    [QUERY_KEY.videoSeminarContent, params.videoSeminarContentGuid],
    () => requestGetVideoSeminarContent(params)
  )
}
