import React from 'react'
import { InputText, RequireTxt, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import {
  StretchedPrimaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { VideoPlayer } from '@/components/VideoPlayer'
import { QuestionBadge } from '@/pages/SelectionRecInterviewPage/components/QuestionBadge'
import {
  Guid,
  RecInterviewQuestion,
  TextAnswer,
  WithDispatch,
} from '@/pages/SelectionRecInterviewPage/types'
import { useTextQuestionForm } from './useTextQuestionForm'

type Props = WithDispatch<{
  selectionStepGuid: Guid
  questionGuid: Guid
  question: RecInterviewQuestion
  answer: TextAnswer | undefined
  questionIndex: number
  questionCount: number
  canGoBack: boolean
}>

export const TextQuestion: React.VFC<Props> = (props) => {
  const { register, onClickNext, onClickBack, errors } = useTextQuestionForm({
    ...props,
  })
  return (
    <Form.Wrapper bottomHeight={64}>
      <Form.Body>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <QuestionBadge>
            問{props.questionIndex + 1} / {props.questionCount}
          </QuestionBadge>
          {props.question.required && (
            <Box ml="8px">
              <RequireTxt size="s">必須</RequireTxt>
            </Box>
          )}
        </Box>
        <Box mt="8px" px="8px" py="8px" bg={theme.color.gray[5]}>
          <BreakWordTxt bold>{props.question.content}</BreakWordTxt>
          {props.question.note.length > 0 && (
            <Box mt="12px">
              <BreakWordTxt>{props.question.note}</BreakWordTxt>
            </Box>
          )}
          {props.question.videoPresignedUrl && (
            <Box mt="12px" display="flex" justifyContent="center">
              <VideoPlayer
                src={props.question.videoPresignedUrl}
                // 16:9
                maxHeight={208}
                maxWidth={370}
              />
            </Box>
          )}
        </Box>
        <Box mt="20px">
          <TextArea
            type="textarea"
            placeholder="入力してください"
            onBlurError={errors['content']?.message}
            defaultValue=""
            {...register('content')}
          />
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          {props.canGoBack && (
            <StretchedTertiaryButton
              comlinkPushParams={{
                action: 'click_back_on_rec_interview_text_question_page',
              }}
              onClick={onClickBack}
            >
              前に戻る
            </StretchedTertiaryButton>
          )}
          <StretchedPrimaryButton
            comlinkPushParams={{
              action: 'click_next_on_rec_interview_text_question_page',
            }}
            onClick={onClickNext}
          >
            次に進む
          </StretchedPrimaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

// TODO: rogueのinputtextもう少し汎用的にする
const TextArea = styled(InputText)`
  textarea {
    width: 100%;
    border-color: ${theme.color.navy[1]};
  }
`
