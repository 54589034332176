import React from 'react'
import { ActivityIndicator, Modal, theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { Spacer } from '@/components/Spacer'
import {
  StretchedPrimaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { VideoPlayer } from '@/components/VideoPlayer'
import { useModal } from '@/hooks/useModal'
import { useSubmitSelectionRecInterview } from '@/pages/SelectionRecInterviewPage/Confirm/useSubmitSelectionRecInterview'
import { QuestionBadge } from '@/pages/SelectionRecInterviewPage/components/QuestionBadge'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import {
  VideoAnswer,
  WithDispatch,
} from '@/pages/SelectionRecInterviewPage/types'
import { useRequestGetRecInterviewPreviewVideoURL } from '@/services/bffService/useRequestGetRecInterviewPreviewVideoURL'
import { QUERY_KEY } from '@/services/queryKeyService'

type Props = WithDispatch<{
  state: State
  recInterviewGuid: string
  selectionStepGuid: string
}>

export const Confirm: React.VFC<Props> = (props) => {
  const { onSubmit } = useSubmitSelectionRecInterview({
    dispatch: props.dispatch,
    state: props.state,
    selectionStepGuid: props.selectionStepGuid,
  })

  return (
    <ConfirmInner
      {...props}
      onSubmit={onSubmit}
      videoAnswerBox={VideoAnswerBox}
    />
  )
}

export const ConfirmInner: React.VFC<
  Props & {
    onSubmit: () => void
    // VideoAnswerBox内でuseQueryを使っており、外部と疎結合にするためにpropsから渡している
    videoAnswerBox: React.VFC<VideoAnswerBoxProps>
  }
> = (props) => {
  const { dispatch, state } = props
  const PropsVideoAnswerBox = props.videoAnswerBox

  const submitModal = useModal()

  const anyQuestionNotAnswered = Object.values(state.answers.byId).some(
    (ans) => {
      return (
        (ans.kind === 'Text' && !ans.content) ||
        (ans.kind === 'Video' && !ans.key)
      )
    }
  )

  return (
    <Form.Wrapper>
      <Form.Body>
        <Box display="flex" justifyContent="center">
          <Txt size="xl">提出内容の確認</Txt>
        </Box>
        {anyQuestionNotAnswered && (
          <Box mt="20px">
            <Txt color={theme.color.orange[1]}>未回答の設問があります</Txt>
          </Box>
        )}
        <Box mt="20px">
          <Txt bold>まだ提出は完了していません。</Txt>
          <Txt>回答を確認のうえ、提出を完了してください。</Txt>
        </Box>
        <Spacer mt="4px" />
        {state.questions.allIds.map((guid, i) => {
          const question = state.questions.byId[guid]
          const answer = state.answers.byId[guid]
          const renderAnswer = () => {
            if (answer && answer.kind === 'Text' && answer.content.length > 0) {
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt="8px"
                    padding="0 8px 8px 8px"
                    bg={theme.color.gray[5]}
                    width="100%"
                  >
                    <Box width="100%" mt="8px">
                      <BreakWordTxt>{answer.content}</BreakWordTxt>
                    </Box>
                  </Box>
                  <EditButton
                    onClick={() =>
                      dispatch(['EDIT_ANSWER', { questionGuid: guid }])
                    }
                    widthSize="L1"
                    heightSize={spButtonHeightSize}
                    comlinkPushParams={{ action: 'edit_text_answer' }}
                  >
                    回答を変更
                  </EditButton>
                </>
              )
            }

            if (answer && answer.kind === 'Video' && answer.key.length > 0) {
              return (
                <PropsVideoAnswerBox
                  answer={answer}
                  dispatch={dispatch}
                  recInterviewGuid={props.recInterviewGuid}
                  questionGuid={guid}
                />
              )
            }

            return (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt="8px"
                  padding="0 8px 8px 8px"
                  width="100%"
                >
                  <Box
                    width="100%"
                    mt="8px"
                    padding="8px 12px"
                    backgroundColor={theme.color.gray[5]}
                  >
                    <Txt color={theme.color.gray[2]}>未回答</Txt>
                  </Box>
                </Box>
                <EditButton
                  onClick={() =>
                    dispatch(['EDIT_ANSWER', { questionGuid: guid }])
                  }
                  widthSize="L1"
                  heightSize={spButtonHeightSize}
                  comlinkPushParams={{ action: 'edit_unanswerwed_answer' }}
                >
                  回答を変更
                </EditButton>
              </>
            )
          }
          return (
            <Box
              key={guid}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt="10px"
              p="20px 0 10px"
              borderTop={`1px solid ${theme.color.gray[4]}`}
            >
              <QuestionBadge>問{i + 1}</QuestionBadge>
              <Box mt="8px" ml="8px">
                <BreakWordTxt bold>{question.content}</BreakWordTxt>
              </Box>
              {renderAnswer()}
            </Box>
          )
        })}
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          <StretchedPrimaryButton
            comlinkPushParams={{
              action: 'submit_answers_on_rec_interview_confirm_page',
            }}
            onClick={submitModal.open}
          >
            回答を提出
          </StretchedPrimaryButton>
        </BottomButtons>
      </Form.Bottom>
      <ModalWithMargin
        active={submitModal.active}
        title="回答を提出"
        onClose={submitModal.close}
      >
        <Box paddingX="20px" paddingY="16px">
          <Txt>回答を提出します。よろしいですか？</Txt>
          <Txt>提出後に回答内容の変更はできませんので、ご注意ください。</Txt>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderTop={`1px solid ${theme.color.gray[4]}`}
          paddingY="11px"
        >
          <Box width={144}>
            <StretchedTertiaryButton
              comlinkPushParams={{
                action: 'click_submit_modal_cancel',
              }}
              onClick={submitModal.close}
            >
              キャンセル
            </StretchedTertiaryButton>
          </Box>
          <Box width={144} ml="8px">
            <StretchedPrimaryButton
              comlinkPushParams={{
                action: 'click_submit_modal_submit',
              }}
              onClick={props.onSubmit}
            >
              回答を提出
            </StretchedPrimaryButton>
          </Box>
        </Box>
      </ModalWithMargin>
    </Form.Wrapper>
  )
}

export type VideoAnswerBoxProps = WithDispatch<{
  answer: VideoAnswer
  recInterviewGuid: string
  questionGuid: string
}>

const VideoAnswerBox: React.VFC<VideoAnswerBoxProps> = ({
  dispatch,
  answer,
  recInterviewGuid,
  questionGuid,
}) => {
  const { requestGetRecInterviewPreviewVideoURL } =
    useRequestGetRecInterviewPreviewVideoURL()
  const previewVideo = useQuery(
    [
      QUERY_KEY.getRecInterviewPreviewVideoURL,
      recInterviewGuid,
      questionGuid,
      answer.key,
    ],
    () =>
      requestGetRecInterviewPreviewVideoURL({
        recInterviewGuid,
        questionGuid,
        key: answer.key,
      }),
    // 取得したsigned urlは60分有効なので、55分間はcacheして使い回す
    { staleTime: 1000 * 60 * 55 }
  )
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        mt="8px"
        padding="0 8px 8px 8px"
        width="100%"
      >
        <Box
          width="100%"
          mt="8px"
          display="flex"
          justifyContent="center"
          bg={theme.color.gray[5]}
        >
          {previewVideo.isLoading ? (
            <ActivityIndicator />
          ) : (
            <VideoPlayer
              src={previewVideo.data?.getUrl()}
              // 4:3
              maxHeight={278}
              maxWidth={370}
            />
          )}
        </Box>
      </Box>
      <EditButton
        onClick={() => dispatch(['EDIT_ANSWER', { questionGuid }])}
        widthSize="L1"
        heightSize={spButtonHeightSize}
        comlinkPushParams={{ action: 'edit_video_answer' }}
      >
        回答を変更
      </EditButton>
    </>
  )
}

const EditButton = styled(TertiaryButton)`
  margin: 12px auto 0;
`

const ModalWithMargin = styled(Modal)`
  margin: 20px;
`
