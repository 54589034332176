import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  spButtonHeightSize,
} from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const StretchedPrimaryButton = styled(PrimaryButton).attrs({
  widthSize: 'MAX',
  heightSize: spButtonHeightSize,
})``

export const StretchedSecondaryButton = styled(SecondaryButton).attrs({
  widthSize: 'MAX',
  heightSize: spButtonHeightSize,
})``

export const StretchedTertiaryButton = styled(TertiaryButton).attrs({
  widthSize: 'MAX',
  heightSize: spButtonHeightSize,
})``

export const StretchedDangerButton = styled(DangerButton).attrs({
  widthSize: 'MAX',
  heightSize: spButtonHeightSize,
})``
