import { theme, Txt, Icon } from '@blue-agency/rogue'
import { generatePath, Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  VideoSeminarContentForList,
  useGetVideoSeminarTicketFromQueryParam,
} from '@/services/videoSeminarService'

type Props = {
  videoSeminarGuid: string
  prevContent?: VideoSeminarContentForList
  nextContent?: VideoSeminarContentForList
}
export const OtherContents: React.VFC<Props> = (props) => {
  const { videoSeminarTicketSearchStr } =
    useGetVideoSeminarTicketFromQueryParam()

  return (
    <Wrapper>
      <Contents>
        {props.prevContent !== undefined ? (
          <ContentLink
            to={{
              pathname: generatePath(INTERNAL_PATHS.videoSeminar.content, {
                videoSeminarGuid: props.videoSeminarGuid,
                videoSeminarContentGuid: props.prevContent.guid,
              }),
              search: videoSeminarTicketSearchStr,
            }}
          >
            <PrevLabel>
              <PrevIcon />
              <Txt>前の動画</Txt>
            </PrevLabel>
            <Content>
              {props.prevContent.thumbnailUrl ? (
                <ThumbnailImg src={props.prevContent.thumbnailUrl} />
              ) : (
                <AltThumbnail>
                  <AltThumbnailTxt>{props.prevContent.title}</AltThumbnailTxt>
                </AltThumbnail>
              )}
              <ContentTitle>{props.prevContent.title}</ContentTitle>
            </Content>
          </ContentLink>
        ) : (
          <Empty />
        )}
        {props.nextContent !== undefined ? (
          <ContentLink
            to={{
              pathname: generatePath(INTERNAL_PATHS.videoSeminar.content, {
                videoSeminarGuid: props.videoSeminarGuid,
                videoSeminarContentGuid: props.nextContent.guid,
              }),
              search: videoSeminarTicketSearchStr,
            }}
          >
            <NextLabel>
              <Txt>次の動画</Txt>
              <NextIcon />
            </NextLabel>
            <Content>
              {props.nextContent.thumbnailUrl ? (
                <ThumbnailImg src={props.nextContent.thumbnailUrl} />
              ) : (
                <AltThumbnail>
                  <AltThumbnailTxt>{props.nextContent.title}</AltThumbnailTxt>
                </AltThumbnail>
              )}
              <ContentTitle>{props.nextContent.title}</ContentTitle>
            </Content>
          </ContentLink>
        ) : (
          <Empty />
        )}
      </Contents>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.gray[5]};
`

const Contents = styled.main`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          width: 640px;
        `
      : css`
          width: 336px;
        `}
`

const Empty = styled.div`
  width: 162px;
`

const ContentLink = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
`

const PrevLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.navy[1]};
`

const NextLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${theme.color.navy[1]};
`

const PrevIcon = styled(Icon).attrs({ name: 'arrow', height: '14px' })``

const NextIcon = styled(Icon).attrs({ name: 'arrow', height: '14px' })`
  transform: rotate(180deg);
`

const Content = styled.div`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  width: 162px;
  height: 118px;
  max-height: 118px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
  margin-top: 4px;
`

const ThumbnailImg = styled.img`
  width: 100%;
  height: 90px;
  object-fit: contain;
  background-color: ${theme.color.darkGray[1]};
`

const AltThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  background-color: ${theme.color.gray[4]};
`

const AltThumbnailTxt = styled(Txt).attrs({
  size: 's',
  bold: true,
  color: theme.color.gray[2],
})``

const ContentTitle = styled(Txt).attrs({ size: 's', bold: true })`
  width: 100%;
  background-color: ${theme.color.white[1]};
  padding: 4px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
