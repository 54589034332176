import { theme, Txt } from '@blue-agency/rogue'
import { FullScreen } from 'react-full-screen'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { PlayerControls } from './PlayerControls'
import { Video } from './Video'
import { useControlsVisibility } from './useControlsVisibility'
import { useVideoControl } from './useVideoControl'

type Props = PropsWithClassName<{
  src: string
  videoSeminarContentGuid: string
  videoSeminarTicketGuid: string
  thumbnailUrl?: string
}>
export const HlsVideoPlayer: React.VFC<Props> = ({
  src,
  videoSeminarContentGuid,
  videoSeminarTicketGuid,
  thumbnailUrl,
  className,
}) => {
  const { videoRef, fullScreenHandle, controlsProps, canPlay } =
    useVideoControl({
      src,
      videoSeminarContentGuid,
      videoSeminarTicketGuid,
    })

  const { playerWrapperRef, isControlsVisible } = useControlsVisibility()

  return (
    <FullScreen className={className} handle={fullScreenHandle}>
      <Wrapper ref={playerWrapperRef}>
        <Video ref={videoRef} thumbnailUrl={thumbnailUrl} />
        {canPlay ? (
          <Controls $visible={!controlsProps.isPlaying || isControlsVisible}>
            <PlayerControls {...controlsProps} />
          </Controls>
        ) : (
          <Loading>
            <LoadingTxt>
              読込中...
              <br />
              10秒経過しても読込みが終了しない場合は
              <br />
              画面を再読込してください。
            </LoadingTxt>
          </Loading>
        )}
      </Wrapper>
    </FullScreen>
  )
}

const Wrapper = styled.div`
  position: relative;
  background-color: ${theme.color.darkGray[1]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Controls = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.$visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
  transition: opacity 0.3s;
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`

const LoadingTxt = styled(Txt).attrs({
  color: theme.color.white[1],
  textAlign: 'center',
})`
  margin: 0 auto;
`
