import { useCallback } from 'react'
import { theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

export type SeekBarProps = PropsWithClassName<{
  duration: number
  currentTime: number
  onSeekChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSeekEnd: () => void
  onSeekStart: () => void
}>

export const SeekBar: React.VFC<SeekBarProps> = ({
  className,
  duration,
  currentTime,
  onSeekChange,
  onSeekEnd,
  onSeekStart,
}) => {
  // 矢印キーで1秒ずつシークできる挙動を無効化する
  const disableArrowKeys = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
        e.preventDefault()
      }
    },
    []
  )

  return (
    <Wrapper className={className}>
      <Bar max={duration} value={currentTime} />
      <Input
        type="range"
        min={0}
        max={duration}
        step={1}
        value={currentTime}
        // <input type="range"> の change イベントは、スライダーを動かしたあと、マウスをニュートラル状態に戻して、スライダーの値が確定したタイミングで発火する
        // しかし、React上では、 ブラウザ間の差異を吸収するために、スライダーを動かしたタイミング（まだマウスをクリックしている状態）でも change が発火する
        // ref: https://github.com/facebook/react/issues/7614
        // したがって、ログ送信のタイミングなどは注意深く実装する必要がある
        onChange={onSeekChange}
        onMouseUp={onSeekEnd}
        onMouseDown={onSeekStart}
        onTouchStart={onSeekStart}
        onTouchEnd={onSeekEnd}
        onKeyDown={disableArrowKeys}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 12px;
`

const Input = styled.input`
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0;
  height: 12px;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  width: 100%;
  background-color: transparent;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${theme.color.green[4]};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`

const Bar = styled.div<{ value: number; max: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  height: 6px;
  ${({ value, max }) => {
    const percent = 100 * (Number(value) / Number(max))
    return css`
      background: linear-gradient(
        to right,
        ${theme.color.green[4]} 0%,
        ${theme.color.green[4]} ${percent}%,
        ${theme.color.gray[3]} ${percent}%,
        ${theme.color.gray[3]} 100%
      );
    `
  }}
`
