import React, { useCallback } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  srcObject?: Blob
  src?: string
  maxHeight: number
  maxWidth: number
}

export const VideoPlayer: React.VFC<Props> = (props) => {
  const elRef = useCallback(
    (el: HTMLVideoElement | null) => {
      if (!el) return
      if (!props.src && !props.srcObject) return

      const src = props.src
        ? `${props.src}#t=0.1`
        : props.srcObject
        ? URL.createObjectURL(props.srcObject)
        : undefined

      if (src === undefined) return
      el.src = src

      el.onloadedmetadata = function () {
        if (el.duration === Infinity) {
          el.currentTime = Number.MAX_SAFE_INTEGER

          el.ontimeupdate = () => {
            el.ontimeupdate = null
            // https://stackoverflow.com/a/55093702
            el.currentTime = 0.1
            el.currentTime = 0
          }
        }
        // NOTE: Safariでサムネを表示させるために少しだけ再生している
        el.muted = true
        el.play().catch((e) => {
          // 「AbortError: The play() request was interrupted by a call to pause().」が出るのでcatchする
          if (e.name === 'AbortError') {
            return
          }
          // ユーザーインタラクション前にplayされるとSafariではNotAllowedErrorになるが、致命的ではないのでスルーする。
          if (e.name === 'NotAllowedError') {
            return
          }
          throw e
        })
        setTimeout(() => {
          el.pause()
          el.muted = false
        }, 10)
      }
    },
    [props.srcObject, props.src]
  )

  return (
    <Video
      ref={elRef}
      controls
      disablePictureInPicture
      controlsList="nodownload"
      onContextMenu={(e) => e.preventDefault()}
      maxHeight={props.maxHeight}
      maxWidth={props.maxWidth}
    />
  )
}

const Video = styled.video<{ maxHeight?: number; maxWidth?: number }>`
  width: 100%;
  ${({ maxHeight }) => maxHeight !== undefined && `max-height: ${maxHeight}px;`}
  ${({ maxWidth }) => maxWidth !== undefined && `max-width: ${maxWidth}px;`}
  background-color: ${theme.color.darkGray[1]};
`
