import { useQuery } from 'react-query'
import {
  useRequestGetVideoSeminar,
  RequestGetVideoSeminarParams,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'
import { setVideoSeminarTicketToLocalStorage } from './ticket'

export const useGetVideoSeminar = (params: RequestGetVideoSeminarParams) => {
  const { requestGetVideoSeminar } = useRequestGetVideoSeminar()

  return useQuery(
    [QUERY_KEY.videoSeminar, params.videoSeminarGuid],
    () => requestGetVideoSeminar(params),
    {
      onSuccess: () => {
        setVideoSeminarTicketToLocalStorage(
          params.videoSeminarGuid,
          params.videoSeminarTicketGuid
        )
      },
    }
  )
}
