import { forwardRef } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  thumbnailUrl?: string
}
export const Video = forwardRef<HTMLVideoElement, Props>((props, ref) => {
  return <StyledVideo ref={ref} playsInline poster={props.thumbnailUrl} />
})

const StyledVideo = styled.video`
  background-color: ${theme.color.darkGray[1]};
  overflow: hidden;
  width: 100%;
`
