import React from 'react'
import { ErrorBox, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Box } from '@/components/Box'

export const Expired: React.VFC = React.memo(() => {
  return (
    <Content mt="17px" px="16px" py="14px">
      <ErrorBox>提出期間は終了しました。</ErrorBox>
    </Content>
  )
})

const Content = styled(Box).attrs({ bg: theme.color.white[1] })`
  width: ${(props) => props.theme.contentWidth};
`
