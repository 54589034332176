import { GetSelectionRecInterviewResponse } from '@blue-agency/proton/my_hutt_bff'
import { assertIsDefined } from '@/assertions'
import { requestGetSelectionRecInterview } from '@/services/bffService/requestGetSelectionRecInterview'
import {
  Guid,
  Organization,
  RecInterviewQuestion,
  RecInterviewStatus,
  VideoAnswerLimitationSetting,
} from './types'

type Params = {
  selectionStepGuid: Guid
}

export type Response = Pick<
  GetSelectionRecInterviewResponse.RecInterview.AsObject,
  | 'guid'
  | 'introMessage'
  | 'outroMessage'
  | 'allowRecordedVideo'
  | 'introVideoPresignedUrl'
  | 'outroVideoPresignedUrl'
> & {
  questions: RecInterviewQuestion[]
  recInterviewStatus: RecInterviewStatus
  organization?: Organization
  deadline?: Date
  selectionStepName: string
}

export async function getSelectionRecInterview({
  selectionStepGuid,
}: Params): Promise<Response> {
  const res = await requestGetSelectionRecInterview({ selectionStepGuid })
  const recInterview = res.getRecInterview()
  if (recInterview === undefined) throw new Error('recInterview is undefined')

  return {
    guid: recInterview.getGuid(),
    introMessage: recInterview.getIntroMessage(),
    outroMessage: recInterview.getOutroMessage(),
    introVideoPresignedUrl: recInterview.getIntroVideoPresignedUrl(),
    outroVideoPresignedUrl: recInterview.getOutroVideoPresignedUrl(),
    organization: mapOrganization(recInterview.getOrganization()),
    recInterviewStatus: mapStatus(recInterview.getStatus()),
    questions: mapQuestionList(recInterview.getQuestionsList()),
    deadline: recInterview.getDeadline()?.toDate(),
    allowRecordedVideo: recInterview.getAllowRecordedVideo(),
    selectionStepName: res.getSelectionStepName() ?? '',
  }
}

const mapQuestionList = (
  questionList: GetSelectionRecInterviewResponse.RecInterview.Question[]
): RecInterviewQuestion[] => {
  if (questionList.length === 0) {
    return []
  }

  return questionList.map((q) => mapQuestion(q))
}

const mapQuestion = (
  question: GetSelectionRecInterviewResponse.RecInterview.Question
): RecInterviewQuestion => {
  switch (question.getFormatCase()) {
    case GetSelectionRecInterviewResponse.RecInterview.Question.FormatCase.TEXT:
      const text = question.getText()
      assertIsDefined(text)
      return {
        kind: 'Text',
        guid: question.getQuestionGuid(),
        required: question.getRequired(),
        content: text.getContent(),
        note: text.getNote(),
        videoPresignedUrl: question.getVideoPresignedUrl(),
      }
    case GetSelectionRecInterviewResponse.RecInterview.Question.FormatCase
      .VIDEO:
      const video = question.getVideo()
      assertIsDefined(video)
      return {
        kind: 'Video',
        guid: question.getQuestionGuid(),
        required: question.getRequired(),
        content: video.getContent(),
        note: video.getNote(),
        videoPresignedUrl: question.getVideoPresignedUrl(),
        ...getVideoAnswerLimitation(video),
      }
    case GetSelectionRecInterviewResponse.RecInterview.Question.FormatCase
      .FORMAT_NOT_SET:
      throw new Error('Invalid rec interview question format')
  }
}

const getVideoAnswerLimitation = (
  video: GetSelectionRecInterviewResponse.RecInterview.Question.Video
): VideoAnswerLimitationSetting => {
  const maxCount = video.getMaxRecCount()
  const maxDuration = video.getMaxDurationSeconds()
  const hasAnswerLimitation = maxCount > 0 || maxDuration > 0
  return {
    maxRecCount: maxCount,
    maxDurationSeconds: maxDuration,
    hasAnswerLimitation,
  }
}

const mapOrganization = (
  organization:
    | GetSelectionRecInterviewResponse.RecInterview.Organization
    | undefined
): Organization | undefined => {
  if (organization === undefined) {
    return organization
  }
  return {
    guid: organization.getOrganizationGuid(),
    name: organization.getName(),
  }
}

const mapStatus = (
  status: GetSelectionRecInterviewResponse.RecInterview.Status
): RecInterviewStatus => {
  switch (status) {
    case GetSelectionRecInterviewResponse.RecInterview.Status.NOT_SUBMITTED:
      return 'NotSubmitted'
    case GetSelectionRecInterviewResponse.RecInterview.Status.SUBMITTED:
      return 'Submitted'
    case GetSelectionRecInterviewResponse.RecInterview.Status.EXPIRED:
      return 'Expired'
    case GetSelectionRecInterviewResponse.RecInterview.Status.STATUS_UNKNOWN:
      throw new Error('Unknown rec interview status')
  }
}
