import { useCallback } from 'react'
import { SubmitSelectionRecInterviewRequest } from '@blue-agency/proton/my_hutt_bff'
import {
  Guid,
  RecInterviewAnswer,
} from '@/pages/SelectionRecInterviewPage/types'
import { callMyHuttBffService } from '@/services/bffService/bffClient'

export type SubmitSelectionRecInterviewParams = {
  selectionStepGuid: string
  organizationGuid: string
  questionIds: Array<Guid>
  answers: {
    byId: Record<Guid, RecInterviewAnswer>
  }
}

export function useRequestSubmitSelectionRecInterview() {
  const requestSubmitSelectionRecInterview = useCallback(
    (params: SubmitSelectionRecInterviewParams) => {
      const req = new SubmitSelectionRecInterviewRequest()
      req.setSelectionStepGuid(params.selectionStepGuid)
      req.setOrganizationGuid(params.organizationGuid)

      const answers = params.questionIds.map((guid) => {
        return mapAnswer(guid, params.answers.byId[guid])
      })
      req.setAnswersList(answers)

      return callMyHuttBffService('submitSelectionRecInterview', [req])
    },
    []
  )

  return { requestSubmitSelectionRecInterview }
}

const mapAnswer = (
  guid: Guid,
  answer: RecInterviewAnswer
): SubmitSelectionRecInterviewRequest.Answer => {
  const res = new SubmitSelectionRecInterviewRequest.Answer()
  res.setQuestionGuid(guid)

  switch (answer.kind) {
    case 'Text':
      const text = new SubmitSelectionRecInterviewRequest.Answer.Text()
      text.setContent(answer.content)
      res.setText(text)
      return res
    case 'Video':
      const video = new SubmitSelectionRecInterviewRequest.Answer.Video()
      video.setFileKey(answer.key)
      res.setVideo(video)
      return res
  }
}
