import React from 'react'
import { Modal, WeakColorSeparator, ErrorBox } from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { Box } from '../Box'

export const MediaErrorModal: React.VFC = () => (
  <ModalWithMargin active title="カメラ・マイクエラー">
    <Box m="12px 16px 40px">
      <ErrorBox>
        カメラ・マイクへのアクセスが許可されていません。
        <br />
        ブラウザの設定からカメラ・マイクへのアクセスを許可し、再読込してください。
        <br />
        ※再読込で撮影前の画面に戻ります
      </ErrorBox>
    </Box>
    <WeakColorSeparator />
    <Box m="12px auto">
      <TertiaryButton
        onClick={() => window.location.reload()}
        comlinkPushParams={{ action: 'reload_on_media_error_modal' }}
        widthSize="L1"
        heightSize={spButtonHeightSize}
      >
        再読込
      </TertiaryButton>
    </Box>
  </ModalWithMargin>
)

const ModalWithMargin = styled(Modal)`
  margin: 20px;
`
