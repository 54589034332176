import React from 'react'
import {
  theme,
  Txt,
  WeakColorSeparator,
  useResponsive,
} from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { Box } from '@/components/Box'

type TutorialProps = {
  toNextStep: () => void
}

export const QuestionTutorial: React.VFC<TutorialProps> = (props) => (
  <QuestionTutorialWrapper>
    <TutorialBalloon arrowPosition="top">
      <Box
        height="70px"
        paddingTop="10px"
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="space-evenly"
      >
        <ResponsiveTxt>
          こちらのページで
          <br />
          設問内容を確認しましょう。
        </ResponsiveTxt>
      </Box>
      <WeakColorSeparator />
      <Box m="11px">
        <TertiaryButton
          onClick={props.toNextStep}
          comlinkPushParams={{ action: 'confirm_question_tutorial' }}
          widthSize="L1"
          heightSize={spButtonHeightSize}
        >
          OK
        </TertiaryButton>
      </Box>
    </TutorialBalloon>
  </QuestionTutorialWrapper>
)

export const LimitationTutorial: React.VFC<TutorialProps> = (props) => {
  const { responsive } = useResponsive()
  return (
    <LimitationTutorialWrapper>
      <TutorialBalloon
        arrowPosition={responsive.spPortrait ? 'topRight' : 'rightTop'}
      >
        <Box m="12px">
          <ResponsiveTxt>
            制限がある設問は、
            <br />
            指定された制限内に回答してください。 <br />
            <br />
            <b>撮影開始までの時間</b>
            <br />
            指定された時間内に撮影を開始してください。
            <br />
            <b>撮影できる回数</b>
            <br />
            指定された回数まで撮影することができます。
          </ResponsiveTxt>
        </Box>
        <WeakColorSeparator />
        <Box m="11px">
          <TertiaryButton
            onClick={props.toNextStep}
            comlinkPushParams={{
              action: 'confirm_question_tutorial_limitation',
            }}
            widthSize="L1"
            heightSize={spButtonHeightSize}
          >
            OK
          </TertiaryButton>
        </Box>
      </TutorialBalloon>
    </LimitationTutorialWrapper>
  )
}

export const StartRecordingTutorial: React.VFC<
  TutorialProps & { limitation: boolean }
> = (props) => (
  <StartRecordingTutorialWrapper>
    <TutorialBalloon arrowPosition="bottomCenter">
      <Box m="12px">
        <ResponsiveTxt>準備ができたら撮影を開始しましょう。</ResponsiveTxt>
        {props.limitation && (
          <ResponsiveTxt>
            制限時間になると自動的に撮影が開始されます。
          </ResponsiveTxt>
        )}
      </Box>
      <WeakColorSeparator />
      <Box m="11px">
        <TertiaryButton
          onClick={props.toNextStep}
          comlinkPushParams={{
            action: 'confirm_tutorial_start_recording',
          }}
          widthSize="L1"
          heightSize={spButtonHeightSize}
        >
          OK
        </TertiaryButton>
      </Box>
    </TutorialBalloon>
  </StartRecordingTutorialWrapper>
)

const TutorialBalloon = styled.div<{
  arrowPosition: 'top' | 'bottomCenter' | 'topRight' | 'rightTop' | 'none'
}>`
  width: 100%;
  height: 100%;
  background: ${theme.color.white[1]};
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid ${theme.color.black[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &&:before {
    content: '';
    position: absolute;
    left: ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottomCenter':
        case 'top':
          return '50%'
        case 'topRight':
          return '90%'
        case 'rightTop':
          return '100%'
      }
    }};
    top: ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'rightTop':
          return '18px'
      }
    }};
    margin-left: -6px;
    border: 6px solid transparent;
    ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottomCenter':
          return css`
            top: 100%;
            border-top: 10px solid ${theme.color.black[1]};
          `
        case 'top':
          return css`
            bottom: 100%;
            border-bottom: 10px solid ${theme.color.black[1]};
          `
        case 'topRight':
          return css`
            bottom: 100%;
            border-bottom: 10px solid ${theme.color.black[1]};
          `
        case 'rightTop':
          return css`
            right: 100%;
            border-left: 10px solid ${theme.color.black[1]};
            margin-left: 0px;
          `
      }
    }}
  }
  &&:after {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    left: ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'top':
          return '50%'
      }
    }};
    ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottomCenter':
          return css`
            top: 99%;
            border-top: 8px solid ${theme.color.white[1]};
          `
        case 'top':
          return css`
            bottom: 99%;
            border-bottom: 8px solid ${theme.color.white[1]};
            margin-left: -4px;
          `
        case 'topRight':
          return css`
            bottom: 99%;
            border-bottom: 8px solid ${theme.color.white[1]};
            left: 88.8%;
          `
        case 'rightTop':
          return css`
            left: 99%;
            top: 20px;
            border-left: 8px solid ${theme.color.white[1]};
            margin-left: 0px;
          `
      }
    }}
  }
`

const QuestionTutorialWrapper = styled(Box)`
  position: absolute;
  max-width: calc(100vw - 22px);
  margin: 0 auto;
  z-index: 3;
  border-radius: 5px;
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        width: 230px;
        right: calc(50% - 300px);
        top: 240px;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        width: 220px;
        height: 116px;
        right: calc(50% - 180px);
        top: 252px;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        width: 220px;
        height: 116px;
        right: calc(50% - 180px);
        top: 252px;
      `
    }
  }}
`

const LimitationTutorialWrapper = styled(Box)`
  position: absolute;
  max-width: calc(100vw - 22px);
  top: 65px;
  margin: 0 auto;
  z-index: 2;
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        width: 360px;
        right: calc(50% - 160px);
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        width: 330px;
        right: calc(50% - 56px);
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        width: 330px;
        right: calc(50% - 160px);
        top: 140px;
      `
    }
  }}
`

export const TutorialOverlay = styled.div<{
  scrollHeight?: number
  fixed?: boolean
}>`
  background-color: rgba(0, 0, 0, 0.15);
  width: 100vw;
  height: ${({ scrollHeight }) => `${scrollHeight}px` ?? '100vh'}};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')}};
  ${(props) => {
    if (props.theme.responsive.spPortrait) {
      return css`
        position: fixed;
        height: 100vh;
      `
    }
  }}
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
`

const ResponsiveTxt = styled(Txt)`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const StartRecordingTutorialWrapper = styled(Box)`
  position: absolute;
  max-width: calc(100vw - 22px);
  margin: 0 auto;
  z-index: 2;
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        top: 96px;
        width: 364px;
        left: 0;
        right: 0;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        top: 156px;
        width: 340px;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        top: 132px;
        width: 330px;
        left: 0;
        right: 0;
      `
    }
  }}
`
