import React, {
  RefObject,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { theme, Txt, Responsive } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { Props as VideoRecordingProps } from '.'
import { Box } from '../Box'
import { Spacer } from '../Spacer'
import { AudioWave } from './AudioWave'
import { CaptureButton } from './CaptureButton'
import { CountdownOverlay } from './CountdownOverlay'
import { Question as _Question } from './Question'
import { Stopwatch } from './Stopwatch'
import { AudioTutorial, QuestionTutorial, TutorialOverlay } from './Tutorials'
import { initialState, reducer } from './reducer'
import { useMediaRecord } from './useMediaRecord'

type TutorialStep = 'audio' | 'question' | 'finish'

export type VideoRecordingBaseProps = VideoRecordingProps & {
  videoRef: RefObject<HTMLVideoElement>
  deviceSettingElement?: JSX.Element
  stream?: MediaStream
  responsive: Responsive
}

export const VideoRecordingBase: React.VFC<VideoRecordingBaseProps> = ({
  videoRef,
  deviceSettingElement,
  stream,
  onRecordingComplete,
  showTutorial,
  question,
  responsive,
  onStartLimitationRecording,
  hasAnswerLimitation,
  isTest = false,
}) => {
  const [tutorialStep, setTutorialStep] = useState<TutorialStep>('finish')
  const toNextStep = useCallback(() => {
    setTutorialStep((prev) => {
      switch (prev) {
        case 'audio':
          return 'question'
        case 'question':
          return 'finish'
        default:
          return prev
      }
    })
  }, [])
  useEffect(() => {
    showTutorial && setTutorialStep('audio')
  }, [showTutorial])

  const [state, dispatch] = useReducer(reducer, initialState)
  const { startRecording, stopRecording } = useMediaRecord({
    onRecordingComplete,
  })

  const [countStarted, setCountStarted] = useState(false)
  const onStartCountdown = useCallback(async () => {
    if (!stream) return
    if (!stream.active) {
      alert('カメラ・マイクを許可してください')
      return
    }
    if (hasAnswerLimitation) {
      if (countStarted) return
      setCountStarted(true)
      dispatch(['COUNT_START_RECORDING'])
      try {
        await onStartLimitationRecording()
      } catch (err) {
        dispatch(['RESET_RECORDING'])
        throw err
      }
    }
    dispatch(['REQUEST_START_RECORDING'])
  }, [
    dispatch,
    stream,
    onStartLimitationRecording,
    hasAnswerLimitation,
    countStarted,
  ])

  const onTickCountdown = useCallback(() => {
    dispatch(['TICK_COUNTDOWN_TIMER'])
  }, [dispatch])

  const onFinishCountdown = useCallback(() => {
    if (!stream) return
    dispatch(['FINISH_COUNTDOWN'])
    startRecording(stream)
  }, [dispatch, stream, startRecording])

  const onCaptureEnd = useCallback(() => {
    dispatch(['REQUEST_FINISH_RECORDING'])
    stopRecording()
  }, [dispatch, stopRecording])

  useEffect(() => {
    if (!isTest) onStartCountdown()
  }, [isTest, stream, onStartCountdown])

  return (
    <Box width="100%" height="100%" position="relative">
      {tutorialStep !== 'finish' && <TutorialOverlay />}
      {state.status === 'countdown' && (
        <CountdownOverlay
          state={state}
          onTickCountdown={onTickCountdown}
          onFinishCountdown={onFinishCountdown}
        />
      )}
      <Box alignItems="center" width="100%" height="100%">
        <Box
          position="absolute"
          display="flex"
          width="100%"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            zIndex={
              tutorialStep !== 'question' && tutorialStep !== 'finish' ? 0 : 3
            }
          >
            <Box>
              <Question {...question} responsive={responsive} />
            </Box>
            {tutorialStep === 'question' && (
              <QuestionTutorial
                toNextStep={toNextStep}
                onStartCountdown={onStartCountdown}
              />
            )}
          </Box>
        </Box>
        <Video ref={videoRef} autoPlay playsInline muted />
        {isTest && tutorialStep === 'finish' && (
          <Box
            position="absolute"
            bottom="130px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="32px"
            backgroundColor={theme.color.white[1]}
          >
            <TestRecordingTxt>お試し撮影</TestRecordingTxt>
          </Box>
        )}
        <Box
          position="absolute"
          bottom="90px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          textShadow="0px 0px 2px #000000"
        >
          {((isTest && tutorialStep === 'finish') || !isTest) && (
            <Stopwatch status={state.status} />
          )}
        </Box>
        <Box
          position="absolute"
          bottom="16px"
          left="0"
          right="0"
          display="flex"
          alignItems="center"
          flexDirection="column"
          zIndex={tutorialStep !== 'finish' ? 0 : 2}
        >
          {((isTest && tutorialStep === 'finish') || !isTest) && (
            <CaptureButton status={state.status} onCaptureEnd={onCaptureEnd} />
          )}
        </Box>
        <Box
          position="absolute"
          bottom="12px"
          left="12px"
          display="flex"
          zIndex={tutorialStep !== 'audio' && tutorialStep !== 'finish' ? 0 : 4}
        >
          <WaveCircle>
            <AudioWave
              width="30px"
              height="30px"
              maxBarHeight={30}
              waveBarLength={3}
              barColor={theme.color.white[1]}
              audioStream={stream}
              hideHorizontalLine
            />
          </WaveCircle>
          {tutorialStep === 'audio' && (
            <AudioTutorial toNextStep={toNextStep} />
          )}
          <Spacer ml="8px" />
          {tutorialStep === 'audio' && responsive.pc && deviceSettingElement}
        </Box>
      </Box>
    </Box>
  )
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.darkGray[1]};
`

const WaveCircle = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${theme.color.navy[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Question = styled(_Question)`
  margin: 8px;
`

const TestRecordingTxt = styled(Txt).attrs({
  color: theme.color.navy[1],
  bold: true,
})`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: 26px;
        `
      : css`
          font-size: 22px;
        `}
`
