import { Modal, WeakColorSeparator, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Box } from '@/components/Box'
import {
  StretchedPrimaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'

export type Direction = 'back' | 'next'
type Props = {
  active: boolean
  direction: Direction
  onClose: () => void
  onFinishAnswer: () => void
}

export const FixAnswerModal: React.VFC<Props> = (props) => {
  const direction = props.direction === 'next' ? '次' : '前'
  const action = props.direction === 'next' ? '次に進む' : '前に戻る'

  return (
    <Modal
      active={props.active}
      title={action}
      onClose={props.onClose}
      size="s"
      overflowVisible
    >
      <ModalContent>
        <Box paddingY="16px" paddingBottom="32px">
          <Txt>
            回答内容を確定して
            <br />
            {direction}の設問ページに
            {props.direction === 'next' ? '進み' : '戻り'}ます。
          </Txt>
          <Txt bold>
            時間や回数が残っていても、{action}と<br />
            この設問の回答は修正できなくなります。
          </Txt>
        </Box>
      </ModalContent>
      <WeakColorSeparator />
      <ModalFooter>
        <ButtonGroup>
          <StretchedTertiaryButton
            onClick={props.onClose}
            comlinkPushParams={{
              action: `click_cancel_button_on_finish_answer_${props.direction}_modal`,
            }}
          >
            キャンセル
          </StretchedTertiaryButton>
          <StretchedPrimaryButton
            onClick={props.onFinishAnswer}
            comlinkPushParams={{
              action: `click_finish_answer_button_on_finish_answer_${props.direction}_modal`,
            }}
          >
            {action}
          </StretchedPrimaryButton>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 8px;
  }
`
