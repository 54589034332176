import { useCallback } from 'react'
import { GetVideoSeminarRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type RequestGetVideoSeminarParams = {
  videoSeminarGuid: string
  videoSeminarTicketGuid: string
}
export function useRequestGetVideoSeminar() {
  const requestGetVideoSeminar = useCallback(
    (params: RequestGetVideoSeminarParams) => {
      const req = new GetVideoSeminarRequest()
      req.setVideoSeminarGuid(params.videoSeminarGuid)
      req.setVideoSeminarTicketGuid(params.videoSeminarTicketGuid)

      return callMyHuttBffService('getVideoSeminar', [req])
    },
    []
  )

  return { requestGetVideoSeminar }
}
