import { useCallback } from 'react'
import { StartRecInterviewAnswerRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type StartRecInterviewAnswerParams = {
  recInterviewGuid: string
  questionGuid: string
}

export function useRequestStartRecInterviewAnswer() {
  const requestStartRecInterviewAnswer = useCallback(
    (params: StartRecInterviewAnswerParams) => {
      const req = new StartRecInterviewAnswerRequest()

      req.setRecInterviewGuid(params.recInterviewGuid)
      req.setRecInterviewQuestionGuid(params.questionGuid)

      return callMyHuttBffService('startRecInterviewAnswer', [req])
    },
    []
  )

  return { requestStartRecInterviewAnswer }
}
