import { useMemo, useRef, useEffect, useCallback, RefObject } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { useRequestSendVideoSeminarLog } from '@/services/bffService'

type LogOnPlayParam = {
  playID: string
  currentTime: number
  isFirstPlay: boolean
}
type LogOnPauseParam = {
  playID: string
  currentTime: number
}

class PlayIDManager {
  playID: string

  constructor() {
    this.playID = ''
  }

  reset(): void {
    this.playID = ''
  }

  genPlayID(): string {
    return uuid()
  }

  play(): string {
    if (this.playID !== '') {
      throw new Error('`play()` was called twice in a row.')
    }
    this.playID = this.genPlayID()
    return this.playID
  }

  pause(): string {
    const id = this.playID
    if (id === '') {
      throw new Error('There is no corresponding playID.')
    }
    this.playID = ''
    return id
  }
}

type Params = {
  videoSeminarTicketGuid: string
  videoSeminarContentGuid: string
  videoRef: RefObject<HTMLVideoElement>
}
/**
 * 動画の各種ログを送信する
 */
export const useVideoLog = ({
  videoSeminarContentGuid,
  videoSeminarTicketGuid,
  videoRef,
}: Params) => {
  const { requestSendVideoSeminarLog } = useRequestSendVideoSeminarLog()

  const playIdManager = useMemo(() => new PlayIDManager(), [])
  const firstPlayRef = useRef(true)

  const logOnPlay = useCallback(
    (param: LogOnPlayParam) => {
      requestSendVideoSeminarLog({
        videoSeminarTicketGuid,
        metadata: {
          type: 'play',
          videoSeminarContentGuid,
          playId: param.playID,
          playbackTimeSeconds: param.currentTime,
          isFirstPlay: param.isFirstPlay,
        },
      })
    },
    [
      requestSendVideoSeminarLog,
      videoSeminarTicketGuid,
      videoSeminarContentGuid,
    ]
  )

  const logOnPause = useCallback(
    (param: LogOnPauseParam) => {
      requestSendVideoSeminarLog({
        videoSeminarTicketGuid,
        metadata: {
          type: 'pause',
          videoSeminarContentGuid,
          playId: param.playID,
          playbackTimeSeconds: param.currentTime,
        },
      })
    },
    [
      requestSendVideoSeminarLog,
      videoSeminarTicketGuid,
      videoSeminarContentGuid,
    ]
  )

  const sendPlayLog = useCallback(
    (playbackTimeSeconds: number) => {
      if (!videoRef.current) return
      let playID = ''
      try {
        playID = playIdManager.play()
      } catch {
        playIdManager.reset()
      }
      if (playID) {
        logOnPlay({
          currentTime: playbackTimeSeconds,
          playID,
          isFirstPlay: firstPlayRef.current,
        })
        firstPlayRef.current = false
      }
    },
    [logOnPlay, playIdManager, videoRef]
  )

  const sendPauseLog = useCallback(
    (playbackTimeSeconds: number) => {
      if (!videoRef.current) return
      let playID = ''
      try {
        playID = playIdManager.pause()
      } catch {
        // 対応するplayのplayIDが無いときは、片割れのplayIDを生成してログ送信する
        playID = playIdManager.genPlayID()
      }
      if (playID) {
        logOnPause({
          currentTime: playbackTimeSeconds,
          playID,
        })
      }
    },
    [logOnPause, playIdManager, videoRef]
  )

  // 画面遷移した時に再生中であればpauseログを送る
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (videoRef.current && !videoRef.current.paused) {
        sendPauseLog(videoRef.current.currentTime)
      }
    })
    return unlisten
  }, [history, sendPauseLog, videoRef])

  // 動画を最後まで見たとき、初回再生フラグをリセットする
  useEffect(() => {
    const ref = videoRef.current
    const resetFirstPlay = () => {
      firstPlayRef.current = true
    }
    ref?.addEventListener('ended', resetFirstPlay)

    return () => {
      ref?.removeEventListener('ended', resetFirstPlay)
    }
  }, [videoRef])

  // 再生中にタブを閉じるなどでページを離れた時、pauseログを送信する
  useEffect(() => {
    let requestedPauseLogOnLeavePage = false
    const onLeavePage = () => {
      if (requestedPauseLogOnLeavePage) return
      if (!videoRef.current) return
      if (videoRef.current.paused) return
      requestedPauseLogOnLeavePage = true
      sendPauseLog(videoRef.current.currentTime)
    }
    window.addEventListener('unload', onLeavePage)
    window.addEventListener('beforeunload', onLeavePage)
    window.addEventListener('pagehide', onLeavePage)

    return () => {
      window.removeEventListener('unload', onLeavePage)
      window.removeEventListener('beforeunload', onLeavePage)
      window.removeEventListener('pagehide', onLeavePage)
    }
  }, [sendPauseLog, videoRef])

  return {
    sendPlayLog,
    sendPauseLog,
  }
}
