import { useCallback } from 'react'
import { CompleteRecInterviewVideoUploadRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

type Part = {
  etag: string
  partNumber: number
}

type Params = {
  key: string
  uploadId: string
  parts: Part[]
  recInterviewGuid: string
}

export function useRequestCompleteRecInterviewVideoUpload() {
  const requestCompleteRecInterviewVideoUpload = useCallback(
    (params: Params) => {
      const req = new CompleteRecInterviewVideoUploadRequest()

      req.setKey(params.key)
      req.setUploadId(params.uploadId)

      const partsList = params.parts.map((p) => {
        const part = new CompleteRecInterviewVideoUploadRequest.Part()
        part.setEtag(p.etag)
        part.setPartNumber(p.partNumber)
        return part
      })
      req.setPartsList(partsList)

      req.setRecInterviewGuid(params.recInterviewGuid)

      return callMyHuttBffService('completeRecInterviewVideoUpload', [req])
    },
    []
  )

  return { requestCompleteRecInterviewVideoUpload }
}
