import React from 'react'
import { Icon, NewTabLink, theme, Txt } from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { StretchedSecondaryButton } from '@/components/StretchedButton'
import { VideoPlayer } from '@/components/VideoPlayer'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Message } from '../../components/Message'

type Props = {
  testRecordingBlob: Blob
  onNext: () => void
  onReRecordRequest: () => void
}

export const TestRecordingConfirm: React.VFC<Props> = (props) => {
  return (
    <Form.Wrapper>
      <Form.Body>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Txt size="xl">お試し撮影</Txt>
        </Box>
        <Box mt="20px">
          <Message
            title="撮影動画を確認しましょう"
            content="「映像が映っているか」「音声が聞こえているか」を確認してください。"
          />
        </Box>
        <Box display="flex" justifyContent="center" mt="12px">
          {/* 再生時、フルスクリーンに置き換える */}
          <VideoPlayer
            srcObject={props.testRecordingBlob}
            // 4:3
            maxHeight={278}
            maxWidth={370}
          />
        </Box>
        <Box display="flex" justifyContent="center" mt="28px">
          <TertiaryButton
            widthSize="L1"
            heightSize={spButtonHeightSize}
            comlinkPushParams={{
              action: 'click_re_record_button',
            }}
            onClick={props.onReRecordRequest}
            icon={<RetakeIcon />}
          >
            撮りなおす
          </TertiaryButton>
        </Box>
        <Box
          mt="20px"
          display="flex"
          backgroundColor={theme.color.gray[5]}
          p="12px"
        >
          <Box minWidth="24px">
            <Icon name="light-bulb" width="24px" height="24px" />
          </Box>
          <Box ml="8px">
            <Txt>
              一部の端末では、撮影した最後の2,3秒が
              <br />
              記録されていない場合があります。
              <br />
              <Bold>動画の最後が正しく終了しているか必ず確認しましょう。</Bold>
              <br />
              残らない場合は、話し終わってから3秒程度待機してから終了ボタンを押すようにしてください。
            </Txt>
          </Box>
        </Box>
        <Box mt="8px">
          <Txt>
            問題がある場合は、
            <NewTabLink
              href={EXTERNAL_URLS.help.top}
              action="open_help_page_on_test_recording_confirm_page"
            >
              ヘルプページ
            </NewTabLink>
            を確認してください
          </Txt>
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          <StretchedSecondaryButton
            comlinkPushParams={{
              action: 'click_next_on_test_recording_confirm_page',
            }}
            onClick={props.onNext}
          >
            お試し撮影を完了する
          </StretchedSecondaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

const Bold = styled.span`
  font-weight: bold;
`

const RetakeIcon = styled(Icon).attrs({ name: 'movie' })`
  margin-right: 10px;
`
