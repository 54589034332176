import { FormPage as F } from '@blue-agency/im-shared-front'
import styled from 'styled-components'

const Wrapper = styled(F.Wrapper)`
  width: 100vw;
`

const Body = styled(F.Body)`
  margin-top: 20px;
  width: ${(props) => props.theme.contentWidth};
`

export const Form = {
  Wrapper,
  Body,
}
