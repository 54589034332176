import { useMemo, useRef, useEffect, useCallback, useState } from 'react'
import Hls from 'hls.js'
import { useFullScreenHandle } from 'react-full-screen'
import { useVideoLog } from '../useVideoLog'
import { PlayerControlsProps } from './PlayerControls'
import type { Rate, PlaybackRateOption } from './PlayerControls/PlaybackRate'

const playbackRateOptions: PlaybackRateOption[] = [
  {
    rate: 0.75,
    label: '0.75x',
  },
  {
    rate: 1,
    label: '1x (標準)',
  },
  {
    rate: 1.25,
    label: '1.25x',
  },
  {
    rate: 1.5,
    label: '1.5x',
  },
  {
    rate: 1.75,
    label: '1.75x',
  },
  {
    rate: 2,
    label: '2x',
  },
]

type Param = {
  src: string
  videoSeminarTicketGuid: string
  videoSeminarContentGuid: string
}
export const useVideoControl = (param: Param) => {
  const isSupportBrowser = useMemo(() => Hls.isSupported(), [])
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [volume, setVolume] = useState(1.0)
  const [isMuted, setIsMuted] = useState(false)
  const [playbackRate, setPlaybackRate] = useState<Rate>(1.0)
  const fullScreenHandle = useFullScreenHandle()
  const [canPlay, setCanPlay] = useState(false)

  useEffect(() => {
    if (!videoRef.current) return

    if (isSupportBrowser) {
      const hls = new Hls({ xhrSetup: (xhr) => (xhr.withCredentials = true) })
      hls.attachMedia(videoRef.current)
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(param.src)
        if (!videoRef.current) return

        videoRef.current.oncanplay = () => {
          if (!videoRef.current) return
          setDuration(videoRef.current.duration)
          setCanPlay(true)
        }

        hls.on(Hls.Events.ERROR, function (_event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError()
                break
              default:
                hls.destroy()
                window.alert(
                  '読込中にエラーが発生しました。リロードしてください。'
                )
                break
            }
          }
        })
      })
      return () => {
        hls.removeAllListeners()
        hls.stopLoad()
      }
    } else {
      videoRef.current.src = param.src
    }
  }, [param.src, isSupportBrowser])

  const { sendPlayLog, sendPauseLog } = useVideoLog({
    videoRef,
    videoSeminarContentGuid: param.videoSeminarContentGuid,
    videoSeminarTicketGuid: param.videoSeminarTicketGuid,
  })

  const onPlay = useCallback(() => {
    if (!canPlay) return
    if (!videoRef.current) return
    videoRef.current.play().catch((e) => {
      // 「AbortError: The play() request was interrupted by a call to pause().」が出るのでcatchする
      if (e.name === 'AbortError') {
        return
      }
      throw e
    })
    setIsPlaying(true)
    sendPlayLog(currentTime)
  }, [canPlay, sendPlayLog, currentTime])

  const onPause = useCallback(() => {
    if (!videoRef.current) return
    videoRef.current.pause()
    setIsPlaying(false)
    sendPauseLog(currentTime)
  }, [sendPauseLog, currentTime])

  // 動画が進行したとき、Reactで管理している currentTime と同期する
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    const syncCurrentTime = () => {
      setCurrentTime(ref.currentTime)
    }
    ref.addEventListener('timeupdate', syncCurrentTime)

    return () => {
      ref.removeEventListener('timeupdate', syncCurrentTime)
    }
  }, [])

  // 動画を最後まで見たとき、以下の処理を行う
  // 1. 動画の再生を停止
  // 2. pauseログを送る
  // 3. 0秒地点に戻る
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    const onEnded = () => {
      onPause()
      ref.currentTime = 0
      setCurrentTime(0)
    }
    ref.addEventListener('ended', onEnded)

    return () => {
      ref.removeEventListener('ended', onEnded)
    }
  }, [onPause])

  const [shouldPlayAfterSeek, setShouldPlayAfterSeek] = useState(false)

  const onSeekStart = useCallback(() => {
    if (videoRef.current === null) return
    // シークバー上でマウス操作をしたタイミングで、動画が再生中の状態である場合、
    // いったん一時停止状態にし、かつ、シーク後に自動再生されるようにフラグを立てておく
    if (!videoRef.current.paused) {
      setShouldPlayAfterSeek(true)
      onPause()
    }
  }, [onPause])

  const onSeekChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current === null) return
    // シークバーでマウスを操作して、シークバーの位置が変わったときに、その位置をReactで管理しているcurrentTimeと、HTMLMediaElementのcurrentTimeに同期する
    const t = Number(e.target.value)
    videoRef.current.currentTime = t
    setCurrentTime(t)
  }, [])

  const onSeekEnd = useCallback(() => {
    // シークバー上で操作をしたあと、クリック状態からニュートラル状態に戻ったとき、
    // シーク前に動画が再生状態であったならば、自動再生をする
    if (shouldPlayAfterSeek) {
      setShouldPlayAfterSeek(false)
      onPlay()
    }
  }, [onPlay, shouldPlayAfterSeek])

  const onVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (videoRef.current === null) return
      const v = Number(e.target.value)
      videoRef.current.volume = v
      setVolume(v)
    },
    []
  )

  const onPlaybackRateChange = useCallback((rate: Rate) => {
    if (videoRef.current === null) return
    videoRef.current.playbackRate = rate
    setPlaybackRate(rate)
  }, [])

  const onMuteToggle = useCallback(() => {
    if (videoRef.current === null) return
    if (isMuted) {
      videoRef.current.muted = false
      setIsMuted(false)
    } else {
      videoRef.current.muted = true
      setIsMuted(true)
    }
  }, [isMuted])

  const isFullscreen = fullScreenHandle.active

  const onFullscreenToggle = useCallback(() => {
    if (fullScreenHandle.active) {
      fullScreenHandle.exit()
    } else {
      fullScreenHandle.enter()
    }
  }, [fullScreenHandle])

  const controlsProps: PlayerControlsProps = {
    currentTime,
    duration,
    volume,
    isMuted,
    isPlaying,
    playbackRate,
    playbackRateOptions,
    isFullscreen,
    onSeekChange,
    onSeekEnd,
    onSeekStart,
    onVolumeChange,
    onPlay,
    onPause,
    onMuteToggle,
    onPlaybackRateChange,
    onFullscreenToggle,
  }

  return {
    videoRef,
    fullScreenHandle,
    controlsProps,
    canPlay,
  }
}
