import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CircularProgress,
  ErrorBox,
  Icon,
  Modal,
  theme,
  toast,
  Txt,
} from '@blue-agency/rogue'
import {
  PrimaryButton,
  TertiaryButton,
  spButtonHeightSize,
} from '@blue-agency/rogue/im'
import assert from 'assert'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { Loading } from '@/components/Loading'
import { Spacer } from '@/components/Spacer'
import {
  StretchedDangerButton,
  StretchedPrimaryButton,
  StretchedSecondaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { VideoPlayer } from '@/components/VideoPlayer'
import { VideoRecording } from '@/components/VideoRecording'
import { useModal } from '@/hooks/useModal'
import {
  Guid,
  VideoQuestion as VideoQuestionType,
  VideoAnswer,
  WithDispatch,
} from '@/pages/SelectionRecInterviewPage/types'
import { useRequestGetRecInterviewPreviewVideoURL } from '@/services/bffService/useRequestGetRecInterviewPreviewVideoURL'
import { QUERY_KEY } from '@/services/queryKeyService'
import { buttonResetStyle } from '@/styles'
import { storeAnswerOnLocalStorage } from '../../storeAnswer'
import { FileSelectButton } from './FileSelectButton'
import { FixAnswerModal } from './FixAnswerModal'
import { LimitedQuestion } from './LimitedQuestion'
import { Question } from './Question'
import { useAnswerLimitation, State } from './useAnswerLimitation'
import { useMultipartUpload } from './useMultipartUpload'

type Props = WithDispatch<{
  recInterviewGuid: Guid
  selectionStepGuid: Guid
  questionGuid: Guid
  question: VideoQuestionType
  questionIndex: number
  questionCount: number
  allowRecordedVideo: boolean
  answer?: VideoAnswer
  canGoBack?: boolean
}>

export const VideoQuestion: React.VFC<Props> = (props) => {
  const limitationCtx = useAnswerLimitation({
    ...props.question,
    recInterviewGuid: props.recInterviewGuid,
    questionGuid: props.questionGuid,
  })

  if (limitationCtx.isLoading) {
    return <Loading />
  }

  if (limitationCtx.hideQuestion) {
    return (
      <LimitedQuestion
        questionIndex={props.questionIndex}
        questionCount={props.questionCount}
        question={props.question}
        limitation={{
          ...props.question,
          ...limitationCtx.state.limitation,
        }}
        canGoBack={props.canGoBack}
        onStartAnswerButtonClick={limitationCtx.onStartAnswer}
      />
    )
  }

  return props.answer?.key ? (
    <VideoQuestionWithPreview
      {...props}
      previewAnswerKey={props.answer.key}
      limitationCtx={limitationCtx}
    />
  ) : (
    <VideoQuestionBase {...props} limitationCtx={limitationCtx} />
  )
}

type LimitationCtx = {
  state: State
  isFixed: boolean
  isLimited: boolean
  AutoStartTrigger: boolean
  enableAnswer: boolean
  onFinishAnswer: () => void
  onStartLimitationRecording: () => void
}

const VideoQuestionWithPreview: React.VFC<
  Props & { previewAnswerKey: string; limitationCtx: LimitationCtx }
> = (props) => {
  const { requestGetRecInterviewPreviewVideoURL } =
    useRequestGetRecInterviewPreviewVideoURL()
  const previewVideo = useQuery(
    [
      QUERY_KEY.getRecInterviewPreviewVideoURL,
      props.recInterviewGuid,
      props.questionGuid,
      props.previewAnswerKey,
    ],
    () =>
      requestGetRecInterviewPreviewVideoURL({
        recInterviewGuid: props.recInterviewGuid,
        questionGuid: props.questionGuid,
        key: props.previewAnswerKey,
      }),
    // 取得したsigned urlは60分有効なので、55分間はcacheして使い回す
    { staleTime: 1000 * 60 * 55 }
  )
  if (previewVideo.isError) return <VideoQuestionBase {...props} />
  if (previewVideo.isLoading) return <Loading />
  return (
    <VideoQuestionBase
      previewVideoUrl={previewVideo.data?.getUrl()}
      initialAnswerKey={props.previewAnswerKey}
      {...props}
    />
  )
}

const maxVideoBlobSize = 1 * 1024 * 1024 * 1024 // 1GiB
const minVideoBlobSize = 0.2 * 1024 * 1024 // 0.2MiB 大体1sほどの撮影時間を想定
const alertMessage = {
  minVideoBlobSize: `撮影時間が短すぎます。
もう一度撮影してください。`,
  maxVideoBlobSize: `撮影したファイルが大きすぎるため、撮影時間を短くしてください。
※アップロードファイルのサイズ上限は1GBです。`,
  uploadError: '通信状態が良い環境で再度アップロードしてください。',
}
const VideoQuestionBase: React.VFC<
  Props & {
    previewVideoUrl?: string
    initialAnswerKey?: string
    limitationCtx: LimitationCtx
  }
> = (props) => {
  const [errorBoxMessage, setErrorBoxMessage] = useState('')
  const [answerKey, setAnswerKey] = useState(props.initialAnswerKey || '')
  const [isRecordingVideo, setIsRecordingVideo] = useState(false)
  const [ignorePreviewVideoUrl, setIgnorePreviewVideoUrl] = useState(false)
  const [videoBlob, setVideoBlob] = useState<Blob>()
  const inputRef = useRef<HTMLInputElement>(null)
  const unselectModal = useModal()
  const rerecordModal = useModal()
  const reselectModal = useModal()
  const finishAnswerOnNextModal = useModal()
  const finishAnswerOnBackModal = useModal()

  const multipartUpload = useMultipartUpload({
    recInterviewGuid: props.recInterviewGuid,
    questionGuid: props.questionGuid,
    selectionStepGuid: props.selectionStepGuid,
  })

  const onRecordingComplete = useCallback(
    async (blob: Blob) => {
      if (blob.size < minVideoBlobSize) {
        setIsRecordingVideo(false)
        setErrorBoxMessage(alertMessage.minVideoBlobSize)
        return
      }
      if (maxVideoBlobSize < blob.size) {
        setIsRecordingVideo(false)
        setErrorBoxMessage(alertMessage.maxVideoBlobSize)
        return
      }
      setErrorBoxMessage('')
      setVideoBlob(blob)
      setIsRecordingVideo(false)
      let key: string
      try {
        key = await multipartUpload.upload(blob)
      } catch (err) {
        window.alert(alertMessage.uploadError)
        throw err
      }
      setAnswerKey(key)
    },
    [multipartUpload]
  )

  const onFileSelectButtonClick = useCallback(() => {
    if (!answerKey) {
      inputRef.current?.click()
      return
    }
    reselectModal.open()
  }, [reselectModal, answerKey])

  const onReselsectModalSelectButtonClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const onFileInputChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorBoxMessage('')
      reselectModal.close()
      const file = e.target?.files?.[0]
      if (!file) return
      if (maxVideoBlobSize < file.size) {
        setErrorBoxMessage(alertMessage.maxVideoBlobSize)
        return
      }
      setVideoBlob(file)
      let key: string
      try {
        key = await multipartUpload.upload(file)
      } catch (err) {
        window.alert(alertMessage.uploadError)
        throw err
      }
      setAnswerKey(key)
    },
    [multipartUpload, reselectModal]
  )

  const clearStoredAnswer = useCallback(() => {
    setVideoBlob(undefined)
    setIgnorePreviewVideoUrl(true)
    multipartUpload.resetState()
    setAnswerKey('')
    storeAnswerOnLocalStorage(props.selectionStepGuid, props.questionGuid, {
      questionGuid: props.questionGuid,
      kind: 'Video',
      key: '',
    })
  }, [multipartUpload, props.questionGuid, props.selectionStepGuid])

  const onUnselectButtonClick = useCallback(() => {
    clearStoredAnswer()
    unselectModal.close()
    toast('動画を削除しました')
  }, [clearStoredAnswer, unselectModal])

  const onRecordButtonClick = useCallback(() => {
    if (!answerKey) {
      setIsRecordingVideo(true)
      return
    }
    rerecordModal.open()
  }, [answerKey, rerecordModal])

  const onRerecordModalRecordButtonClick = useCallback(() => {
    clearStoredAnswer()
    setIsRecordingVideo(true)
    rerecordModal.close()
  }, [clearStoredAnswer, rerecordModal])

  const onRestartUploadButtonClick = useCallback(async () => {
    setErrorBoxMessage('')
    assert(videoBlob)
    let key: string
    try {
      key = await multipartUpload.upload(videoBlob)
    } catch (err) {
      window.alert(alertMessage.uploadError)
      throw err
    }
    setAnswerKey(key)
  }, [videoBlob, multipartUpload])

  const onBackButtonClick = useCallback(() => {
    if (props.question.hasAnswerLimitation && !props.limitationCtx.isFixed) {
      finishAnswerOnBackModal.open()
      return
    }
    props.dispatch([
      'SET_ANSWER',
      {
        questionGuid: props.questionGuid,
        answer: {
          questionGuid: props.questionGuid,
          kind: 'Video',
          key: answerKey,
        },
      },
    ])
  }, [props, answerKey, finishAnswerOnBackModal])

  const onFinishAnswerToBack = useCallback(async () => {
    await props.limitationCtx.onFinishAnswer()
    finishAnswerOnBackModal.close()
    props.dispatch([
      'SET_ANSWER',
      {
        questionGuid: props.questionGuid,
        answer: {
          questionGuid: props.questionGuid,
          kind: 'Video',
          key: answerKey,
        },
      },
    ])
  }, [answerKey, finishAnswerOnBackModal, props])

  const onNextButtonClick = useCallback(async () => {
    // 任意回答の場合、回答せず進んだ場合でもlocalStorageに記録しておく
    if (answerKey === '') {
      storeAnswerOnLocalStorage(props.selectionStepGuid, props.questionGuid, {
        questionGuid: props.questionGuid,
        kind: 'Video',
        key: '',
      })
    }
    if (props.question.hasAnswerLimitation && !props.limitationCtx.isFixed) {
      finishAnswerOnNextModal.open()
      return
    }
    props.dispatch([
      'SUBMIT_ANSWER',
      {
        questionGuid: props.questionGuid,
        answer: {
          questionGuid: props.questionGuid,
          kind: 'Video',
          key: answerKey,
        },
      },
    ])
  }, [answerKey, finishAnswerOnNextModal, props])

  const onFinishAnswerToNext = useCallback(async () => {
    await props.limitationCtx.onFinishAnswer()
    finishAnswerOnNextModal.close()
    props.dispatch([
      'SUBMIT_ANSWER',
      {
        questionGuid: props.questionGuid,
        answer: {
          questionGuid: props.questionGuid,
          kind: 'Video',
          key: answerKey,
        },
      },
    ])
  }, [answerKey, finishAnswerOnNextModal, props])

  const closeRecordingVideo = useCallback(() => {
    setIsRecordingVideo(false)
  }, [])

  const isAnswered =
    videoBlob !== undefined ||
    (props.previewVideoUrl !== undefined && !ignorePreviewVideoUrl)

  useEffect(() => {
    if (isAnswered) return
    if (isRecordingVideo) return
    if (props.limitationCtx.AutoStartTrigger) {
      onRecordButtonClick()
    }
  }, [
    props.limitationCtx.AutoStartTrigger,
    onRecordButtonClick,
    isAnswered,
    isRecordingVideo,
  ])

  return (
    <>
      <Form.Wrapper
        bottomHeight={
          multipartUpload.isUploading || multipartUpload.isUploaded ? 106 : 64
        }
      >
        <Form.Body marginTop={multipartUpload.isError ? 0 : undefined}>
          {errorBoxMessage && (
            <Box marginBottom="20px">
              <ErrorBox>
                <ErrorBoxTxt>{errorBoxMessage}</ErrorBoxTxt>
              </ErrorBox>
            </Box>
          )}
          {multipartUpload.isError && (
            <Box
              paddingX="16px"
              paddingY="19px"
              backgroundColor={theme.color.gray[5]}
            >
              <Txt>アップロードが中断されたデータが残っています。</Txt>
              <Box
                mt="12px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <PrimaryButton
                  widthSize="L2"
                  heightSize={spButtonHeightSize}
                  comlinkPushParams={{ action: 'click_restart_upload' }}
                  onClick={onRestartUploadButtonClick}
                >
                  アップロードを再開
                </PrimaryButton>
              </Box>
            </Box>
          )}
          <Box>
            {!isRecordingVideo && (
              <Question
                questionIndex={props.questionIndex}
                questionCount={props.questionCount}
                question={props.question}
                enableAccordion={isAnswered}
                limitation={
                  props.limitationCtx.enableAnswer
                    ? {
                        ...props.question,
                        ...props.limitationCtx.state.limitation,
                      }
                    : undefined
                }
              />
            )}
            {isAnswered && !isRecordingVideo && (
              <Box
                mt="16px"
                position="relative"
                display="flex"
                justifyContent="center"
              >
                {videoBlob ? (
                  <VideoPlayer
                    srcObject={videoBlob}
                    // 4:3
                    maxHeight={278}
                    maxWidth={370}
                  />
                ) : (
                  <VideoPlayer
                    src={props.previewVideoUrl}
                    maxHeight={278}
                    maxWidth={370}
                  />
                )}
                <Box position="relative">
                  <Box position="absolute" top="-12px" right="-12px">
                    <IconButton onClick={unselectModal.open}>
                      <Icon name="delete" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}
            <Box mt="20px" display="flex" justifyContent="center">
              {isAnswered ? (
                <TertiaryButton
                  widthSize="L1"
                  heightSize={spButtonHeightSize}
                  comlinkPushParams={{
                    action: 'click_go_to_recording_page_again',
                  }}
                  onClick={onRecordButtonClick}
                  icon={<RetakeIcon />}
                  disabled={!props.limitationCtx.enableAnswer}
                >
                  撮りなおす
                </TertiaryButton>
              ) : (
                <PrimaryButton
                  widthSize="L1"
                  heightSize={spButtonHeightSize}
                  comlinkPushParams={{
                    action: 'click_go_to_recording_page',
                  }}
                  onClick={onRecordButtonClick}
                  disabled={!props.limitationCtx.enableAnswer}
                >
                  撮影を開始
                </PrimaryButton>
              )}
            </Box>
            <Box mt="16px" display="flex" justifyContent="center">
              {props.limitationCtx.isFixed && (
                <LimitedTxt>
                  内容確定済のため、もう撮影はできません。
                </LimitedTxt>
              )}
              {props.limitationCtx.isLimited && (
                <LimitedTxt>
                  制限に達したため、もう撮影はできません。
                </LimitedTxt>
              )}
            </Box>
            {props.allowRecordedVideo && !isAnswered && (
              <Box display="flex" justifyContent="flex-end" mt="28px">
                <FileSelectButton
                  onClick={onFileSelectButtonClick}
                  onFileInputChange={onFileInputChange}
                  ref={inputRef}
                />
              </Box>
            )}
          </Box>
          <Spacer mb="16px" />
        </Form.Body>
        <Form.Bottom>
          {multipartUpload.isUploading && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              backgroundColor={theme.color.gray[5]}
              borderBottom={`1px solid ${theme.color.gray[4]}`}
            >
              <FixedWidthBox display="flex" alignItems="center" paddingY="10px">
                <CircularProgress
                  color={theme.color.green[4]}
                  size={22}
                  borderWidth={2}
                />
                <Box ml="12px">
                  <Txt>アップロード中です。しばらくお待ちください。</Txt>
                </Box>
              </FixedWidthBox>
            </Box>
          )}
          {multipartUpload.isUploaded && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              backgroundColor={theme.color.gray[5]}
              borderBottom={`1px solid ${theme.color.gray[4]}`}
            >
              <FixedWidthBox display="flex" alignItems="center" paddingY="10px">
                <CheckIcon />
                <Box ml="12px">
                  <Txt>アップロードが完了しました。</Txt>
                </Box>
              </FixedWidthBox>
            </Box>
          )}
          <BottomButtons>
            {props.canGoBack && (
              <StretchedTertiaryButton
                comlinkPushParams={{
                  action: 'click_back_on_rec_interview_video_question_page',
                }}
                disabled={multipartUpload.isUploading}
                onClick={onBackButtonClick}
              >
                前に戻る
              </StretchedTertiaryButton>
            )}
            <StretchedSecondaryButton
              comlinkPushParams={{
                action: 'click_next_on_rec_interview_video_question_page',
                metadata: {
                  questionGuid: props.questionGuid,
                  questionContent: props.question.content,
                },
              }}
              disabled={
                !canGoNext(
                  multipartUpload.isUploading,
                  props.question.required,
                  props.limitationCtx.enableAnswer,
                  errorBoxMessage,
                  answerKey
                )
              }
              onClick={onNextButtonClick}
            >
              次に進む
            </StretchedSecondaryButton>
          </BottomButtons>
        </Form.Bottom>
        <ModalWithMargin
          active={reselectModal.active}
          title="端末内の動画を選択"
          onClose={reselectModal.close}
        >
          <Box paddingX="20px" paddingY="16px">
            <Txt>この動画を破棄して、端末内の動画を選択します。</Txt>
            <Txt>よろしいですか？</Txt>
            <Txt bold>破棄した動画は、復元できませんのでご注意ください。</Txt>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderTop={`1px solid ${theme.color.gray[4]}`}
            paddingY="11px"
          >
            <Box width={144}>
              <StretchedTertiaryButton
                comlinkPushParams={{
                  action: 'click_reselect_modal_cancel',
                }}
                onClick={reselectModal.close}
              >
                キャンセル
              </StretchedTertiaryButton>
            </Box>
            <Box width={144} ml="8px">
              <StretchedPrimaryButton
                comlinkPushParams={{
                  action: 'click_reselect_modal_record',
                }}
                onClick={onReselsectModalSelectButtonClick}
              >
                動画を選択
              </StretchedPrimaryButton>
            </Box>
          </Box>
        </ModalWithMargin>
        <ModalWithMargin
          active={rerecordModal.active}
          title="撮りなおし"
          onClose={rerecordModal.close}
        >
          <Box paddingX="20px" paddingY="16px">
            <Txt>
              <Bold>撮影済みの動画を破棄</Bold>して撮りなおします。
            </Txt>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderTop={`1px solid ${theme.color.gray[4]}`}
            paddingY="11px"
          >
            <Box width={144}>
              <StretchedTertiaryButton
                comlinkPushParams={{
                  action: 'click_cancel_rerecord_modal_button',
                }}
                onClick={rerecordModal.close}
              >
                キャンセル
              </StretchedTertiaryButton>
            </Box>
            <Box width={144} ml="8px">
              <StretchedPrimaryButton
                comlinkPushParams={{
                  action: 'click_rerecord_modal_record_button',
                }}
                onClick={onRerecordModalRecordButtonClick}
              >
                撮影
              </StretchedPrimaryButton>
            </Box>
          </Box>
        </ModalWithMargin>
        <ModalWithMargin
          active={unselectModal.active}
          title="アップロードした動画を削除"
          onClose={unselectModal.close}
        >
          <Box paddingX="20px" paddingY="16px">
            <Txt>動画を削除してもよろしいですか？</Txt>
            <Txt bold>削除後は、復元できませんのでご注意ください。</Txt>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderTop={`1px solid ${theme.color.gray[4]}`}
            paddingY="11px"
          >
            <Box width={144}>
              <StretchedTertiaryButton
                comlinkPushParams={{
                  action: 'click_unselect_modal_cancel',
                }}
                onClick={unselectModal.close}
              >
                キャンセル
              </StretchedTertiaryButton>
            </Box>
            <Box width={144} ml="8px">
              <StretchedDangerButton
                comlinkPushParams={{
                  action: 'click_unselect_modal_unselect',
                }}
                onClick={onUnselectButtonClick}
              >
                動画を削除
              </StretchedDangerButton>
            </Box>
          </Box>
        </ModalWithMargin>
        {finishAnswerOnNextModal.active && (
          <FixAnswerModal
            active
            direction="next"
            onClose={finishAnswerOnNextModal.close}
            onFinishAnswer={onFinishAnswerToNext}
          />
        )}
        {finishAnswerOnBackModal.active && (
          <FixAnswerModal
            active
            direction="back"
            onClose={finishAnswerOnBackModal.close}
            onFinishAnswer={onFinishAnswerToBack}
          />
        )}
      </Form.Wrapper>
      {isRecordingVideo && (
        <Overlay>
          <VideoRecording
            question={{
              index: props.questionIndex,
              content: props.question.content,
              note: props.question.note,
            }}
            onRecordingComplete={onRecordingComplete}
            onClose={closeRecordingVideo}
            limitation={
              props.question.hasAnswerLimitation
                ? {
                    ...props.question,
                    ...props.limitationCtx.state.limitation,
                    enableAnswer: props.limitationCtx.enableAnswer,
                  }
                : undefined
            }
            onStartLimitationRecording={
              props.limitationCtx.onStartLimitationRecording
            }
            hasAnswerLimitation={props.question.hasAnswerLimitation}
          />
        </Overlay>
      )}
    </>
  )
}

export const canGoNext = (
  isUploading: boolean,
  required: boolean,
  enableAnswer: boolean,
  errorBoxMessage: string,
  answerKey: string
): boolean => {
  if (isUploading) return false
  if (!required) return true
  if (!enableAnswer) return true // NOTE:必須設問で制限残なしの場合は未回答でも次に進む
  if (errorBoxMessage !== '') return false
  if (answerKey === '') return false
  return true
}

const ErrorBoxTxt = styled(Txt).attrs({
  size: 'm',
  color: theme.color.red[2],
})``

const LimitedTxt = styled(Txt).attrs({
  size: 'm',
  color: theme.color.navy[1],
})``

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: 22px;
  height: 22px;
  color: ${theme.color.green[4]};
`

const FixedWidthBox = styled(Box)`
  width: ${(props) => props.theme.contentWidth};
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #000;
`

const IconButton = styled.button.attrs({ type: 'button' })`
  ${buttonResetStyle}
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.navy[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 1px 0 0;
`

const Bold = styled.span`
  font-weight: bold;
`

const ModalWithMargin = styled(Modal)`
  margin: 20px;
`

const RetakeIcon = styled(Icon).attrs({ name: 'movie' })`
  margin-right: 10px;
`
