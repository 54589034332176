import { comlinkPush } from '@blue-agency/im-shared-front'
import { MyHuttBffServicePromiseClient } from '@blue-agency/proton/my_hutt_bff'
import { callWithRetry } from './callWithRetry'
import { logRpcErr } from './logRpcErr'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')

const myHuttClient = new MyHuttBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([myHuttClient])
}

export function callMyHuttBffService<
  T extends keyof MyHuttBffServicePromiseClient
>(
  rpcName: T,
  req: Parameters<MyHuttBffServicePromiseClient[T]>
): ReturnType<MyHuttBffServicePromiseClient[T]> {
  const metadata: Record<string, string> = {
    rpc: rpcName,
  }

  comlinkPush({
    action: 'call_rpc',
    metadata,
  })

  // @ts-expect-error
  return callWithRetry(() =>
    // @ts-expect-error
    myHuttClient[rpcName].apply(myHuttClient, req).catch((e) => {
      logRpcErr(rpcName, e)
      throw e
    })
  )
}
