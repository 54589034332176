import React, { useCallback, useEffect, useRef, useState } from 'react'
import { theme, useResponsive } from '@blue-agency/rogue'
import { PrimaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { Limitation } from '@/components/Limitation'
import { Spacer } from '@/components/Spacer'
import {
  StretchedSecondaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { QuestionBadge } from '../components/QuestionBadge'
import {
  QuestionTutorial,
  LimitationTutorial,
  StartRecordingTutorial,
  TutorialOverlay,
} from './Tutorials'

type TutorialQuestionStep = 'question' | 'limitation' | 'ready'
type Props = {
  onNext: () => void
  hasLimitation: boolean
}
export const TestQuestionTutorial: React.VFC<Props> = (props) => {
  const [tutorialQuestionStep, setTutorialQuestionStep] =
    useState<TutorialQuestionStep>('question')
  const toNextStep = useCallback(() => {
    setTutorialQuestionStep((prev) => {
      switch (prev) {
        case 'question':
          return props.hasLimitation ? 'limitation' : 'ready'
        case 'limitation':
          return 'ready'
        default:
          return prev
      }
    })
  }, [props.hasLimitation])
  const { responsive } = useResponsive()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(document.body.scrollHeight)
  const [startY, setStartY] = useState(0)
  const [diffY, setDiffY] = useState(0)
  const [isFixed, setIsFixed] = useState(false)

  const scrollHeight = useCallback(
    (e: TouchEvent) => {
      if (e.type === 'touchstart') {
        setStartY(e.touches[0].pageY)
      } else if (e.type === 'touchmove') {
        setDiffY(e.touches[0].pageY - startY)
      }
    },
    [startY]
  )

  useEffect(() => {
    setIsFixed(diffY < 0)
    if (diffY < 0) {
      setHeight(window.innerHeight - diffY)
    }
  }, [diffY])

  useEffect(() => {
    const ref = wrapperRef.current
    if (!ref) return

    ref.addEventListener('touchstart', scrollHeight)
    ref.addEventListener('touchmove', scrollHeight)
    return () => {
      ref.removeEventListener('touchstart', scrollHeight)
      ref.removeEventListener('touchmove', scrollHeight)
    }
  }, [scrollHeight])

  return (
    <>
      <TutorialOverlay ref={wrapperRef} scrollHeight={height} fixed={isFixed} />
      <Form.Wrapper>
        <Form.Body>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="start"
            >
              <Box display="flex" alignItems="center">
                <QuestionBadge>問 0 / 0</QuestionBadge>
              </Box>
              {props.hasLimitation && (
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  paddingRight="8px"
                >
                  <SampleLabel
                    tutorialStepLimitation={
                      tutorialQuestionStep === 'limitation'
                    }
                  >
                    サンプル
                  </SampleLabel>
                  <LimitWrapper
                    tutorialStepLimitation={
                      tutorialQuestionStep === 'limitation'
                    }
                  >
                    <Limitation
                      limitation={{
                        maxDurationSeconds: 600,
                        remainsDurationSeconds: 600,
                        maxRecCount: 3,
                        remainsRecCount: 3,
                      }}
                    />
                  </LimitWrapper>
                </Box>
              )}
              {tutorialQuestionStep === 'limitation' && (
                <LimitationTutorial toNextStep={toNextStep} />
              )}
            </Box>
            <Spacer mt="6px" />
            <MessageBox
              tutorialStepQuestion={tutorialQuestionStep === 'question'}
            >
              <ResponsiveTxt bold>
                サンプル設問：10秒を目安に自由に話していただき、撮影動画を確認
                {responsive.pc && <br />}してください。
                <br /> お試し撮影では、何回でもお試しいただけます。
                <br /> ※お試し撮影の内容は、応募先企業に送信されません。
              </ResponsiveTxt>
            </MessageBox>
            {tutorialQuestionStep === 'question' && (
              <QuestionTutorial toNextStep={toNextStep} />
            )}
          </Box>
          <Spacer mb="20px" />
          {tutorialQuestionStep === 'ready' && (
            <StartRecordingTutorial
              toNextStep={props.onNext}
              limitation={props.hasLimitation}
            />
          )}
          <Box display="flex" justifyContent="center">
            <StartButton
              comlinkPushParams={{
                action: 'click_tutorial_start_recording',
              }}
              tutorialStepReady={tutorialQuestionStep === 'ready'}
            >
              撮影を開始
            </StartButton>
          </Box>
        </Form.Body>
        <Form.Bottom>
          <BottomButtons>
            <StretchedTertiaryButton
              comlinkPushParams={{
                action:
                  'click_back_on_rec_interview_video_question_tutorial_page',
              }}
            >
              前に戻る
            </StretchedTertiaryButton>
            <StretchedSecondaryButton
              comlinkPushParams={{
                action:
                  'click_next_on_rec_interview_video_question_tutorial_page',
              }}
            >
              次に進む
            </StretchedSecondaryButton>
          </BottomButtons>
        </Form.Bottom>
      </Form.Wrapper>
    </>
  )
}

const ResponsiveTxt = styled(BreakWordTxt)`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const LimitWrapper = styled.div<{ tutorialStepLimitation: boolean }>`
  ${(props) =>
    props.tutorialStepLimitation &&
    css`
      z-index: 2;
    `}
`

const MessageBox = styled(Box)<{ tutorialStepQuestion: boolean }>`
  position: relative;
  padding: 16px;
  ${(props) =>
    props.tutorialStepQuestion
      ? css`
          background-color: ${theme.color.white[1]};
          z-index: 2;
          border: dashed 3px;
        `
      : css`
          background-color: ${theme.color.gray[5]};
        `}
`

const StartButton = styled(PrimaryButton).attrs({
  widthSize: 'L1',
  heightSize: spButtonHeightSize,
})<{ tutorialStepReady: boolean }>`
  pointer-events: none;
  ${(props) =>
    props.tutorialStepReady &&
    css`
      z-index: 1;
    `}
`

const SampleLabel = styled.span<{ tutorialStepLimitation: boolean }>`
  position: absolute;
  width: 58px;
  border-radius: 12px;
  margin-right: 11px;
  padding: 3px;
  text-align: center;
  font-size: ${theme.fontSize.s};
  font-weight: bold;
  background: rgba(0, 0, 0, 0.44);
  color: ${theme.color.white[1]};
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  ${(props) =>
    props.tutorialStepLimitation &&
    css`
      z-index: 3;
    `}
`
