import React from 'react'
import { NewTabLink, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { StretchedSecondaryButton } from '@/components/StretchedButton'
import { VideoPlayer } from '@/components/VideoPlayer'
import { Message } from '@/pages/SelectionRecInterviewPage/components/Message'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import { WithDispatch } from '@/pages/SelectionRecInterviewPage/types'
import { EXTERNAL_URLS } from '@/services/urlService'

type Props = WithDispatch<{
  organization: NonNullable<State['organization']>
  deadline: NonNullable<State['deadline']>
  selectionStepName: string
  introMessage: State['introMessage']
  introVideoPresignedUrl: State['introVideoPresignedUrl']
}>

export const Home: React.VFC<Props> = (props) => {
  const messageTitle = `${props.organization.name}からのメッセージ`
  return (
    <Form.Wrapper bottomHeight={150}>
      <Form.Body>
        <Box
          display="flex"
          flexDirection="column"
          alignSelf="center"
          alignItems="center"
        >
          <Txt size="l">{props.organization.name}</Txt>
          <Txt size="xl">{props.selectionStepName}</Txt>
        </Box>
        {(props.introVideoPresignedUrl || props.introMessage) && (
          <Box mt="30px">
            <BreakWordTxt size="l" bold>
              {messageTitle}
            </BreakWordTxt>
          </Box>
        )}
        {props.introVideoPresignedUrl && (
          <Box mt="12px" display="flex" justifyContent="center">
            <VideoPlayer
              src={props.introVideoPresignedUrl}
              // 16:9
              maxHeight={278}
              maxWidth={494}
            />
          </Box>
        )}
        {props.introMessage && (
          <Box mt="12px">
            <BreakWordTxt>{props.introMessage}</BreakWordTxt>
          </Box>
        )}
        {/* NOTE: 提出期限の表示ON/OFF機能実装まで一旦消しておく https://blue-agency.atlassian.net/browse/PIM-2912 */}
        {/* <Box mt="20px" p="16px" bg={theme.color.gray[5]}>
          <Box display="flex">
            <CalendarIcon />
            <Txt color={theme.color.gray[1]}>提出期限：</Txt>
            <Txt bold>{formatTime(props.deadline)}</Txt>
          </Box>
        </Box> */}
        <Box mt="20px">
          <Message
            title="インタビューメーカーについて"
            content="「インタビューメーカー」は、株式会社スタジアムが提供する録画面接サービスです。ご利用にあたっては、インタビューメーカーの利用規約及びプライバシーポリシーへの同意が必要です。"
          />
        </Box>
      </Form.Body>
      <Form.Bottom>
        <Box mt="8px">
          <LinkTxt>
            インタビューメーカーの
            <br />
            <NewTabLink
              href={EXTERNAL_URLS.termsAndPP}
              action="open_terms_and_pp"
            >
              利用規約及びプライバシーポリシー
            </NewTabLink>
            に
          </LinkTxt>
        </Box>
        <BottomButtons>
          <StretchedSecondaryButton
            comlinkPushParams={{
              action: 'click_next_on_rec_interview_home_page',
            }}
            onClick={() => props.dispatch(['GO_NEXT'])}
          >
            同意して次に進む
          </StretchedSecondaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

const LinkTxt = styled(Txt).attrs({ size: 'm' })`
  text-align: center;
`
