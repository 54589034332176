import { useCallback } from 'react'
import { GetRecInterviewPreviewVideoURLRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

type Params = {
  recInterviewGuid: string
  questionGuid: string
  key: string
}

export function useRequestGetRecInterviewPreviewVideoURL() {
  const requestGetRecInterviewPreviewVideoURL = useCallback(
    (params: Params) => {
      const req = new GetRecInterviewPreviewVideoURLRequest()
      req.setRecInterviewGuid(params.recInterviewGuid)
      req.setRecInterviewQuestionGuid(params.questionGuid)
      req.setKey(params.key)

      return callMyHuttBffService('getRecInterviewPreviewVideoURL', [req])
    },
    []
  )

  return { requestGetRecInterviewPreviewVideoURL }
}
