import { FixedBottomFormPage as F } from '@blue-agency/im-shared-front'
import styled from 'styled-components'

const Wrapper = styled(F.Wrapper)`
  width: 100vw;
`

const Body = styled(F.Body)<{ marginTop?: number }>`
  margin-top: ${(props) => props.marginTop ?? 20}px;
  width: ${(props) => props.theme.contentWidth};
`

export const FixedBottomForm = {
  Wrapper,
  Body,
  Bottom: F.Bottom,
}
