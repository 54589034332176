import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * レンダリング時に画面上部までスクロール位置を戻すコンポーネント
 * @see https://v5.reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
