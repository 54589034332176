import { Icon, theme, Txt } from '@blue-agency/rogue'
import { Anchorme } from 'react-anchorme'
import { isIOS } from 'react-device-detect'
import { generatePath, Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  useGetVideoSeminarTicketFromQueryParam,
  VideoSeminarContent,
} from '@/services/videoSeminarService'
import { HlsVideoPlayer } from './HlsVideoPlayer'
import { VideoPlayerForIOS } from './VideoPlayerForIOS'

type Props = {
  videoSeminarGuid: string
  videoSeminarTicketGuid: string
  content: VideoSeminarContent
}
export const MainContent: React.VFC<Props> = (props) => {
  const { videoSeminarTicketSearchStr } =
    useGetVideoSeminarTicketFromQueryParam()

  return (
    <div>
      <Header>
        <Back
          to={{
            pathname: generatePath(INTERNAL_PATHS.videoSeminar.index, {
              videoSeminarGuid: props.videoSeminarGuid,
            }),
            search: videoSeminarTicketSearchStr,
          }}
        >
          <BackIcon />
          <Txt>一覧へ戻る</Txt>
        </Back>
      </Header>
      <Main>
        {isIOS ? (
          <VideoPlayerForIOS
            src={props.content.movieUrl}
            thumbnailUrl={props.content.thumbnailUrl}
            videoSeminarTicketGuid={props.videoSeminarTicketGuid}
            videoSeminarContentGuid={props.content.guid}
          />
        ) : (
          <VideoPlayer
            src={props.content.movieUrl}
            videoSeminarTicketGuid={props.videoSeminarTicketGuid}
            videoSeminarContentGuid={props.content.guid}
            thumbnailUrl={props.content.thumbnailUrl}
            key={props.content.guid}
          />
        )}
        <Title>{props.content.title}</Title>
        <Description>
          <Anchorme target="_blank" rel="noreferrer noopener">
            {props.content.description}
          </Anchorme>
        </Description>
      </Main>
    </div>
  )
}

const Header = styled.header`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px;
`

const Back = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 4px;
  text-decoration: none;
`

const BackIcon = styled(Icon).attrs({
  name: 'arrow',
  height: '24px',
})`
  color: ${theme.color.navy[1]};
  margin-right: 4px;
`

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          max-width: 640px;
        `
      : css`
          max-width: 336px;
        `}
  margin: 0 auto;
`

const VideoPlayer = styled(HlsVideoPlayer)`
  width: 100%;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          height: 345px;
          max-height: 345px;
        `
      : css`
          height: 188px;
          max-height: 188px;
        `}
`

const Title = styled(Txt).attrs({ size: 'l', bold: true })`
  margin-top: 12px;
`

const Description = styled(Txt)`
  margin: 12px 0;
`
