import React, { Suspense, useEffect, useMemo } from 'react'
import {
  CommonErrorToastContainer,
  CustomGrpcError,
  GeneralErrorPage,
  injectAlertBox,
  usePageLayoutComponent,
} from '@blue-agency/im-shared-front'
import '@blue-agency/im-shared-front/dist/assets/PageLayout.css'
import { ToastContainer, useResponsive } from '@blue-agency/rogue'
import '@blue-agency/rogue/dist/assets/style.css'
import * as Sentry from '@sentry/react'
import assert from 'assert'
import { createBrowserHistory } from 'history'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { SelectionRecInterviewPage } from '@/pages/SelectionRecInterviewPage'
import { INTERNAL_PATHS } from '@/services/urlService'
import { Loading } from './components/Loading'
import { NotFoundPage } from './components/NotFoundPage'
import { useTracking } from './hooks/useTracking'
import { initSentry } from './initSentry'
import { initializeImSharedFront } from './initializeImSharedFront'
import { VideoSeminarContentPage } from './pages/VideoSeminarContentPage'
import { VideoSeminarTopPage } from './pages/VideoSeminarTopPage'

const alertJsonUrl = process.env.REACT_APP_ALERT_JSON_URL
assert(alertJsonUrl, 'alertJsonUrl is required')

initializeImSharedFront()

const history = createBrowserHistory()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  initSentry()
}

const App: React.FC = () => {
  const { responsive } = useResponsive()
  useTracking()
  usePageLayoutComponent()

  useEffect(() => {
    injectAlertBox(alertJsonUrl)
  }, [])

  const theme = useMemo(() => {
    if (responsive.pc) {
      return { responsive, contentWidth: '548px' }
    }
    return { responsive, contentWidth: '328px' }
  }, [responsive])

  return (
    <Router history={history}>
      <Sentry.ErrorBoundary
        fallback={() => <GeneralErrorPage showDashboardLink={false} />}
      >
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loading />}>
            <MyErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <Switch>
                  <Route
                    exact
                    path={INTERNAL_PATHS.selectionRecInterview}
                    component={SelectionRecInterviewPage}
                  />
                  <Route
                    exact
                    path={INTERNAL_PATHS.videoSeminar.index}
                    component={VideoSeminarTopPage}
                  />
                  <Route
                    exact
                    path={INTERNAL_PATHS.videoSeminar.content}
                    component={VideoSeminarContentPage}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
                <ToastContainer />
                <CommonErrorToastContainer />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </MyErrorBoundary>
          </Suspense>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </Router>
  )
}

export default App

export const MyErrorBoundary: React.FC = ({ children }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
}

const Fallback: React.VFC<FallbackProps> = ({ error }) => {
  if (error instanceof CustomGrpcError) {
    if (error.isNotFound) {
      return <NotFoundPage />
    }
  }

  Sentry.captureException(error)

  return <GeneralErrorPage showDashboardLink={false} />
}
