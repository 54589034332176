import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { StretchedSecondaryButton } from '@/components/StretchedButton'

type Props = {
  onNext: () => void
}

export const TestRecordingReady: React.VFC<Props> = (props) => {
  return (
    <Form.Wrapper>
      <Form.Body>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Txt size="xl">お試し撮影</Txt>
        </Box>
        <Box mt="32px">
          <Txt size="l" bold>
            お試し撮影をしてください
          </Txt>
        </Box>
        <MovieIcon />
        <Box mt="20px">
          <Txt>
            10秒を目安に自由に喋っていただき、撮影動画を確認してください。
            <br />
            お試し撮影では、何回でも撮影をお試しいただけます。
            <br />
            <Red>
              ※お試し撮影の内容は、応募先企業に送信されることはありません。
            </Red>
          </Txt>
        </Box>
        <Box mt="32px">
          <Txt size="l" bold>
            スマートフォンまたはiPadの場合
          </Txt>
        </Box>
        <SPIcon />
        <Box mt="10px">
          <Txt>
            撮影時の端末は「<Bold>横向き</Bold>」がおすすめです。
            <br />
            端末の自動回転設定をONにして「<Bold>横向き</Bold>」にしましょう。
          </Txt>
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          <StretchedSecondaryButton
            comlinkPushParams={{
              action: 'click_next_on_test_recording_ready_page',
            }}
            onClick={props.onNext}
          >
            お試し撮影へ進む
          </StretchedSecondaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

const MovieIcon = styled(Icon).attrs({
  name: 'movie',
  width: '67px',
  height: '45px',
})`
  margin: 20px auto 0;
`

const SPIcon = styled(Icon).attrs({
  name: 'recommend-landscape',
  width: '123px',
  height: '80px',
})`
  margin: 10px auto 0;
`

const Red = styled.span`
  color: ${theme.color.red[2]};
`

const Bold = styled.span`
  font-weight: bold;
`
