import { useCallback, useState } from 'react'
import { useRequestSendVideoSeminarLog } from '@/services/bffService'
import { LOCAL_STORAGE_KEY } from '@/services/storageService'
import { useGetVideoSeminarTicket } from '@/services/videoSeminarService'

const localStorageTrueValue = 'true'

export const useAgreement = (videoSeminarGuid: string) => {
  const agreementStorageKey = buildStorageKey(videoSeminarGuid)
  const { videoSeminarTicketGuid } = useGetVideoSeminarTicket(videoSeminarGuid)
  const { requestSendVideoSeminarLog } = useRequestSendVideoSeminarLog()

  const [isAgreed, setIsAgreed] = useState(
    localStorage.getItem(agreementStorageKey) === localStorageTrueValue
  )

  const agree = useCallback(() => {
    if (videoSeminarTicketGuid === undefined)
      throw new Error('videoSeminarTicketGuid not found')
    requestSendVideoSeminarLog({
      videoSeminarTicketGuid,
      metadata: {
        type: 'participate',
      },
    })
    setIsAgreed(true)
    localStorage.setItem(agreementStorageKey, localStorageTrueValue)
  }, [agreementStorageKey, videoSeminarTicketGuid, requestSendVideoSeminarLog])

  return {
    isAgreed,
    agree,
  }
}

const buildStorageKey = (videoSeminarGuid: string) => {
  return LOCAL_STORAGE_KEY.agreementToVideoSeminarTermsAndPP.replace(
    ':videoSeminarGuid',
    videoSeminarGuid
  )
}
