import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { Box } from '@/components/Box'

type Props = {
  children: React.ReactNode
}

export const QuestionBadge: React.VFC<Props> = ({ children }) => {
  return (
    <Box
      borderRadius="4px"
      py="3px"
      px="6px"
      bg={theme.color.navy[1]}
      display="inline-block"
    >
      <Txt color={theme.color.white[1]}>{children}</Txt>
    </Box>
  )
}
