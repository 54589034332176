import React from 'react'
import { useQuery } from 'react-query'
import { useMount } from 'react-use'
import { getSelectionRecInterview } from '@/pages/SelectionRecInterviewPage/getSelectionRecInterview'
import { Action } from '@/pages/SelectionRecInterviewPage/reducer'
import { Guid } from '@/pages/SelectionRecInterviewPage/types'
import { QUERY_KEY } from '@/services/queryKeyService'
import { LOCAL_STORAGE_KEY } from '@/services/storageService'
import { getStoredAnswers } from './storeAnswer'

export function useRecInterviewInitialLoad(
  selectionStepGuid: Guid,
  dispatch: React.Dispatch<Action>
) {
  const recInterview = useQuery(
    [QUERY_KEY.getSelectionRecInterview, selectionStepGuid],
    () => getSelectionRecInterview({ selectionStepGuid }),
    {
      onSuccess: (data) => {
        dispatch(['SET_REC_INTERVIEW', data])
      },
      // 取得したsigned urlは60分有効なので、55分間毎にrefetchする
      refetchInterval: 1000 * 60 * 55,
    }
  )

  useMount(() => {
    restoreStepFromStorage(selectionStepGuid, dispatch)
    const answers = getStoredAnswers(selectionStepGuid)
    dispatch(['SET_STORED_ANSWERS', answers])
  })

  return recInterview
}

const restoreStepFromStorage = (
  selectionStepGuid: Guid,
  dispatch: React.Dispatch<Action>
) => {
  const key = LOCAL_STORAGE_KEY.recInterviewStep.replace(
    ':selectionStepGuid',
    selectionStepGuid
  )
  const item = localStorage.getItem(key)
  if (!item) {
    return
  }
  const step = JSON.parse(item)
  dispatch(['RESTORE_STEP', step])
}
