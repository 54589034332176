export const EXTERNAL_URLS = {
  termsAndPP: 'https://interview-maker.jp/terms/participant-terms/',
  recommendedEnvironment:
    'https://im-ac.zendesk.com/hc/ja/articles/4403618809113',
  help: {
    top: 'https://im-ac.zendesk.com/hc/ja',
    speedTest: 'https://im-ac.zendesk.com/hc/ja/articles/4403618809113',
    osBrowserTest: 'https://im-ac.zendesk.com/hc/ja/articles/4403618809113',
    recInterviewTips:
      'https://magazine.interview-maker.jp/2020/06/04/%e3%80%90%e5%b0%b1%e6%b4%bb%e3%80%91%e8%87%aa%e5%b7%b1pr%e5%8b%95%e7%94%bb%e3%81%ae%e6%b3%a8%e6%84%8f%e7%82%b9%e3%81%a8%e5%8d%b0%e8%b1%a1%e3%82%92%e8%89%af%e3%81%8f%e3%81%99%e3%82%8b%e3%83%9d%e3%82%a4/',
    audioTutorial: 'https://im-ac.zendesk.com/hc/ja/articles/4910325250969',
    answerLimitation: 'https://im-ac.zendesk.com/hc/ja/articles/5851303262489',
  },
}
