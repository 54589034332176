import { NewTabLink, theme, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Organization } from '@/services/videoSeminarService'
import { useLogoHeight } from './useLogoHeight'

type Props = {
  guid: string
  organization: Organization
  title: string
  agree: () => void
  isGuest: boolean
}
export const AgreementModal: React.VFC<Props> = (props) => {
  const { logoHeight } = useLogoHeight(props.organization.logoUrl)

  return (
    <Overlay>
      <Wrapper>
        <Content>
          {props.organization.logoUrl ? (
            <Logo
              src={props.organization.logoUrl}
              alt={props.organization.name}
              height={logoHeight}
            />
          ) : (
            <OrganizationName>{props.organization.name}</OrganizationName>
          )}
          <Title>{props.title}</Title>
          <Note>
            {props.isGuest && (
              <NoteTxt>※このサイトは他の人に共有しないでください。</NoteTxt>
            )}
            {!props.isGuest && (
              <>
                <NoteTxt>※あなた専用の説明会サイトです。</NoteTxt>
                <NoteTxt>他の人にこのページのURLを送信したり、 </NoteTxt>
                <NoteTxt>SNSで共有しないでください。</NoteTxt>
              </>
            )}
          </Note>
          <Separator />
          <AboutIm>インタビューメーカーについて</AboutIm>
          <AboutImDescription>
            「インタビューメーカー」は、株式会社スタジアムが提供するサービスです。
            ご利用にあたっては、インタビューメーカーの利用規約及びプライバシーポリシーへの同意が必要です。
          </AboutImDescription>
        </Content>
        <Separator />
        <TermsAndPPTxt>
          インタビューメーカーの
          <NewTabLink
            href={EXTERNAL_URLS.termsAndPP}
            action="open_terms_and_pp_on_video_seminar_initial_modal"
          >
            利用規約及びプライバシーポリシー
          </NewTabLink>
          に
        </TermsAndPPTxt>
        <AgreeButton
          onClick={props.agree}
          comlinkPushParams={{
            action: 'agree_to_terms_and_pp_on_video_seminar_initial_modal',
          }}
        >
          同意する
        </AgreeButton>
      </Wrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 16px;
  z-index: 1;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const Logo = styled.img<{ height: string }>`
  ${(props) =>
    css`
      height: ${props.height};
    `}
`

const OrganizationName = styled(Txt).attrs({ bold: true })``

const Title = styled(Txt).attrs({ size: 'l', bold: true })`
  margin-top: 8px;
`

const Note = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`

const NoteTxt = styled(Txt).attrs({ size: 's', color: theme.color.gray[1] })``

const Separator = styled(WeakColorSeparator)`
  margin-top: 8px;
`

const AboutIm = styled(Txt).attrs({ bold: true })`
  margin-top: 12px;
`

const AboutImDescription = styled(Txt)`
  margin-top: 8px;
`

const TermsAndPPTxt = styled(Txt).attrs({ textAlign: 'center' })`
  margin-top: 8px;
`

const AgreeButton = styled(SecondaryButton).attrs({
  widthSize: 'L3',
  heightSize: 'L',
})`
  margin: 8px 0 12px 0;
`
