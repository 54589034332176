import { useCallback, useRef, useState } from 'react'
import {
  Txt,
  Icon,
  LineClampedTxt,
  theme,
  ClickOutsideListener,
} from '@blue-agency/rogue'
import { Anchorme } from 'react-anchorme'
import { generatePath, Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { EXTERNAL_URLS, INTERNAL_PATHS } from '@/services/urlService'
import {
  useGetVideoSeminarTicketFromQueryParam,
  VideoSeminar,
} from '@/services/videoSeminarService'
import { useLogoHeight } from './useLogoHeight'

const menus: { label: string; href: string }[] = [
  {
    label: 'ヘルプ',
    href: EXTERNAL_URLS.help.top,
  },
  {
    label: '利用規約及び\nプライバシーポリシー',
    href: EXTERNAL_URLS.termsAndPP,
  },
]

type Props = PropsWithClassName<{
  videoSeminar: VideoSeminar
  isGuest: boolean
}>
export const VideoSeminarTop: React.VFC<Props> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), [])
  const closeMenu = useCallback(() => setIsMenuOpen(false), [])

  const { videoSeminarTicketSearchStr } =
    useGetVideoSeminarTicketFromQueryParam()

  const { logoHeight } = useLogoHeight(props.videoSeminar.organization.logoUrl)
  return (
    <Wrapper className={props.className}>
      <Header>
        <Txt>{props.videoSeminar.organization.name}</Txt>
        <MenuWrapper onClick={toggleMenu} ref={menuRef}>
          <MenuIcon />
          {isMenuOpen && (
            <ClickOutsideListener targetRef={menuRef} onClick={closeMenu}>
              <Menu>
                {menus.map((menu) => (
                  <MenuItem
                    key={menu.label}
                    href={menu.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {menu.label}
                    <TargetBlankIcon name="target-blank" />
                  </MenuItem>
                ))}
              </Menu>
            </ClickOutsideListener>
          )}
        </MenuWrapper>
      </Header>
      <Main>
        {props.videoSeminar.organization.logoUrl ? (
          <Logo
            src={props.videoSeminar.organization.logoUrl}
            alt={props.videoSeminar.organization.name}
            height={logoHeight}
          />
        ) : (
          <Txt bold>{props.videoSeminar.organization.name}</Txt>
        )}
        <Title>{props.videoSeminar.title}</Title>
        <Note>
          {props.isGuest && (
            <NoteTxt>※このサイトは他の人に共有しないでください。</NoteTxt>
          )}
          {!props.isGuest && (
            <>
              <NoteTxt>※あなた専用の説明会サイトです。</NoteTxt>
              <NoteTxt>他の人にこのページのURLを送信したり、 </NoteTxt>
              <NoteTxt>SNSで共有しないでください。</NoteTxt>
            </>
          )}
        </Note>
        <Description>
          <Anchorme target="_blank" rel="noreferrer noopener">
            {props.videoSeminar.description}
          </Anchorme>
        </Description>
        <ContentsList>
          {props.videoSeminar.contents
            // sequence昇順
            .sort((a, b) => a.sequence - b.sequence)
            .map((c) => (
              <Content
                key={c.guid}
                to={{
                  pathname: generatePath(INTERNAL_PATHS.videoSeminar.content, {
                    videoSeminarGuid: props.videoSeminar.guid,
                    videoSeminarContentGuid: c.guid,
                  }),
                  search: videoSeminarTicketSearchStr,
                }}
                $hasThumbnail={c.thumbnailUrl !== undefined}
              >
                {c.thumbnailUrl !== undefined && (
                  <ThumbnailImg src={c.thumbnailUrl} />
                )}
                <ContentText>
                  <ContentTitle>{c.title}</ContentTitle>
                  <ContentDescription>{c.description}</ContentDescription>
                </ContentText>
              </Content>
            ))}
        </ContentsList>
      </Main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.gray[5]};
  overflow-y: auto;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-left: 12px;
  border-bottom: 1px solid ${theme.color.gray[3]};
  background-color: ${theme.color.white[1]};
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const MenuIcon = styled(Icon).attrs({ name: 'more-horizontal' })``

const Menu = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  background-color: ${theme.color.white[1]};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
`

const MenuItem = styled.a`
  text-decoration: none;
  font-size: ${theme.fontSize.s};
  color: ${theme.color.navy[1]};
  line-height: 1.3;
  padding: 16px 20px;
  border-top: 1px solid ${theme.color.gray[4]};
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`

const TargetBlankIcon = styled(Icon).attrs({ name: 'target-blank' })`
  margin: 0 3px 3px;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  flex: 1;
  padding: 12px;
  margin: 0 auto;
`

const Title = styled(Txt).attrs({ bold: true, size: 'l' })`
  margin-top: 8px;
`

const Note = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`

const NoteTxt = styled(Txt).attrs({ size: 's', color: theme.color.gray[1] })``

const Description = styled(Txt)`
  width: 100%;
  margin-top: 8px;
`

const ContentsList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
`

const Content = styled(Link)<{ $hasThumbnail: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${theme.color.white[1]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  cursor: pointer;
  ${(props) =>
    props.theme.responsive.spPortrait
      ? css`
          width: 100%;
          height: 288px;
        `
      : css`
          width: 300px;
          height: 270px;
        `}
  ${(props) =>
    !props.$hasThumbnail &&
    css`
      height: 100px;
    `}
`

const ThumbnailImg = styled.img`
  width: 100%;
  object-fit: contain;
  background-color: ${theme.color.darkGray[1]};
  ${(props) =>
    props.theme.responsive.spPortrait
      ? css`
          height: 188px;
        `
      : css`
          height: 170px;
        `}
`

const ContentText = styled.div`
  padding: 4px 12px;
  height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
`

const ContentTitle = styled(LineClampedTxt).attrs({ bold: true, line: 2 })`
  flex: 1;
`

const ContentDescription = styled(LineClampedTxt).attrs({
  line: 2,
  color: theme.color.gray[1],
})`
  flex: 1;
`

const Logo = styled.img<{ height: string }>`
  ${(props) =>
    css`
      height: ${props.height};
    `}
`
