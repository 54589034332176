import React from 'react'
import { Icon, RequireTxt, theme, Txt } from '@blue-agency/rogue'
import { useResponsive } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Box } from '@/components/Box'
import { BreakWordTxt } from '@/components/BreakWordTxt'
import { Limitation, LimitationExplanation } from '@/components/Limitation'
import { Spacer } from '@/components/Spacer'
import { VideoPlayer } from '@/components/VideoPlayer'
import {
  RecInterviewQuestion,
  VideoAnswerLimitationSetting,
  VideoAnswerLimitation,
} from '@/pages/SelectionRecInterviewPage/types'
import { QuestionBadge } from '../../components/QuestionBadge'

type Props = PropsWithClassName<{
  questionIndex: number
  questionCount: number
  question: RecInterviewQuestion
  enableAccordion?: boolean
  hideQuestion?: boolean
  limitation?: VideoAnswerLimitationSetting & VideoAnswerLimitation
}>
export const Question: React.VFC<Props> = (props) => {
  const [isOpened, setIsOpened] = React.useState(!props.enableAccordion)
  const toggleIsOpened = React.useCallback(() => {
    setIsOpened((prev) => !prev)
  }, [])

  const { responsive } = useResponsive()

  React.useEffect(() => {
    setIsOpened(!props.enableAccordion)
  }, [props.enableAccordion])

  return (
    <Box className={props.className}>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box display="flex" alignItems="center">
          <QuestionBadge>
            問 {props.questionIndex + 1} / {props.questionCount}
          </QuestionBadge>
          {props.question.required && (
            <Box ml="8px" mt="1px">
              <RequireTxt size="s">必須</RequireTxt>
            </Box>
          )}
          {!props.hideQuestion && props.enableAccordion && (
            <ToggleWrapper onClick={toggleIsOpened}>
              <Txt color={theme.color.blue[2]}>
                {isOpened ? '設問を非表示' : '設問を表示'}
              </Txt>
              <ArrowIcon isOpened={isOpened} />
            </ToggleWrapper>
          )}
        </Box>
        {!props.hideQuestion && props.limitation?.hasAnswerLimitation && (
          <Limitation limitation={props.limitation} />
        )}
      </Box>
      {!props.hideQuestion && isOpened && (
        <>
          <Spacer mt="6px" />
          <Box backgroundColor={theme.color.gray[5]} padding="16px">
            <ResponsiveTxt bold>{props.question.content}</ResponsiveTxt>
            {props.question.note && (
              <>
                <Spacer mt="8px" />
                <ResponsiveTxt>{props.question.note}</ResponsiveTxt>
              </>
            )}
            {props.question.videoPresignedUrl && (
              <Box mt="12px" display="flex" justifyContent="center">
                <VideoPlayer
                  src={props.question.videoPresignedUrl}
                  // 16:9
                  maxHeight={208}
                  maxWidth={370}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      {props.hideQuestion && props.limitation?.hasAnswerLimitation && (
        <>
          <LimitationExplanation limitation={props.limitation} />
          <BoxWrapper>
            <InformationBox>
              <ResponsiveTxt>
                「設問を表示」を押すと、設問が表示され{!responsive.pc && <br />}
                カウントが開始されます。
              </ResponsiveTxt>
            </InformationBox>
          </BoxWrapper>
        </>
      )}
    </Box>
  )
}

const ToggleWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${theme.color.blue[2]};
`

const ArrowIcon = styled(Icon).attrs({
  width: '16px',
  height: '8px',
  name: 'arrow-down',
})<{ isOpened?: boolean }>`
  margin-left: 4px;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const ResponsiveTxt = styled(BreakWordTxt)`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const InformationBox = styled.div`
  background-color: ${theme.color.gray[4]};
  padding: 16px;
  text-align: left;
  display: inline-block;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          width: 548px;
        `
      : css`
          width: 320px;
        `}
`

const BoxWrapper = styled.div`
  text-align: center;
`
