import { useCallback } from 'react'
import { GetRecInterviewQuestionEventSummaryRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type GetRecInterviewQuestionEventSummaryParams = {
  recInterviewGuid: string
  questionGuid: string
}

export function useRequestGetRecInterviewQuestionEventSummary() {
  const requestGetRecInterviewQuestionEventSummary = useCallback(
    (params: GetRecInterviewQuestionEventSummaryParams) => {
      const req = new GetRecInterviewQuestionEventSummaryRequest()
      req.setRecInterviewGuid(params.recInterviewGuid)
      req.setRecInterviewQuestionGuid(params.questionGuid)

      return callMyHuttBffService('getRecInterviewQuestionEventSummary', [req])
    },
    []
  )

  return { requestGetRecInterviewQuestionEventSummary }
}
