export type Status = 'loading' | 'countdown' | 'capturing' | 'captured'

export type State = {
  status: Status
  countdown: number
}

export type Action =
  | ['RESET_RECORDING']
  | ['COUNT_START_RECORDING']
  | ['REQUEST_START_RECORDING']
  | ['TICK_COUNTDOWN_TIMER']
  | ['FINISH_COUNTDOWN']
  | ['REQUEST_FINISH_RECORDING']

export const initialState: State = {
  status: 'loading',
  countdown: 10,
}

export const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'RESET_RECORDING':
      return {
        ...initialState,
      }
    case 'COUNT_START_RECORDING':
      return {
        ...state,
        status: 'loading',
      }
    case 'REQUEST_START_RECORDING':
      return {
        ...state,
        status: 'countdown',
      }
    case 'TICK_COUNTDOWN_TIMER':
      return {
        ...state,
        countdown: state.countdown - 1,
      }
    case 'FINISH_COUNTDOWN':
      return {
        ...state,
        status: 'capturing',
      }
    case 'REQUEST_FINISH_RECORDING':
      return {
        ...state,
        status: 'captured',
      }
  }
}
