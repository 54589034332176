import { useParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { Loading } from '@/components/Loading'
import {
  setVideoSeminarTicketToLocalStorage,
  useCreateVideoSeminarTicketForGuest,
  useGetVideoSeminarTicket,
} from '@/services/videoSeminarService'
import { VideoSeminarTopPageWithTicket } from './VideoSeminarTopPageWithTicket'

export const VideoSeminarTopPage: React.VFC = () => {
  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()
  const { videoSeminarTicketGuid } = useGetVideoSeminarTicket(videoSeminarGuid)

  return videoSeminarTicketGuid ? (
    <VideoSeminarTopPageWithTicket
      videoSeminarTicketGuid={videoSeminarTicketGuid}
    />
  ) : (
    <CreateVideoSeminarTicketForGuestPage videoSeminarGuid={videoSeminarGuid} />
  )
}

type Props = {
  videoSeminarGuid: string
}
const CreateVideoSeminarTicketForGuestPage: React.VFC<Props> = (props) => {
  const { mutateAsync } = useCreateVideoSeminarTicketForGuest(
    props.videoSeminarGuid
  )

  /**
   * チケットを発行した後localStorageに格納してリロードする
   * ※ ゲストの場合はクエリパラメータにはticketGuidを含めない
   */
  useAsync(async () => {
    const res = await mutateAsync()
    setVideoSeminarTicketToLocalStorage(
      props.videoSeminarGuid,
      res.getVideoSeminarTicketGuid()
    )
    window.location.reload()
  }, [mutateAsync, props])

  return <Loading />
}
