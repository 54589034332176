import React, { useCallback, useRef, useState } from 'react'
import {
  ClickOutsideListener,
  Icon,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'

export type Rate = number
export type PlaybackRateOption = {
  rate: Rate
  label: string
}
type Props = {
  rate: Rate
  options: PlaybackRateOption[]
  onChange: (rate: Rate) => void
}
export const PlaybackRate: React.VFC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOptions = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])
  const closeOptions = useCallback(() => {
    setIsOpen(false)
  }, [])
  const clickOutsideTargetRef = useRef(null)

  return (
    <ClickOutsideListener
      onClick={closeOptions}
      targetRef={clickOutsideTargetRef}
    >
      <Wrapper ref={clickOutsideTargetRef}>
        <Control onClick={toggleOptions}>{props.rate}x</Control>
        {isOpen && (
          <Options length={props.options.length}>
            <OptionsTitle>再生速度</OptionsTitle>
            {props.options.map((option) => (
              <React.Fragment key={option.rate}>
                <WeakColorSeparator />
                <Option
                  selectedRate={props.rate}
                  option={option}
                  onChange={props.onChange}
                  closeOptions={closeOptions}
                />
              </React.Fragment>
            ))}
          </Options>
        )}
      </Wrapper>
    </ClickOutsideListener>
  )
}

type OptionProps = {
  selectedRate: Rate
  option: PlaybackRateOption
  onChange: (rate: Rate) => void
  closeOptions: () => void
}
const Option: React.VFC<OptionProps> = (props) => {
  const isSelected = props.selectedRate === props.option.rate
  const onClick = useCallback(() => {
    props.onChange(props.option.rate)
    props.closeOptions()
  }, [props])

  return (
    <OptionWrapper onClick={onClick}>
      {isSelected && <CheckIcon />}
      <OptionTxt isSelected={isSelected}>{props.option.label}</OptionTxt>
    </OptionWrapper>
  )
}

const optionHeight = 40
const iconMarginLeft = 13.5
const rateTxtMarginLeft = 7.5

const Wrapper = styled.div`
  position: relative;
`

const Control = styled.button`
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  width: 44px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSize.m};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 2px;
  color: ${theme.color.white[1]};
  background-color: transparent;
`

const Options = styled.ul<{ length: number }>`
  position: absolute;
  top: 30px;
  right: 0;
  width: 150px;
  background-color: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0px 0px 2px ${theme.color.gray[1]};
`

const OptionsTitle = styled.div`
  height: ${optionHeight}px;
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.s};
  padding-left: 20px;
`

const OptionWrapper = styled.li`
  cursor: pointer;
  height: ${optionHeight}px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const CheckIcon = styled(Icon).attrs({ name: 'check', size: 's' })`
  margin-left: ${iconMarginLeft}px;
  color: ${theme.color.green[4]};
`

const OptionTxt = styled(Txt)<{ isSelected: boolean }>`
  margin-left: ${({ isSelected }) =>
    isSelected
      ? `${rateTxtMarginLeft}px`
      : `calc(${theme.iconSize.s} + ${iconMarginLeft + rateTxtMarginLeft}px)`};
`
