import { useEffect, useMemo, useState } from 'react'
import { generatePath, Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Loading } from '@/components/Loading'
import { ScrollToTop } from '@/components/ScrollToTop'
import { setCookie } from '@/services/cookieService'
import { INTERNAL_PATHS } from '@/services/urlService'
import {
  mapProtoToVideoSeminar,
  mapProtoToVideoSeminarContent,
  useGetVideoSeminar,
  useGetVideoSeminarContent,
  useGetVideoSeminarTicket,
} from '@/services/videoSeminarService'
import { MainContent } from './MainContent'
import { OtherContents } from './OtherContents'

export const VideoSeminarContentPage: React.VFC = () => {
  const { videoSeminarGuid, videoSeminarContentGuid } =
    useParams<{ videoSeminarGuid: string; videoSeminarContentGuid: string }>()

  const { videoSeminarTicketGuid } = useGetVideoSeminarTicket(videoSeminarGuid)

  if (!videoSeminarTicketGuid) {
    return (
      <Redirect
        to={generatePath(INTERNAL_PATHS.videoSeminar.index, {
          videoSeminarGuid,
        })}
      />
    )
  }

  return (
    <VideoSeminarContentPageWithTicket
      videoSeminarGuid={videoSeminarGuid}
      videoSeminarContentGuid={videoSeminarContentGuid}
      videoSeminarTicketGuid={videoSeminarTicketGuid}
    />
  )
}

type Props = {
  videoSeminarGuid: string
  videoSeminarContentGuid: string
  videoSeminarTicketGuid: string
}
const VideoSeminarContentPageWithTicket: React.VFC<Props> = ({
  videoSeminarGuid,
  videoSeminarContentGuid,
  videoSeminarTicketGuid,
}) => {
  const { data: seminarData } = useGetVideoSeminar({
    videoSeminarGuid,
    videoSeminarTicketGuid,
  })

  const { data: contentData, isLoading: contentLoading } =
    useGetVideoSeminarContent({
      videoSeminarContentGuid,
      videoSeminarTicketGuid,
    })

  const videoSeminar = useMemo(() => {
    if (seminarData === undefined) return undefined
    return mapProtoToVideoSeminar(seminarData, videoSeminarGuid)
  }, [seminarData, videoSeminarGuid])

  const content = useMemo(() => {
    if (contentData === undefined) return undefined
    return mapProtoToVideoSeminarContent(contentData)
  }, [contentData])

  const sequence = useMemo(() => {
    if (videoSeminar === undefined) return undefined
    return videoSeminar.contents.find((c) => c.guid === videoSeminarContentGuid)
      ?.sequence
  }, [videoSeminar, videoSeminarContentGuid])

  const prevContent = useMemo(() => {
    if (videoSeminar === undefined || sequence === undefined) return undefined
    return videoSeminar.contents.find((c) => c.sequence === sequence - 1)
  }, [videoSeminar, sequence])

  const nextContent = useMemo(() => {
    if (videoSeminar === undefined || sequence === undefined) return undefined
    return videoSeminar.contents.find((c) => c.sequence === sequence + 1)
  }, [videoSeminar, sequence])

  const [isCookieReady, setIsCookieReady] = useState(false)

  useEffect(() => {
    if (!content) return
    content.cookies.forEach((cookie) => {
      setCookie(cookie)
    })
    // Cookieが正しくセットされる前にm3u8を取得しようとすると404になり、動画が再生できない
    setIsCookieReady(true)
  }, [content, videoSeminar])

  if (contentLoading || !content || !isCookieReady) return <Loading />

  return (
    <Wrapper>
      <ScrollToTop />
      <MainContent
        videoSeminarGuid={videoSeminarGuid}
        videoSeminarTicketGuid={videoSeminarTicketGuid}
        content={content}
      />
      <OtherContents
        videoSeminarGuid={videoSeminarGuid}
        prevContent={prevContent}
        nextContent={nextContent}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
