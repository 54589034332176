import React, { forwardRef } from 'react'
import { Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { buttonResetStyle } from '@/styles'

type Props = {
  onFileInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
}

export const FileSelectButton = forwardRef<HTMLInputElement, Props>(
  (props, inputRef) => {
    return (
      <Button onClick={props.onClick}>
        <HiddenInput
          onFileInputChange={props.onFileInputChange}
          ref={inputRef}
        />
        <Text>端末内の動画を選択</Text>
      </Button>
    )
  }
)

const Button = styled.button.attrs({ type: 'button' })`
  ${buttonResetStyle};
`

const Text = styled(Txt).attrs({ size: 'm' })`
  text-decoration: underline;
`

type HiddenInputProps = {
  onFileInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const HiddenInput = forwardRef<HTMLInputElement, HiddenInputProps>(
  ({ onFileInputChange }, inputRef) => {
    return (
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="video/mp4,video/mov,video/quicktime"
        onChange={onFileInputChange}
      />
    )
  }
)
