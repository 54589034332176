import { useState, useEffect, useRef } from 'react'
import { isDesktop } from 'react-device-detect'

const showControlsDurationMs = 3000

export const useControlsVisibility = () => {
  const [isControlsVisible, setIsControlsVisible] = useState(true)
  const playerWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const p = playerWrapperRef.current
    if (!p) return

    let id: NodeJS.Timeout
    const showControls = () => {
      clearTimeout(id)
      setIsControlsVisible(true)
      id = setTimeout(() => {
        setIsControlsVisible(false)
      }, showControlsDurationMs)
    }
    const hideControls = () => {
      setIsControlsVisible(false)
    }

    if (isDesktop) {
      // PCでは再生プレイヤーへのホバー時にコントロールUIを表示、カーソルが外れた時に非表示にする
      p.addEventListener('mouseenter', showControls)
      p.addEventListener('mousemove', showControls)
      p.addEventListener('mouseleave', hideControls)
    } else {
      // SPでは再生プレイヤータップ時にコントロールUIを表示する
      // TODO: コントロールUI表示時に、コントロールUIのオーバーレイタップで非表示にできるようにする
      p.addEventListener('touchstart', showControls)
    }

    return () => {
      if (isDesktop) {
        p.removeEventListener('mouseenter', showControls)
        p.removeEventListener('mousemove', showControls)
        p.removeEventListener('mouseleave', hideControls)
      } else {
        p.removeEventListener('touchstart', showControls)
      }
    }
  }, [])

  return {
    playerWrapperRef,
    isControlsVisible,
  }
}
