import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  organizationName?: string
  backgroundColor?: string
}

export const PageLayout: React.FC<Props> = ({
  organizationName,
  backgroundColor,
  children,
}) => {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Page>
        <HeaderArea>
          <HeaderInner>
            <OrganizationName>{organizationName}</OrganizationName>
            <Logo />
          </HeaderInner>
        </HeaderArea>
        <Content>{children}</Content>
      </Page>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs((props: { backgroundColor: string }) => ({
  backgroundColor: props.backgroundColor || theme.color.white[1],
}))`
  /* src/im/assets/PageLayout.css で .root に display: flex を設定している */
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
`

const Page = styled.div``

const HeaderArea = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderInner = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 6px 0 12px;
  background-color: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
`

const Logo = styled(Icon).attrs({
  name: 'logo2',
  width: '50px',
  height: '45px',
})`
  color: ${theme.color.black[1]};
`

const OrganizationName = styled(Txt)`
  flex: 1;
  font-size: 14px;
`
