import { useMemo, useReducer, useEffect, useCallback } from 'react'
import { commonErrorToast } from '@blue-agency/im-shared-front'
import { captureException } from '@sentry/react'
import { add, differenceInSeconds } from 'date-fns'
import { useMutation, useQuery } from 'react-query'
import { assertIsDefined } from '@/assertions'
import {
  Guid,
  VideoAnswerLimitation,
  VideoAnswerLimitationSetting,
} from '@/pages/SelectionRecInterviewPage/types'
import { timestampToDate } from '@/services/bffService'
import {
  useRequestGetRecInterviewQuestionEventSummary,
  GetRecInterviewQuestionEventSummaryParams,
  useRequestStartRecInterviewAnswer,
  StartRecInterviewAnswerParams,
  useRequestFinishRecInterviewAnswer,
  FinishRecInterviewAnswerParams,
  useRequestStartRecInterviewVideoRecording,
  StartRecInterviewVideoRecordingParams,
} from '@/services/bffService'
import { QUERY_KEY } from '@/services/queryKeyService'

type Args = VideoAnswerLimitationSetting & {
  recInterviewGuid: Guid
  questionGuid: Guid
}

type CountdownMode =
  | 'ready'
  | 'timerRunning'
  | 'timerStopped'
  | 'answerFinished'

export type State = {
  mode: CountdownMode
  limitation: VideoAnswerLimitation
  expiresAt: Date
}

type Action =
  | [
      'START',
      {
        start: Date
        serverCurrentTime: Date
        maxDurationSeconds: number
        remainsRecCount: number
      }
    ] // 制限付き設問の回答開始
  | ['REC_VIDEO'] // 録画の開始
  | ['FINISH'] // 制限付き設問の回答終了
  | ['TICK_COUNT_DOWN_TIMER'] // 回答時間のtick down

const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'START':
      const current = new Date()
      const diff = differenceInSeconds(current, action[1].serverCurrentTime) // NOTE:クライアントとサーバーでの現在時刻の時差を埋めて計算
      const expiresAt = add(action[1].start, {
        seconds: action[1].maxDurationSeconds + diff,
      })
      return {
        ...state,
        expiresAt: expiresAt,
        limitation: {
          remainsDurationSeconds: getRemainsDurationSeconds(current, expiresAt),
          remainsRecCount: action[1].remainsRecCount,
        },
        mode: 'timerRunning',
      }
    case 'REC_VIDEO':
      return {
        ...state,
        limitation: {
          remainsDurationSeconds: state.limitation.remainsDurationSeconds,
          remainsRecCount: state.limitation.remainsRecCount - 1,
        },
      }
    case 'FINISH':
      return {
        ...state,
        mode: 'answerFinished',
      }
    case 'TICK_COUNT_DOWN_TIMER':
      if (state.limitation.remainsDurationSeconds === 0) {
        return {
          ...state,
          limitation: {
            // 自動開始を入れた関係で、0でもenableAnswerがtrueになってしまい撮影に進むボタンが活性になったままなので-1にする
            remainsDurationSeconds: -1,
            remainsRecCount: state.limitation.remainsRecCount,
          },
          mode: 'timerStopped',
        }
      }
      return {
        ...state,
        limitation: {
          remainsDurationSeconds: getRemainsDurationSeconds(
            new Date(),
            state.expiresAt
          ),
          remainsRecCount: state.limitation.remainsRecCount,
        },
      }
  }
}

export const useAnswerLimitation = (args: Args) => {
  const { data, isLoading } = useGetRecInterviewQuestionEventSummary({
    recInterviewGuid: args.recInterviewGuid,
    questionGuid: args.questionGuid,
  })
  const { mutateAsync: startRecInterviewAnswer } = useStartRecInterviewAnswer()
  const { mutateAsync: finishRecInterviewAnswer } =
    useFinishRecInterviewAnswer()
  const { mutateAsync: startRecInterviewRecording } =
    useStartRecInterviewVideoRecording()

  const initialState: State = {
    limitation: {
      remainsDurationSeconds: args.maxDurationSeconds,
      remainsRecCount: args.maxRecCount,
    },
    mode: 'ready',
    expiresAt: new Date(),
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!args.hasAnswerLimitation) return
    if (data === undefined) return

    const obj = data.toObject()
    if (obj.startedTime === undefined) {
      // initialStateでok
      return
    }

    if (obj.finishedTime !== undefined) {
      return dispatch(['FINISH'])
    }

    const start = timestampToDate(obj.startedTime)
    assertIsDefined(start)

    const serverCurrent = timestampToDate(obj.currentTime)
    assertIsDefined(serverCurrent)

    // NOTE:既に開始され終了されていない場合はステータスを更新
    dispatch([
      'START',
      {
        remainsRecCount: getRemainsRecCount(args.maxRecCount, obj.recCount),
        start: start,
        serverCurrentTime: serverCurrent,
        maxDurationSeconds: args.maxDurationSeconds,
      },
    ])
  }, [
    args.hasAnswerLimitation,
    args.maxDurationSeconds,
    args.maxRecCount,
    data,
  ])

  useEffect(() => {
    if (args.maxDurationSeconds === 0) return
    if (state.mode !== 'timerRunning') return

    const id = setInterval(() => {
      dispatch(['TICK_COUNT_DOWN_TIMER'])
    }, 500)

    return () => {
      clearInterval(id)
    }
  }, [args.maxDurationSeconds, state.mode])

  const hideQuestion = useMemo<boolean>(() => {
    if (!args.hasAnswerLimitation) {
      return false
    }
    // NOTE:制限付き設問の場合は開始のイベントが実行された場合のみ設問を表示する
    return state.mode === 'ready'
  }, [args.hasAnswerLimitation, state.mode])

  const isFixed = useMemo<boolean>(() => {
    if (!args.hasAnswerLimitation) {
      return false
    }

    return state.mode === 'answerFinished'
  }, [args.hasAnswerLimitation, state.mode])

  const isLimited = useMemo<boolean>(() => {
    if (!args.hasAnswerLimitation) {
      return false
    }

    if (isFixed) {
      return false
    }

    if (args.maxRecCount > 0 && state.limitation.remainsRecCount === 0) {
      return true
    }

    if (
      args.maxDurationSeconds > 0 &&
      state.limitation.remainsDurationSeconds < 0
    ) {
      return true
    }
    return false
  }, [
    args.hasAnswerLimitation,
    args.maxRecCount,
    args.maxDurationSeconds,
    isFixed,
    state.limitation.remainsRecCount,
    state.limitation.remainsDurationSeconds,
  ])

  const AutoStartTrigger = useMemo<boolean>(() => {
    if (isFixed || isLimited) return false
    return (
      args.maxDurationSeconds > 0 &&
      state.limitation.remainsDurationSeconds === 0
    )
  }, [
    args.maxDurationSeconds,
    state.limitation.remainsDurationSeconds,
    isLimited,
    isFixed,
  ])

  const enableAnswer = useMemo<boolean>(() => {
    if (!args.hasAnswerLimitation) {
      return true
    }

    return !isFixed && !isLimited
  }, [args.hasAnswerLimitation, isFixed, isLimited])

  const onStartAnswer = useCallback(async () => {
    const res = await startRecInterviewAnswer({
      recInterviewGuid: args.recInterviewGuid,
      questionGuid: args.questionGuid,
    }).catch((err) => {
      captureException(err)
      commonErrorToast()
      return
    })

    const start = timestampToDate(res?.getStartedTime()?.toObject())
    assertIsDefined(start)

    const serverCurrent = timestampToDate(res?.getCurrentTime()?.toObject())
    assertIsDefined(serverCurrent)

    dispatch([
      'START',
      {
        remainsRecCount: getRemainsRecCount(args.maxRecCount, 0),
        start: start,
        serverCurrentTime: serverCurrent,
        maxDurationSeconds: args.maxDurationSeconds,
      },
    ])
  }, [
    args.maxDurationSeconds,
    args.maxRecCount,
    args.questionGuid,
    args.recInterviewGuid,
    startRecInterviewAnswer,
  ])

  const onFinishAnswer = useCallback(async () => {
    await finishRecInterviewAnswer({
      recInterviewGuid: args.recInterviewGuid,
      questionGuid: args.questionGuid,
    }).catch((err) => {
      captureException(err)
      commonErrorToast()
      throw err
    })
    dispatch(['FINISH'])
  }, [args.questionGuid, args.recInterviewGuid, finishRecInterviewAnswer])

  const onStartLimitationRecording = useCallback(async () => {
    if (!enableAnswer) return
    await startRecInterviewRecording({
      recInterviewGuid: args.recInterviewGuid,
      questionGuid: args.questionGuid,
    }).catch((err) => {
      captureException(err)
      commonErrorToast()
      throw err
    })
    dispatch(['REC_VIDEO'])
  }, [
    args.questionGuid,
    args.recInterviewGuid,
    enableAnswer,
    startRecInterviewRecording,
  ])

  return {
    isLoading,
    state,
    hideQuestion,
    isFixed,
    isLimited,
    AutoStartTrigger,
    enableAnswer,
    onStartAnswer,
    onFinishAnswer,
    onStartLimitationRecording,
  }
}

function useGetRecInterviewQuestionEventSummary(
  args: GetRecInterviewQuestionEventSummaryParams
) {
  const { requestGetRecInterviewQuestionEventSummary } =
    useRequestGetRecInterviewQuestionEventSummary()

  return useQuery(
    [
      QUERY_KEY.getRecInterviewQuestionEventSummary,
      args.recInterviewGuid,
      args.questionGuid,
    ],
    () => requestGetRecInterviewQuestionEventSummary(args)
  )
}

function useStartRecInterviewAnswer() {
  const { requestStartRecInterviewAnswer } = useRequestStartRecInterviewAnswer()

  return useMutation((value: StartRecInterviewAnswerParams) =>
    requestStartRecInterviewAnswer(value)
  )
}

function useFinishRecInterviewAnswer() {
  const { requestFinishRecInterviewAnswer } =
    useRequestFinishRecInterviewAnswer()

  return useMutation((value: FinishRecInterviewAnswerParams) =>
    requestFinishRecInterviewAnswer(value)
  )
}

function useStartRecInterviewVideoRecording() {
  const { requestStartRecInterviewVideoRecording } =
    useRequestStartRecInterviewVideoRecording()

  return useMutation((value: StartRecInterviewVideoRecordingParams) =>
    requestStartRecInterviewVideoRecording(value)
  )
}

export function getRemainsRecCount(
  maxRecCount: number,
  currentRecCount: number
): number {
  const recCount = maxRecCount - currentRecCount
  return recCount > 0 ? recCount : 0
}

export function getRemainsDurationSeconds(now: Date, expiresAt: Date): number {
  return differenceInSeconds(expiresAt, now)
}
