import { useCallback } from 'react'
import { FinishRecInterviewAnswerRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type FinishRecInterviewAnswerParams = {
  recInterviewGuid: string
  questionGuid: string
}

export function useRequestFinishRecInterviewAnswer() {
  const requestFinishRecInterviewAnswer = useCallback(
    (params: FinishRecInterviewAnswerParams) => {
      const req = new FinishRecInterviewAnswerRequest()

      req.setRecInterviewGuid(params.recInterviewGuid)
      req.setRecInterviewQuestionGuid(params.questionGuid)

      return callMyHuttBffService('finishRecInterviewAnswer', [req])
    },
    []
  )

  return { requestFinishRecInterviewAnswer }
}
