import {
  GetVideoSeminarContentResponse,
  GetVideoSeminarResponse,
} from '@blue-agency/proton/web/v2/my_hutt_bff'
import { Cookie } from '../cookieService'

export type Organization = {
  name: string
  logoUrl?: string
}

export type VideoSeminar = {
  guid: string
  title: string
  description: string
  organization: Organization
  contents: VideoSeminarContentForList[]
  totalContentsLength: number
}

export type VideoSeminarContent = {
  guid: string
  title: string
  description: string
  movieUrl: string
  thumbnailUrl?: string
  cookies: Cookie[]
}

export type VideoSeminarContentForList = {
  guid: string
  title: string
  description: string
  thumbnailUrl?: string
  sequence: number
}

export const mapProtoToVideoSeminar = (
  proto: GetVideoSeminarResponse,
  videoSeminarGuid: string
): VideoSeminar => ({
  guid: videoSeminarGuid,
  title: proto.getTitle(),
  description: proto.getDescription(),
  organization: {
    name: proto.getOrganization()?.getName() ?? '',
    logoUrl: proto.getOrganization()?.getLogoUrl(),
  },
  contents: proto.getContentsList().map((c) => ({
    guid: c.getGuid(),
    title: c.getTitle(),
    description: c.getDescription(),
    thumbnailUrl: c.getThumbnailUrl() || undefined,
    sequence: c.getSequence(),
  })),
  totalContentsLength: proto.getTotalContentsLength(),
})

export const mapProtoToVideoSeminarContent = (
  proto: GetVideoSeminarContentResponse
): VideoSeminarContent => {
  return {
    guid: proto.getGuid(),
    title: proto.getTitle(),
    description: proto.getDescription(),
    movieUrl: proto.getMovieUrl(),
    thumbnailUrl: proto.getThumbnailUrl() || undefined,
    cookies: proto.getPresignedCookiesList().map((c) => ({
      key: c.getKey(),
      value: c.getValue(),
      domain: c.getDomain(),
    })),
  }
}
