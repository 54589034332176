import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { LOCAL_STORAGE_KEY } from '../storageService'

const videoSeminarTicketQueryParamName = 't'

export const getVideoSeminarTicketFromLocalStorage = (
  videoSeminarGuid: string
) => {
  const key = buildStorageKey(videoSeminarGuid)
  return localStorage.getItem(key)
}

export const setVideoSeminarTicketToLocalStorage = (
  videoSeminarGuid: string,
  videoSeminarTicketGuid: string
) => {
  const key = buildStorageKey(videoSeminarGuid)
  return localStorage.setItem(key, videoSeminarTicketGuid)
}

const buildStorageKey = (videoSeminarGuid: string) => {
  return LOCAL_STORAGE_KEY.videoSeminarTicket.replace(
    ':videoSeminarGuid',
    videoSeminarGuid
  )
}

const buildTicketSearchStr = (videoSeminarTicketGuid: string) =>
  `${videoSeminarTicketQueryParamName}=${videoSeminarTicketGuid}`

export const useGetVideoSeminarTicketFromQueryParam = () => {
  const location = useLocation()
  const videoSeminarGuidFromQueryParam = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return query.get(videoSeminarTicketQueryParamName) ?? undefined
  }, [location.search])

  const videoSeminarTicketSearchStr = useMemo(() => {
    if (!videoSeminarGuidFromQueryParam) return ''
    return buildTicketSearchStr(videoSeminarGuidFromQueryParam)
  }, [videoSeminarGuidFromQueryParam])

  return { videoSeminarGuidFromQueryParam, videoSeminarTicketSearchStr }
}

export const useGetVideoSeminarTicket = (videoSeminarGuid: string) => {
  const { videoSeminarGuidFromQueryParam } =
    useGetVideoSeminarTicketFromQueryParam()

  /**
   * videoSeminarTicketGuidの取得元の優先順位
   * 1. URLのクエリパラメータから取得
   * 2. localStorageから取得
   * 3. ゲスト用に新規発行
   */
  const videoSeminarTicketGuid = useMemo(() => {
    if (videoSeminarGuidFromQueryParam) return videoSeminarGuidFromQueryParam

    const guidFromStorage =
      getVideoSeminarTicketFromLocalStorage(videoSeminarGuid)
    if (guidFromStorage) return guidFromStorage

    return undefined
  }, [videoSeminarGuid, videoSeminarGuidFromQueryParam])

  return {
    videoSeminarTicketGuid,
  }
}
