import React from 'react'
import produce from 'immer'
import { getSelectionRecInterview, Response } from './getSelectionRecInterview'
import {
  RecInterviewAnswer,
  RecInterviewQuestion,
  Guid,
  TextAnswer,
  VideoAnswer,
} from './types'

type Step =
  | { name: 'Home' }
  | { name: 'EnvironmentCheck' }
  | { name: 'Question'; questionGuid: Guid }
  | { name: 'Confirm' }
  | { name: 'Outro' }

// ref: https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
export type State = {
  step: Step
  questions: {
    byId: Record<Guid, RecInterviewQuestion>
    allIds: Array<Guid>
  }
  answers: {
    byId: Record<Guid, RecInterviewAnswer>
  }
  allowRecordedVideo: boolean
} & Partial<Omit<Response, 'questions'>>

export type Action =
  | ['GO_BACK']
  | ['GO_NEXT']
  | ['EDIT_ANSWER', { questionGuid: Guid }] // 回答確認画面で回答編集ボタンを押して各設問に遷移する
  | ['SET_REC_INTERVIEW', Response] // 初回ページ読み込み時にGetSelectionRecInterviewを叩いてレスポンスを状態に反映する
  | [
      'SET_REC_INTERVIEW_STATUS',
      { status: Exclude<State['recInterviewStatus'], undefined> }
    ] // 録画面接のステータスのみの更新
  | ['SET_STORED_ANSWERS', { [questionGuid: string]: TextAnswer | VideoAnswer }] // 保存された入力内容を読み込む
  | ['SUBMIT_ANSWER', { questionGuid: Guid; answer: RecInterviewAnswer }] // 回答をstateに反映して次に進む
  | ['SET_ANSWER', { questionGuid: Guid; answer: RecInterviewAnswer }] // 回答をstateに反映して前に戻る
  | ['RESTORE_STEP', State['step']] // ローカルストレージからstepを復元する

export const initialState: State = {
  step: { name: 'Home' },
  questions: { byId: {}, allIds: [] },
  answers: { byId: {} },
  recInterviewStatus: undefined,
  allowRecordedVideo: true,
}

export const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'GO_BACK':
      return goBack(state)
    case 'GO_NEXT':
      return goNext(state)
    case 'EDIT_ANSWER':
      return jumpToQuestion(state, action[1].questionGuid)
    case 'SET_REC_INTERVIEW':
      return {
        ...state,
        ...action[1],
        questions: normalizeQuestions(action[1].questions),
      }
    case 'SET_REC_INTERVIEW_STATUS':
      return {
        ...state,
        recInterviewStatus: action[1].status,
      }
    case 'SET_STORED_ANSWERS':
      const nextState = produce(state, (draft) => {
        Object.entries(action[1]).forEach(([questionGuid, answer]) => {
          draft.answers.byId[questionGuid] = answer
        })
      })
      return nextState
    case 'SUBMIT_ANSWER':
      return goNext(setAnswer(state, action[1].questionGuid, action[1].answer))
    case 'SET_ANSWER':
      return goBack(setAnswer(state, action[1].questionGuid, action[1].answer))
    case 'RESTORE_STEP':
      if (isBeforeStart(action[1])) {
        return {
          ...state,
          step: { name: 'Home' },
        }
      }
      return {
        ...state,
        step: action[1],
      }
  }
}

const jumpToQuestion = (state: State, guid: Guid): State => {
  const question = state.questions.allIds.find((q) => q === guid)
  if (question === undefined) {
    throw new Error('Question not found')
  }
  return {
    ...state,
    step: { name: 'Question', questionGuid: question },
  }
}

const goNext = (state: State): State => {
  switch (state.step.name) {
    case 'Home':
      return {
        ...state,
        step: { name: 'EnvironmentCheck' },
      }
    case 'EnvironmentCheck':
      return {
        ...state,
        step: { name: 'Question', questionGuid: state.questions.allIds[0] },
      }
    case 'Confirm':
      return {
        ...state,
        step: { name: 'Outro' },
      }
    case 'Outro':
      return state
    case 'Question':
      const next = nextQuestion(state.step.questionGuid, state.questions.allIds)
      if (next === null) {
        return {
          ...state,
          step: { name: 'Confirm' },
        }
      }
      return {
        ...state,
        step: { name: 'Question', questionGuid: next },
      }
  }
}

const goBack = (state: State): State => {
  switch (state.step.name) {
    case 'Home':
    case 'Confirm':
    case 'Outro':
      return state
    case 'EnvironmentCheck':
      return {
        ...state,
        step: { name: 'Home' },
      }
    case 'Question':
      const prev = previousQuestion(
        state.step.questionGuid,
        state.questions.allIds
      )
      if (prev === null) {
        return state
      }
      return {
        ...state,
        step: { name: 'Question', questionGuid: prev },
      }
  }
}

const nextQuestion = (
  current: Guid,
  questions: State['questions']['allIds']
): Guid | null => {
  const currentIdx = questions.findIndex((q) => q === current)
  if (currentIdx === questions.length - 1) {
    return null
  }
  return questions[currentIdx + 1]
}

const previousQuestion = (
  current: Guid,
  questions: State['questions']['allIds']
): Guid | null => {
  const currentIdx = questions.findIndex((q) => q === current)
  if (currentIdx === 0) {
    return null
  }
  return questions[currentIdx - 1]
}

const normalizeQuestions = (
  questions: Response['questions']
): State['questions'] => {
  const byId = Object.fromEntries(questions.map((q) => [q.guid, q]))
  return {
    byId,
    allIds: questions.map((q) => q.guid),
  }
}

const setAnswer = (
  state: State,
  questionGuid: Guid,
  answer: RecInterviewAnswer
) => {
  const nextState = produce(state, (draft) => {
    draft.answers.byId[questionGuid] = answer
  })
  return nextState
}

const isBeforeStart = (step: State['step']) => {
  switch (step.name) {
    case 'Home':
    case 'EnvironmentCheck':
      return true
    default:
      return false
  }
}

export const validateRecInterviewStatus = async (
  selectionStepGuid: Guid,
  dispatch: React.Dispatch<Action>
) => {
  const res = await getSelectionRecInterview({ selectionStepGuid })
  dispatch(['SET_REC_INTERVIEW_STATUS', { status: res.recInterviewStatus }])
  return res.recInterviewStatus === 'NotSubmitted'
}

export const includeAnswerLimitedQuestion = (
  byId: Record<Guid, RecInterviewQuestion>
): boolean => {
  return Object.values(byId).some((q) => {
    return q.kind === 'Video' && q.hasAnswerLimitation
  })
}
