import { useCallback } from 'react'
import { StartRecInterviewVideoRecordingRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export type StartRecInterviewVideoRecordingParams = {
  recInterviewGuid: string
  questionGuid: string
}

export function useRequestStartRecInterviewVideoRecording() {
  const requestStartRecInterviewVideoRecording = useCallback(
    (params: StartRecInterviewVideoRecordingParams) => {
      const req = new StartRecInterviewVideoRecordingRequest()

      req.setRecInterviewGuid(params.recInterviewGuid)
      req.setRecInterviewQuestionGuid(params.questionGuid)

      return callMyHuttBffService('startRecInterviewVideoRecording', [req])
    },
    []
  )

  return { requestStartRecInterviewVideoRecording }
}
