import { useCallback } from 'react'
import { SendVideoSeminarLogRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')

const url = `${hostname}/proton.v2.my_hutt_bff.MyHuttBffService/SendVideoSeminarLog`

type LogMetadata =
  | {
      type: 'participate'
    }
  | {
      type: 'play'
      videoSeminarContentGuid: string
      playId: string
      playbackTimeSeconds: number
      isFirstPlay: boolean
    }
  | {
      type: 'pause'
      videoSeminarContentGuid: string
      playId: string
      playbackTimeSeconds: number
    }

export type RequestSendVideoSeminarLogParams = {
  videoSeminarTicketGuid: string
  metadata: LogMetadata
}
/**
 * ビデオセミナーの各種ログ送信
 * sendBeaconを利用しているため、リトライや返り値の取得はできない
 */
export function useRequestSendVideoSeminarLog() {
  const requestSendVideoSeminarLog = useCallback(
    (params: RequestSendVideoSeminarLogParams) => {
      const req = new SendVideoSeminarLogRequest()
      req.setVideoSeminarTicketGuid(params.videoSeminarTicketGuid)

      switch (params.metadata.type) {
        case 'participate': {
          const p = new SendVideoSeminarLogRequest.Participate()
          req.setParticipate(p)
          break
        }
        case 'play': {
          const p = new SendVideoSeminarLogRequest.Play()
          p.setVideoSeminarContentGuid(params.metadata.videoSeminarContentGuid)
          p.setPlayId(params.metadata.playId)
          p.setPlaybackTimeSeconds(
            Math.floor(params.metadata.playbackTimeSeconds)
          )
          p.setIsFirstPlay(params.metadata.isFirstPlay)
          req.setPlay(p)
          break
        }
        case 'pause': {
          const p = new SendVideoSeminarLogRequest.Pause()
          p.setVideoSeminarContentGuid(params.metadata.videoSeminarContentGuid)
          p.setPlayId(params.metadata.playId)
          p.setPlaybackTimeSeconds(
            Math.floor(params.metadata.playbackTimeSeconds)
          )
          req.setPause(p)
          break
        }
      }

      const blob = createRequestBlob(req)
      navigator.sendBeacon(url, blob)
    },
    []
  )

  return { requestSendVideoSeminarLog }
}

// 参考：https://zenn.dev/kiddikn/scraps/64d84f82427eb4#comment-e8d9f805cb7a1f
const createRequestBlob = (req: SendVideoSeminarLogRequest): Blob => {
  const bytes = req.serializeBinary()
  const frame = new ArrayBuffer(bytes.byteLength + 5)
  new DataView(frame, 1, 4).setUint32(0, bytes.length, false /* big endian */)
  new Uint8Array(frame, 5).set(bytes)
  const base64Req = Buffer.from(new Uint8Array(frame)).toString('base64')

  return new Blob([base64Req], {
    type: 'application/grpc-web-text',
  })
}
