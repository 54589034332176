import { GetSelectionRecInterviewRequest } from '@blue-agency/proton/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

type Params = {
  selectionStepGuid: string
}

export async function requestGetSelectionRecInterview({
  selectionStepGuid,
}: Params) {
  const req = new GetSelectionRecInterviewRequest()
  req.setSelectionStepGuid(selectionStepGuid)

  return await callMyHuttBffService('getSelectionRecInterview', [req])
}
