export const INTERNAL_PATHS = {
  selectionRecInterview: '/sri/:selectionStepGuid',
  videoSeminar: {
    /** ビデオセミナーTOPページ */
    index: '/video-seminars/:videoSeminarGuid', // クエリパラメータ：?t=<ticket GUID>
    /** 各コンテンツ詳細ページ */
    content:
      '/video-seminars/:videoSeminarGuid/contents/:videoSeminarContentGuid',
  },
}
