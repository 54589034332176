import React from 'react'
import { ErrorBox, Icon, NewTabLink, theme, Txt } from '@blue-agency/rogue'
import * as deviceDetect from 'react-device-detect'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { StretchedSecondaryButton } from '@/components/StretchedButton'
import { EXTERNAL_URLS } from '@/services/urlService'

type Props = {
  onNext: () => void
}

export const OSBrowserTest: React.VFC<Props> = (props) => {
  const d = deviceDetect
  const unsupportedMessage = getUnsupportedMessage(d)
  const deviceTxt = (() => {
    if (d.isTablet) return 'タブレット'
    if (d.isMobile) return 'スマートフォン'
    return 'PC'
  })()
  const osTxt = `${d.osName} ${d.osVersion}`
  const browserTxt = `${mapBrowserName(d.browserName)} ${d.browserVersion}`

  return (
    <Form.Wrapper>
      <Form.Body>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Txt size="xl">お試し撮影</Txt>
        </Box>
        <Box mt="32px" display="flex" justifyContent="space-between">
          <Txt size="l" bold>
            OS・ブラウザチェック
          </Txt>
        </Box>
        {unsupportedMessage ? (
          <Box mt="16px">
            <ErrorBox>{unsupportedMessage}</ErrorBox>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" mt="10px">
            <CheckIcon />
            <Box ml="15px">
              <Txt>
                ご利用いただけるOS・ブラウザです。
                <br />
                次に進んでください。
              </Txt>
            </Box>
          </Box>
        )}
        <Box px="4px">
          <Box mt="22px" display="flex">
            <FixedWidthTxt size="m" bold>
              デバイス
            </FixedWidthTxt>
            <Box ml="32px">{deviceTxt}</Box>
          </Box>
          <Box mt="22px" display="flex">
            <FixedWidthTxt size="m" bold>
              OS
            </FixedWidthTxt>
            <Box ml="32px">{osTxt}</Box>
          </Box>
          <Box mt="22px" display="flex">
            <FixedWidthTxt size="m" bold>
              ブラウザ
            </FixedWidthTxt>
            <Box ml="32px">{browserTxt}</Box>
          </Box>
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          {unsupportedMessage ? (
            <StyledNewTabLink
              href={EXTERNAL_URLS.help.osBrowserTest}
              action="open_os_browser_test_help"
              hideIcon
            >
              <StretchedSecondaryButton
                comlinkPushParams={{
                  action: 'click_environment_check_open_help_page',
                }}
              >
                ヘルプページへ
              </StretchedSecondaryButton>
            </StyledNewTabLink>
          ) : (
            <StretchedSecondaryButton
              comlinkPushParams={{
                action: 'click_next_on_os_browser_test_page',
              }}
              onClick={props.onNext}
            >
              次に進む
            </StretchedSecondaryButton>
          )}
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

const FixedWidthTxt = styled(Txt)`
  width: 100px;
`

const StyledNewTabLink = styled(NewTabLink)`
  width: 100%;
`

const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  width: 22px;
  height: 22px;
  color: ${theme.color.green[4]};
`

const getUnsupportedMessage = (d: typeof deviceDetect) => {
  const browserVersion = Number(d.browserVersion)
  const osVersion = Number(d.osVersion)

  // iOS15.7からUAが変更されており、UAParserを利用したチェックだと推奨環境からはじかれるので一時的に通るようにする
  // 参考 https://github.com/faisalman/ua-parser-js/pull/595/files
  const ua = d.getUA
  if (ua.match(/version\/15\.6,[\w]+ .*mobile\/\w+ (safari)/i)) {
    return ''
  }
  if (ua.match(/version\/15\.6,[\w]+ .*(mobile ?safari|safari)/i)) {
    return ''
  }

  if (d.isWindows) {
    if (d.isChrome && browserVersion < 71)
      return '最新のGoogle Chromeにアップデートしてください。'
    if (d.isFirefox && browserVersion < 78)
      return '最新のFirefoxにアップデートしてください。'
    if (d.isEdge && browserVersion < 79)
      return '最新のMicrosoft Edgeにアップデートしてください。'
    if (d.isIE) return 'お使いのブラウザはご利用いただけません。'
  }
  if (d.isMacOs) {
    if (d.isChrome && browserVersion < 71)
      return '最新のGoogle Chromeにアップデートしてください。'
    if (d.isFirefox && browserVersion < 78)
      return '最新のFirefoxにアップデートしてください。'
    if (d.isEdge && browserVersion < 79)
      return '最新のMicrosoft Edgeにアップデートしてください。'
  }
  // iPhone
  if (d.isIOS && d.isMobileOnly) {
    if (!d.isMobileSafari)
      return 'こちらのブラウザは利用できません。Safariからページを開きなおしてください。'
    if (osVersion < 14.3)
      return 'OSが最新版でないためご利用できません。OSをアップデートしてください。'
  }
  // iPad
  if (d.isIOS && d.isTablet) {
    if (!d.isSafari && !d.isMobileSafari)
      return 'こちらのブラウザは利用できません。Safariからページを開きなおしてください。'
    if (osVersion < 14.3)
      return 'OSが最新版でないためご利用できません。OSをアップデートしてください。'
  }
  if (d.isAndroid) {
    if (!d.isChrome)
      return 'こちらのブラウザは利用できません。Google Chromeをご利用ください。'
    if (browserVersion < 71)
      return '最新のGoogle Chromeにアップデートしてください。'
  }
  return ''
}

/**
 * ref: https://github.com/duskload/react-device-detect/blob/54ce6cfbed0479691c45aa0371e4afdfaaae74f8/src/constants/typesMaps.js
 * @param browserName react-device-detectのbrowserName
 * @returns im上で表示するbrowserName
 */
const mapBrowserName = (browserName: string): string => {
  if (browserName === 'Chrome') {
    return 'Google Chrome'
  }
  return browserName
}
