import React, { useCallback } from 'react'
import {
  theme,
  Txt,
  NewTabLink,
  WeakColorSeparator,
  Icon,
  useResponsive,
} from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { EXTERNAL_URLS } from '@/services/urlService'
import { Box } from '../Box'

type TutorialProps = {
  toNextStep: () => void
}

export const AudioTutorial: React.VFC<TutorialProps> = (props) => {
  const { responsive } = useResponsive()
  return (
    <Box
      position="absolute"
      width={responsive.pc ? '400px' : '360px'}
      maxWidth="calc(100vw - 30px)"
      top={responsive.pc ? '-250px' : '-220px'}
    >
      <TutorialBalloon arrowPosition="bottomLeft">
        <Box
          height={responsive.pc ? '160px' : '130px'}
          paddingTop="10px"
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="space-evenly"
        >
          {responsive.pc ? (
            <>
              <ResponsiveTxt>
                こちらのページに遷移してから
                <br />
                10秒のカウントダウン後に撮影が始まります。
                <br />
                声に反応してゲージが動いているか確認しましょう。
              </ResponsiveTxt>
              <Txt size="s">
                ※カメラ・マイクは音声 / 映像設定から変更できます
                <br />
                ※動作しない場合は
                <NewTabLink
                  href={EXTERNAL_URLS.help.audioTutorial}
                  action="open_audio_tutorial_help_page"
                >
                  こちら
                </NewTabLink>
              </Txt>
            </>
          ) : (
            <>
              <ResponsiveTxt>
                こちらのページに遷移して
                <br />
                10秒のカウントダウン後に撮影が始まります。
                <br />
                声に反応してゲージが動いているか確認しましょう。
              </ResponsiveTxt>
              <Txt size="s">
                ※動作しない場合は
                <NewTabLink
                  href={EXTERNAL_URLS.help.audioTutorial}
                  action="open_audio_tutorial_help_page"
                >
                  こちら
                </NewTabLink>
              </Txt>
            </>
          )}
        </Box>
        <WeakColorSeparator />
        <Box m="11px">
          <TertiaryButton
            onClick={props.toNextStep}
            comlinkPushParams={{ action: 'confirm_tutorial_mic' }}
            widthSize="L1"
            heightSize={spButtonHeightSize}
          >
            OK
          </TertiaryButton>
        </Box>
      </TutorialBalloon>
    </Box>
  )
}

export const QuestionTutorial: React.VFC<
  TutorialProps & { onStartCountdown: () => void }
> = (props) => {
  const onClick = useCallback(() => {
    props.toNextStep()
    props.onStartCountdown()
  }, [props])
  return (
    <QuestionTutorialWrapper>
      <TutorialBalloon arrowPosition="top">
        <Box m="12px">
          <ResponsiveTxt>
            撮影中も「設問内容」を見ることができます。
            <br />
            全て表示したい場合は
            <ArrowIcon />
            を押してください。
          </ResponsiveTxt>
        </Box>
        <WeakColorSeparator />
        <Box m="11px">
          <TertiaryButton
            onClick={onClick}
            comlinkPushParams={{
              action: 'confirm_tutorial_question',
            }}
            widthSize="L1"
            heightSize={spButtonHeightSize}
          >
            OK
          </TertiaryButton>
        </Box>
      </TutorialBalloon>
    </QuestionTutorialWrapper>
  )
}

const TutorialBalloon = styled.div<{
  arrowPosition: 'top' | 'bottomLeft' | 'bottomCenter' | 'topRight' | 'none'
}>`
  width: 100%;
  height: 100%;
  background: ${theme.color.white[1]};
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &&:before {
    content: '';
    position: absolute;
    left: ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottomCenter':
        case 'top':
          return '50%'
        case 'bottomLeft':
          return '18px'
        case 'topRight':
          return '90%'
      }
    }};
    margin-left: -6px;
    border: 6px solid transparent;
    ${({ arrowPosition }) => {
      switch (arrowPosition) {
        case 'bottomCenter':
        case 'bottomLeft':
          return css`
            top: 100%;
            border-top: 10px solid ${theme.color.white[1]};
          `
        case 'top':
        case 'topRight':
          return css`
            bottom: 100%;
            border-bottom: 10px solid ${theme.color.white[1]};
          `
      }
    }}
  }
`

const ArrowIcon = styled(Icon).attrs({
  width: '16px',
  height: '8px',
  name: 'arrow-down',
})`
  margin: 0 4px;
`

export const TutorialOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
`

const ResponsiveTxt = styled(Txt)`
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const QuestionTutorialWrapper = styled(Box)`
  position: absolute;
  max-width: calc(100vw - 22px);
  top: 65px;
  margin: 0 auto;
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        width: 360px;
        right: 40px;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        width: 330px;
        right: 8%;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        width: 330px;
        right: 8%;
      `
    }
  }}
`
