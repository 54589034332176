import { useCallback } from 'react'
import { alertToast } from '@blue-agency/rogue'
import assert from 'assert'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import { WithDispatch } from '@/pages/SelectionRecInterviewPage/types'
import { useRequestSubmitSelectionRecInterview } from '@/services/bffService'
import { clearStoredAnswers } from '../storeAnswer'

type Params = WithDispatch<{
  state: State
  selectionStepGuid: string
}>

export const useSubmitSelectionRecInterview = ({
  dispatch,
  state,
  selectionStepGuid,
}: Params) => {
  const { requestSubmitSelectionRecInterview } =
    useRequestSubmitSelectionRecInterview()
  const onSubmit = useCallback(async () => {
    try {
      assert(state.organization)
      await requestSubmitSelectionRecInterview({
        selectionStepGuid: selectionStepGuid,
        organizationGuid: state.organization.guid,
        answers: state.answers,
        questionIds: state.questions.allIds,
      })
    } catch (err) {
      alertToast('提出に失敗しました\nブラウザを再読込して、再度お試しください')
      throw err
    }
    clearStoredAnswers(selectionStepGuid)
    dispatch(['GO_NEXT'])
  }, [dispatch, selectionStepGuid, state, requestSubmitSelectionRecInterview])

  return { onSubmit }
}
