import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSpecificMediaDevice } from '@blue-agency/react-media-devices'
import {
  Dropdown,
  Icon,
  Modal,
  theme,
  Txt,
  useResponsive,
} from '@blue-agency/rogue'
import { TertiaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import assert from 'assert'
import { useAsync } from 'react-use'
import styled from 'styled-components'
import { useModal } from '@/hooks/useModal'
import { Box } from '../Box'
import { StretchedTertiaryButton } from '../StretchedButton'
import { AudioWave } from './AudioWave'
import { MediaErrorModal } from './MediaErrorModal'
import {
  VideoRecordingBase,
  VideoRecordingBaseProps,
} from './VideoRecordingBase'

export type Props = {
  onRecordingComplete: (blob: Blob) => void
  onClose: () => void
  showTutorial?: boolean
  question: {
    index: number
    content: string
    note?: string
  }
  isTest?: boolean
  limitation?: limitation
  hasAnswerLimitation: boolean
  onStartLimitationRecording: () => void
}

type limitation = {
  enableAnswer: boolean
  maxDurationSeconds: number
  remainsDurationSeconds: number
  maxRecCount: number
  remainsRecCount: number
}

export const VideoRecording: React.VFC<Props> = (props) => {
  const { responsive } = useResponsive()
  return responsive.pc ? (
    <VideoRecordingWithDeviceSetting {...props} />
  ) : (
    <VideoRecordingWithoutDeviceSetting {...props} />
  )
}

const VideoRecordingWithDeviceSetting: React.VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMediaUnavailable, setIsMediaUnavailable] = useState(false)

  const micCameraSettingModal = useModal()
  const mic = useSpecificMediaDevice('audioinput')

  const camera = useSpecificMediaDevice('videoinput')
  const cameraVideoRef = useCallback(
    async (el: HTMLVideoElement | null) => {
      if (el === null) return
      if (camera.stream === undefined) return
      el.srcObject = camera.stream
    },
    [camera.stream]
  )

  useEffect(() => {
    setIsMediaUnavailable(false)
    if (camera.error || mic.error) {
      setIsMediaUnavailable(true)
    }
  }, [camera, mic])

  const streamState = useAsync(async () => {
    assert(videoRef.current)
    if (
      camera.devicesState === 'unavailable' ||
      mic.devicesState === 'unavailable'
    )
      return
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: camera.selectedDeviceId,
        aspectRatio: 4 / 3,
        /**
         * NOTE: 仮想カメラを使っていると、MediaRecorderで録画した映像が指定したaspectRatioに合わせて歪んでしまうことがある。
         * streamのresizeModeが'crop-and-scale'になっていると上記の現象が起きるため、resizeModeは'none'を指定しておく。
         */
        resizeMode: 'none',
        // NOTE: lib.dom.d.tsのMediaTrackConstraintSetにresizeModeが定義されていない
      } as MediaTrackConstraintSet,
      audio: {
        deviceId: mic.selectedDeviceId,
      },
    })
    videoRef.current.srcObject = stream
    return stream
  }, [
    camera.selectedDeviceId,
    camera.devicesState,
    mic.selectedDeviceId,
    mic.devicesState,
  ])

  useEffect(() => {
    return () => {
      streamState.value?.getTracks().forEach((track) => track.stop())
    }
  }, [streamState.value])

  return (
    <>
      {isMediaUnavailable && <MediaErrorModal />}

      <VideoRecordingBaseWithDeviceDetect
        {...props}
        videoRef={videoRef}
        stream={streamState.value}
        deviceSettingElement={
          <>
            <TertiaryButton
              comlinkPushParams={{
                action: 'click_mic_camera_setting',
              }}
              widthSize="L2"
              heightSize={spButtonHeightSize}
              onClick={micCameraSettingModal.open}
              icon={<SettingsIcon />}
            >
              音声 / 映像設定
            </TertiaryButton>
            <Modal
              active={micCameraSettingModal.active}
              title="音声 / 映像設定"
              onClose={micCameraSettingModal.close}
            >
              <Box>
                <Box
                  padding="20px"
                  display="flex"
                  alignItems="center"
                  borderBottom={`1px solid ${theme.color.gray[4]}`}
                >
                  <Box width="120px">
                    <Txt>カメラ</Txt>
                  </Box>
                  <Dropdown
                    size="m"
                    value={camera.selectedDeviceId}
                    options={
                      camera.devices
                        ? camera.devices.map((d) => ({
                            label: d.label,
                            value: d.deviceId,
                          }))
                        : []
                    }
                    onChange={(e) => {
                      camera.changeDevice(e.target.value)
                    }}
                  />
                  <Box width="130px" ml="26px">
                    <Video ref={cameraVideoRef} autoPlay muted />
                  </Box>
                </Box>
                <Box
                  padding="20px"
                  display="flex"
                  alignItems="center"
                  borderBottom={`1px solid ${theme.color.gray[4]}`}
                >
                  <Box width="120px">
                    <Txt>マイク</Txt>
                  </Box>
                  <Dropdown
                    size="m"
                    value={mic.selectedDeviceId}
                    options={
                      mic.devices
                        ? mic.devices.map((d) => ({
                            label: d.label,
                            value: d.deviceId,
                          }))
                        : []
                    }
                    onChange={(e) => {
                      mic.changeDevice(e.target.value)
                    }}
                  />
                  <Box ml="26px">
                    <AudioWave
                      width="130px"
                      height="56px"
                      audioStream={mic.stream}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderTop={`1px solid ${theme.color.gray[4]}`}
                paddingY="11px"
              >
                <Box width={144}>
                  <StretchedTertiaryButton
                    comlinkPushParams={{
                      action: 'click_close_rerecord_modal_button',
                    }}
                    onClick={micCameraSettingModal.close}
                  >
                    閉じる
                  </StretchedTertiaryButton>
                </Box>
              </Box>
            </Modal>
          </>
        }
      />
    </>
  )
}

const VideoRecordingWithoutDeviceSetting: React.VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMediaUnavailable, setIsMediaUnavailable] = useState(false)

  const streamState = useAsync(async () => {
    setIsMediaUnavailable(false)
    assert(videoRef.current)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          aspectRatio: 4 / 3,
          /**
           * NOTE: 仮想カメラを使っていると、MediaRecorderで録画した映像が指定したaspectRatioに合わせて歪んでしまうことがある。
           * streamのresizeModeが'crop-and-scale'になっていると上記の現象が起きるため、resizeModeは'none'を指定しておく。
           */
          resizeMode: 'none',
          // NOTE: lib.dom.d.tsのMediaTrackConstraintSetにresizeModeが定義されていない
        } as MediaTrackConstraintSet,
        audio: true,
      })
      videoRef.current.srcObject = stream
      return stream
    } catch {
      setIsMediaUnavailable(true)
    }
  })

  useEffect(() => {
    return () => {
      streamState.value?.getTracks().forEach((track) => track.stop())
    }
  }, [streamState.value])

  return (
    <>
      {isMediaUnavailable && <MediaErrorModal />}
      <VideoRecordingBaseWithDeviceDetect
        {...props}
        videoRef={videoRef}
        stream={streamState.value}
      />
    </>
  )
}

const VideoRecordingBaseWithDeviceDetect: React.VFC<
  Omit<VideoRecordingBaseProps, 'responsive'>
> = (props) => {
  const { responsive } = useResponsive()
  return <VideoRecordingBase {...props} responsive={responsive} />
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.darkGray[1]};
`

const SettingsIcon = styled(Icon).attrs({ name: 'settings' })`
  margin-right: 10px;
`
