import React from 'react'
import { NewTabLink, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import {
  StretchedSecondaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { Message } from '@/pages/SelectionRecInterviewPage/components/Message'
import { EXTERNAL_URLS } from '@/services/urlService'
import { ReactComponent as _TopIllust } from './top.svg'

type Props = {
  onPrev: () => void
  onNext: () => void
}

export const Top: React.VFC<Props> = (props) => {
  return (
    <Form.Wrapper>
      <Form.Body>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Txt size="xl">お試し撮影</Txt>
        </Box>
        <TopIllust />
        <Box mt="32px">
          <Message
            title="まずはお試し撮影をしましょう"
            content={
              <Txt>
                <Bold>ご利用の端末で正常に撮影ができるか</Bold>
                確認してください。
              </Txt>
            }
          />
        </Box>
        <Box mt="32px">
          <Message
            title="上手く撮影するには"
            content={
              <>
                上手く撮影するためのコツは
                <br />
                <NewTabLink
                  href={EXTERNAL_URLS.help.recInterviewTips}
                  action="open_rec_interview_tips_page"
                >
                  こちら
                </NewTabLink>
                の記事にまとめておりますのでご確認ください。
              </>
            }
          />
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          <StretchedTertiaryButton
            comlinkPushParams={{
              action: 'click_prev_on_os_browser_test_page_top',
            }}
            onClick={props.onPrev}
          >
            前に戻る
          </StretchedTertiaryButton>
          <StretchedSecondaryButton
            comlinkPushParams={{
              action: 'click_next_on_os_browser_test_page_top',
            }}
            onClick={props.onNext}
          >
            次に進む
          </StretchedSecondaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}

const TopIllust = styled(_TopIllust)`
  margin: 20px auto;
`

const Bold = styled.span`
  font-weight: bold;
`
