import { useCallback } from 'react'
import { StartRecInterviewVideoUploadRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

type Params = {
  recInterviewGuid: string
  questionGuid: string
  partsNum: number
  contentType: string
}

export function useRequestStartRecInterviewVideoUpload() {
  const requestStartRecInterviewVideoUpload = useCallback((params: Params) => {
    const req = new StartRecInterviewVideoUploadRequest()

    req.setRecInterviewGuid(params.recInterviewGuid)
    req.setRecInterviewQuestionGuid(params.questionGuid)
    req.setPartsNum(params.partsNum)
    req.setContentType(params.contentType)

    return callMyHuttBffService('startRecInterviewVideoUpload', [req])
  }, [])

  return { requestStartRecInterviewVideoUpload }
}
