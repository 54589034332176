import React, { useReducer, useEffect } from 'react'
import { theme } from '@blue-agency/rogue'
import { useParams } from 'react-router-dom'
import { Loading } from '@/components/Loading'
import { PageLayout } from '@/components/PageLayout'
import { AlreadySubmitted } from '@/pages/SelectionRecInterviewPage/AlreadySubmitted'
import { Expired } from '@/pages/SelectionRecInterviewPage/Expired'
import { Guid } from '@/pages/SelectionRecInterviewPage/types'
import { useRecInterviewInitialLoad } from '@/pages/SelectionRecInterviewPage/useRecInterviewInitialLoad'
import { useStoreStepInStorage } from '@/pages/SelectionRecInterviewPage/useStoreStepInStorage'
import { assertIsRecInterviewDefined } from '@/pages/SelectionRecInterviewPage/utils'
import { Confirm } from './Confirm'
import { EnvironmentCheck } from './EnvironmentCheck'
import { Home } from './Home'
import { Outro } from './Outro'
import { Question } from './Question'
import {
  initialState,
  reducer,
  State,
  includeAnswerLimitedQuestion,
} from './reducer'
import { useBeforeUnload } from './useBeforeUnload'

export const SelectionRecInterviewPage = () => {
  const { selectionStepGuid } = useParams<{ selectionStepGuid: Guid }>()
  const [state, dispatch] = useReducer(reducer, initialState)

  useBeforeUnload(state.recInterviewStatus, state.step)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [state.step])

  const { isLoading, data } = useRecInterviewInitialLoad(
    selectionStepGuid,
    dispatch
  )
  useStoreStepInStorage(selectionStepGuid, state.step, isLoading)

  if (isLoading || !data) return <Loading />

  assertIsRecInterviewDefined(state)

  const organizationName = state.organization.name

  if (state.recInterviewStatus === 'Expired') {
    return (
      <PageLayout
        organizationName={organizationName}
        backgroundColor={theme.color.gray[5]}
      >
        <Expired />
      </PageLayout>
    )
  }

  if (state.recInterviewStatus === 'Submitted') {
    return (
      <PageLayout organizationName={organizationName}>
        <AlreadySubmitted />
      </PageLayout>
    )
  }

  const render = (state: State) => {
    assertIsRecInterviewDefined(state)
    switch (state.step.name) {
      case 'Home':
        return (
          <Home
            dispatch={dispatch}
            {...state}
            selectionStepName={data.selectionStepName}
          />
        )
      case 'EnvironmentCheck':
        return (
          <EnvironmentCheck
            dispatch={dispatch}
            selectionStepGuid={selectionStepGuid}
            includeAnswerLimitedQuestion={includeAnswerLimitedQuestion(
              state.questions.byId
            )}
            deadline={state.deadline}
          />
        )
      case 'Question':
        const questionGuid = state.step.questionGuid
        const questionIndex = state.questions.allIds.findIndex(
          (q) => q === questionGuid
        )
        const canGoBack = questionIndex > 0
        return (
          <Question
            key={questionGuid}
            dispatch={dispatch}
            recInterviewGuid={data.guid}
            questionGuid={questionGuid}
            question={state.questions.byId[questionGuid]}
            answer={state.answers.byId[questionGuid]}
            questionCount={state.questions.allIds.length}
            questionIndex={questionIndex}
            canGoBack={canGoBack}
            allowRecordedVideo={state.allowRecordedVideo}
            selectionStepGuid={selectionStepGuid}
          />
        )
      case 'Confirm':
        return (
          <Confirm
            dispatch={dispatch}
            state={state}
            recInterviewGuid={data.guid}
            selectionStepGuid={selectionStepGuid}
          />
        )
      case 'Outro':
        return <Outro {...state} />
    }
  }

  return (
    <PageLayout organizationName={organizationName}>{render(state)}</PageLayout>
  )
}
