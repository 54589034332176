import { useEffect, useRef, useCallback, useState } from 'react'
import type { RefObject } from 'react'
import { theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { useVideoLog } from './useVideoLog'

type Props = {
  src: string
  thumbnailUrl?: string
  videoSeminarTicketGuid: string
  videoSeminarContentGuid: string
}
export const VideoPlayerForIOS: React.VFC<Props> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  /**
   * 画面遷移したときにPicture in Pictureを削除する
   * (iOSではOS標準のvideoを利用するため、PiPされる可能性がある)
   */
  useEffect(() => {
    return () => {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
      }
    }
  }, [])

  const { sendPlayLog, sendPauseLog } = useVideoLog({
    videoRef,
    videoSeminarContentGuid: props.videoSeminarContentGuid,
    videoSeminarTicketGuid: props.videoSeminarTicketGuid,
  })

  useSetupLogSenders(videoRef, sendPlayLog, sendPauseLog)

  return (
    <Video
      ref={videoRef}
      src={props.src}
      poster={props.thumbnailUrl}
      playsInline
      controls
      x-webkit-airplay="deny"
    />
  )
}

// シークバーを動かすとpauseイベントが発生するが、そのときすでに videoRef.current.currentTime は
// シーク後の位置を示す値に書き換えられてしまっているため、シーク前の位置でpauseログを送信するために、
// 時間をキャッシュしておく
class PlaybackTimeSecondsCache {
  private prev: number
  private latest: number

  constructor() {
    this.prev = 0
    this.latest = 0
  }

  update(value: number) {
    this.prev = this.latest
    this.latest = value
  }

  get(): number {
    return this.prev
  }
}

type Sender = (playbackTimeSeconds: number) => void

function useSetupLogSenders(
  videoRef: RefObject<HTMLVideoElement>,
  sendPlayLog: Sender,
  sendPauseLog: Sender
) {
  // iOS Safari では、ブラウザ埋め込みの動画プレイヤーと、フルスクリーン時の動画プレイヤーで、
  // イベント発火の仕方に細かい違いがあるため、現在どちらのプレイヤーが使われているかによって処理を分ける
  const isFullScreen = useIsFullScreen(videoRef)

  const playbackTimeSecondsCache = useRef(new PlaybackTimeSecondsCache())

  const onPlay = useCallback(() => {
    if (!videoRef.current) return
    videoRef.current.play().catch((e) => {
      // 「AbortError: The play() request was interrupted by a call to pause().」が出るのでcatchする
      if (e.name === 'AbortError') {
        return
      }
      throw e
    })
    sendPlayLog(videoRef.current.currentTime)
  }, [videoRef, sendPlayLog])

  const onPause = useCallback(() => {
    if (!videoRef.current) return
    videoRef.current.pause()
    sendPauseLog(playbackTimeSecondsCache.current.get())
  }, [videoRef, sendPauseLog])

  // 動画が進行したとき、ログ送信用にキャッシュしている値と同期する
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    const syncCurrentTime = () => {
      playbackTimeSecondsCache.current.update(ref.currentTime)
    }
    ref.addEventListener('timeupdate', syncCurrentTime)

    return () => {
      ref.removeEventListener('timeupdate', syncCurrentTime)
    }
  }, [videoRef])

  // 再生したとき、playログを送る
  // 再生中にシークバーを操作した時、シーク後にplayイベントが発火し、このハンドラで処理される
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    ref.addEventListener('play', onPlay)

    return () => {
      ref.removeEventListener('play', onPlay)
    }
  }, [videoRef, onPlay])

  // 一時停止したとき、pauseログを送る
  // NOTE: ブラウザ内プレイヤーの場合、再生中にシークバーを操作したときにもpauseイベントが発火し、このハンドラで処理される
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    ref.addEventListener('pause', onPause)

    return () => {
      ref.removeEventListener('pause', onPause)
    }
  }, [videoRef, onPause])

  // フルスクリーン動画プレイヤーを使っている時、再生中にシークしたら、pause + playログを送る
  // NOTE: ブラウザ内プレイヤーの場合は、再生中にシークしたらpause + playイベントが発火するので、このuseEffect内では何もしないようにする
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    let seeking = false

    const sendOnSeekStart = () => {
      if (!isFullScreen) return
      // 一時停止中のシークはログを送る必要がない
      if (ref.paused) return
      if (seeking) return

      // シークバーをぐりぐりと動かしている間は絶え間なく seeking イベントが発火する
      // 初回の seeking のみ対応すれば良いため、対応済みを示すフラグを用意する
      seeking = true
      // プレイヤー組み込みの15秒シークを使ったときに正しい値を送るためにはキャッシュを利用する必要がある
      sendPauseLog(playbackTimeSecondsCache.current.get())
    }

    const sendOnSeekEnd = () => {
      if (!isFullScreen) return
      // 一時停止中のシークはログを送る必要がない
      if (ref.paused) return

      seeking = false
      sendPlayLog(ref.currentTime)
    }

    ref.addEventListener('seeking', sendOnSeekStart)
    ref.addEventListener('seeked', sendOnSeekEnd)

    return () => {
      ref.removeEventListener('seeking', sendOnSeekStart)
      ref.removeEventListener('seeked', sendOnSeekEnd)
    }
  }, [isFullScreen, videoRef, sendPlayLog, sendPauseLog])

  // 動画を最後まで見たとき、以下の処理を行いたい
  // 1. 動画の再生を停止
  // 2. pauseログを送る
  // 3. 0秒地点に戻る
  // ただし、動画を最後まで見た時はpauseイベントも発火するため、1と2は処理する必要がない
  // 3のみendedイベントで処理する
  useEffect(() => {
    const ref = videoRef.current

    const onEnded = () => {
      if (ref === null) return
      ref.currentTime = 0
    }
    ref?.addEventListener('ended', onEnded)

    return () => {
      ref?.removeEventListener('ended', onEnded)
    }
  }, [videoRef, onPause])
}

function useIsFullScreen(videoRef: RefObject<HTMLVideoElement>): boolean {
  const [isFullScreen, setIsFullScreen] = useState(false)

  // フルスクリーンの動画プレイヤーを使っているか否かの状態を更新する
  useEffect(() => {
    const ref = videoRef.current
    if (ref === null) return

    const begin = () => {
      setIsFullScreen(true)
    }

    const end = () => {
      setIsFullScreen(false)
    }

    ref.addEventListener('webkitbeginfullscreen', begin)
    ref.addEventListener('webkitendfullscreen', end)

    return () => {
      ref.removeEventListener('webkitbeginfullscreen', begin)
      ref.removeEventListener('webkitendfullscreen', end)
    }
  }, [videoRef])

  return isFullScreen
}

const Video = styled.video`
  width: 100%;
  background-color: ${theme.color.darkGray[1]};
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          height: 345px;
          max-height: 345px;
        `
      : css`
          height: 188px;
          max-height: 188px;
        `}
`
