import React from 'react'
import {
  Guid,
  RecInterviewAnswer,
  RecInterviewQuestion,
  WithDispatch,
} from '@/pages/SelectionRecInterviewPage/types'
import { TextQuestion } from './TextQuestion'
import { VideoQuestion } from './VideoQuestion'

type Props = WithDispatch<{
  recInterviewGuid: Guid
  selectionStepGuid: Guid
  questionGuid: Guid
  question: RecInterviewQuestion
  answer: RecInterviewAnswer | undefined
  questionIndex: number
  questionCount: number
  allowRecordedVideo: boolean
  canGoBack: boolean
}>

export const Question: React.VFC<Props> = (props) => {
  if (props.question.kind === 'Text') {
    if (props.answer === undefined || props.answer.kind === 'Text') {
      return <TextQuestion {...props} answer={props.answer} />
    }
  }
  if (props.question.kind === 'Video') {
    if (props.answer === undefined || props.answer.kind === 'Video') {
      return (
        <VideoQuestion
          {...props}
          question={props.question}
          answer={props.answer}
        />
      )
    }
  }
  throw new Error('Invalid question.kind')
}
