import { useCallback } from 'react'
import { CreateVideoSeminarTicketForGuestRequest } from '@blue-agency/proton/web/v2/my_hutt_bff'
import { callMyHuttBffService } from './bffClient'

export function useRequestCreateVideoSeminarTicketForGuest() {
  const requestCreateVideoSeminarTicketForGuest = useCallback(
    (videoSeminarGuid: string) => {
      const req = new CreateVideoSeminarTicketForGuestRequest()
      req.setVideoSeminarGuid(videoSeminarGuid)

      return callMyHuttBffService('createVideoSeminarTicketForGuest', [req])
    },
    []
  )

  return { requestCreateVideoSeminarTicketForGuest }
}
