import styled from 'styled-components'

/** 下部固定エリアのボタン群のWrapper */
export const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.theme.contentWidth};
  & > *:nth-child(n + 2) {
    margin-left: 8px;
  }
  margin-top: 12px;
  margin-bottom: 8px;
`
