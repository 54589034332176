import { useMutation } from 'react-query'
import { useRequestCreateVideoSeminarTicketForGuest } from '@/services/bffService'

export const useCreateVideoSeminarTicketForGuest = (
  videoSeminarGuid: string
) => {
  const { requestCreateVideoSeminarTicketForGuest } =
    useRequestCreateVideoSeminarTicketForGuest()

  return useMutation(() =>
    requestCreateVideoSeminarTicketForGuest(videoSeminarGuid)
  )
}
