import { initImSharedFront } from '@blue-agency/im-shared-front'
import assert from 'assert'

export function initializeImSharedFront() {
  const bffHost = process.env.REACT_APP_API_HOST
  assert(bffHost)

  initImSharedFront({
    applicationName: 'my-hutt-front',
    host: {
      bizAnakinFront: '',
      bizHuttFront: '',
      myHuttFront: '',
      skywalkerFront: '',
      bff: bffHost,
    },
    logoutEndpoint: '',
    openidClientId: '',
    openidConfigurationEndpoint: '',
    signInPathPrefix: '',
    techtouch: {
      organizationUuid: '',
      administratorProperty: '',
      normalProperty: '',
      assistantProperty: '',
      userIdProperty: '',
      organizationIdProperty: '',
    },
  })
}
