import { useState } from 'react'
import { useAsync } from 'react-use'

const defaultLogoHeight = '42px'

export const useLogoHeight = (src?: string) => {
  const [logoHeight, setLogoHeight] = useState(defaultLogoHeight)

  useAsync(async () => {
    if (src === undefined) return
    const height = await getLogoHeight(src)
    setLogoHeight(height)
  }, [src])

  return { logoHeight }
}

const getLogoHeight = async (src: string) => {
  const img = await loadImage(src)
  if (img.width / img.height >= 2.0) {
    return '21px'
  } else {
    return '42px'
  }
}

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
    img.src = src
  })
}
