import { useEffect } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { State } from './reducer'

type Props = {
  state: State
  onTickCountdown: () => void
  onFinishCountdown: () => void
}

export const CountdownOverlay: React.VFC<Props> = ({
  state,
  onTickCountdown,
  onFinishCountdown,
}) => {
  useCountdown(state, onTickCountdown, onFinishCountdown)
  return (
    <Wrapper>
      {state.countdown > 0 && <Number>{state.countdown}</Number>}
    </Wrapper>
  )
}

function useCountdown(
  state: State,
  onTickCountdown: () => void,
  onFinishCountdown: () => void
) {
  useEffect(() => {
    if (state.status !== 'countdown') return

    const id = setInterval(() => {
      onTickCountdown()
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [onTickCountdown, state.status])

  useEffect(() => {
    if (state.countdown === 0) {
      onFinishCountdown()
    }
  }, [onFinishCountdown, state.countdown])
}

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Number = styled.p`
  color: ${theme.color.white[1]};
  font-size: 72px;
`
