import { PrimaryButton, spButtonHeightSize } from '@blue-agency/rogue/im'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { BottomButtons } from '@/components/BottomButtons'
import { Box } from '@/components/Box'
import { FixedBottomForm as Form } from '@/components/FixedBottomForm'
import { Spacer } from '@/components/Spacer'
import {
  StretchedSecondaryButton,
  StretchedTertiaryButton,
} from '@/components/StretchedButton'
import { RecInterviewQuestion } from '@/pages/SelectionRecInterviewPage/types'
import {
  VideoAnswerLimitationSetting,
  VideoAnswerLimitation,
} from '@/pages/SelectionRecInterviewPage/types'
import { Question } from './Question'

type Props = PropsWithClassName<{
  questionIndex: number
  questionCount: number
  question: RecInterviewQuestion
  limitation: VideoAnswerLimitationSetting & VideoAnswerLimitation
  canGoBack?: boolean
  onStartAnswerButtonClick: () => void
}>
export const LimitedQuestion: React.VFC<Props> = (props) => {
  return (
    <Form.Wrapper>
      <Form.Body>
        <Question
          questionIndex={props.questionIndex}
          questionCount={props.questionCount}
          question={props.question}
          limitation={props.limitation}
          hideQuestion
        />
        <Spacer mb="20px" />
        <Box display="flex" justifyContent="center">
          <PrimaryButton
            widthSize="L1"
            heightSize={spButtonHeightSize}
            comlinkPushParams={{
              action: 'click_start_video_question_answer',
            }}
            onClick={props.onStartAnswerButtonClick}
          >
            設問を表示
          </PrimaryButton>
        </Box>
      </Form.Body>
      <Form.Bottom>
        <BottomButtons>
          {props.canGoBack && (
            <StretchedTertiaryButton
              comlinkPushParams={{
                action:
                  'click_back_on_rec_interview_video_question_limitation_page',
              }}
              disabled
            >
              前に戻る
            </StretchedTertiaryButton>
          )}
          <StretchedSecondaryButton
            comlinkPushParams={{
              action:
                'click_next_on_rec_interview_video_question_limitation_page',
            }}
            disabled
          >
            次に進む
          </StretchedSecondaryButton>
        </BottomButtons>
      </Form.Bottom>
    </Form.Wrapper>
  )
}
