import * as yup from 'yup'

const TEXT_CONTENT_MAX_LEN = 4000

const requiredMessage = '入力してください'
const maxMessage = '4000文字以下で入力してください'

export const optionalTextValidator = yup.object().shape({
  content: yup.string().min(0).max(TEXT_CONTENT_MAX_LEN, maxMessage),
})

export const requiredTextValidator = yup.object().shape({
  content: yup
    .string()
    .required(requiredMessage)
    .max(TEXT_CONTENT_MAX_LEN, maxMessage),
})
