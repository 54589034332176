import React from 'react'
import { useMemo } from 'react'
import { theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { Status } from './reducer'

type Props = {
  status: Status
  onCaptureEnd: () => void
}

const noop = () => {}

export const CaptureButton: React.VFC<Props> = (props) => {
  const [InnerComponent, onClick] = useMemo(() => {
    switch (props.status) {
      case 'loading':
        return [InnerBoxCountdown, noop]
      case 'countdown':
        return [InnerBoxCountdown, noop]
      case 'capturing':
        return [InnerBoxCapturing, props.onCaptureEnd]
      case 'captured':
        return [InnerCircle, noop]
    }
  }, [props.onCaptureEnd, props.status])
  return (
    <Wrapper
      onClick={onClick}
      disabled={props.status === 'loading' || props.status === 'countdown'}
    >
      <InnerComponent />
    </Wrapper>
  )
}

const Wrapper = styled.button<{ disabled: boolean }>`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  padding: 4px;
  background-color: transparent;
  border: 2px solid ${theme.color.white[1]};
  box-shadow: 0px 0px 2px ${theme.color.black[1]},
    0px 0px 2px ${theme.color.black[1]} inset;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
`

const InnerCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${theme.color.red[2]};
  box-shadow: 0px 0px 2px ${theme.color.black[1]};
`

const innerBoxBaseStyle = css`
  width: 50%;
  height: 50%;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0px 0px 2px ${theme.color.black[1]};
`

const InnerBoxCapturing = styled.div`
  ${innerBoxBaseStyle}
  background-color: ${theme.color.red[2]};
`

const InnerBoxCountdown = styled.div`
  ${innerBoxBaseStyle}
  background-color: ${theme.color.red[3]};
`
