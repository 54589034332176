import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Loading } from '@/components/Loading'
import {
  mapProtoToVideoSeminar,
  useGetVideoSeminar,
  useGetVideoSeminarTicketFromQueryParam,
} from '@/services/videoSeminarService'
import { AgreementModal } from './AgreementModal'
import { VideoSeminarTop as _VideoSeminarTop } from './VideoSeminarTop'
import { useAgreement } from './useAgreement'

type Props = {
  videoSeminarTicketGuid: string
}
export const VideoSeminarTopPageWithTicket: React.VFC<Props> = ({
  videoSeminarTicketGuid,
}) => {
  const { videoSeminarGuid } = useParams<{ videoSeminarGuid: string }>()
  const { data, isLoading } = useGetVideoSeminar({
    videoSeminarGuid,
    videoSeminarTicketGuid,
  })

  const { videoSeminarGuidFromQueryParam } =
    useGetVideoSeminarTicketFromQueryParam()

  const isGuest = useMemo(() => {
    return !videoSeminarGuidFromQueryParam
  }, [videoSeminarGuidFromQueryParam])

  const videoSeminar = useMemo(() => {
    if (data === undefined) return data
    return mapProtoToVideoSeminar(data, videoSeminarGuid)
  }, [data, videoSeminarGuid])

  const { isAgreed, agree } = useAgreement(videoSeminarGuid)

  if (isLoading || !videoSeminar) return <Loading />

  return (
    <>
      <VideoSeminarTop
        videoSeminar={videoSeminar}
        $isAgreed={isAgreed}
        isGuest={isGuest}
      />
      {!isAgreed && (
        <AgreementModal
          guid={videoSeminarGuid}
          title={videoSeminar.title}
          organization={videoSeminar.organization}
          agree={agree}
          isGuest={isGuest}
        />
      )}
    </>
  )
}

const VideoSeminarTop = styled(_VideoSeminarTop)<{ $isAgreed: boolean }>`
  /* 同意モーダル表示時は背景スクロールを無効にする */
  ${(props) =>
    !props.$isAgreed &&
    css`
      overflow: hidden;
    `}
`
