import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import assert from 'assert'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import { Guid, WithDispatch } from '@/pages/SelectionRecInterviewPage/types'
import { Complete } from './Complete'
import { OSBrowserTest } from './OSBrowserTest'
import { TestQuestionTutorial } from './TestQuestionTutorial'
import { TestRecording } from './TestRecording'
import { TestRecordingConfirm } from './TestRecordingConfirm'
import { TestRecordingReady } from './TestRecordingReady'
import { Top } from './Top'

type Props = WithDispatch<{
  selectionStepGuid: Guid
  deadline: NonNullable<State['deadline']>
  includeAnswerLimitedQuestion: boolean
}>

type Step =
  | 'Top'
  | 'OSBrowserTest'
  | 'TestRecordingReady'
  | 'TestQuestionTutorial'
  | 'TestRecording'
  | 'TestRecordingConfirm'
  | 'Complete'

export const EnvironmentCheck: React.VFC<Props> = ({
  selectionStepGuid,
  deadline,
  includeAnswerLimitedQuestion,
  dispatch,
}) => {
  const [step, setStep] = useState<Step>('Top')
  const [testCaptureBlob, setTestCaptureBlob] = useState<Blob>()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [step])

  const onTestCaptureComplete = useCallback((blob: Blob) => {
    setTestCaptureBlob(blob)
    setStep('TestRecordingConfirm')
  }, [])

  const onRecaptureRequest = useCallback(() => {
    setStep('TestRecording')
    setTestCaptureBlob(undefined)
  }, [])

  switch (step) {
    case 'Top':
      return (
        <Top
          onPrev={() => dispatch(['GO_BACK'])}
          onNext={() => setStep('OSBrowserTest')}
        />
      )
    case 'OSBrowserTest':
      return <OSBrowserTest onNext={() => setStep('TestRecordingReady')} />
    case 'TestRecordingReady':
      return (
        <TestRecordingReady onNext={() => setStep('TestQuestionTutorial')} />
      )
    case 'TestQuestionTutorial':
      return (
        <TestQuestionTutorial
          onNext={() => setStep('TestRecording')}
          hasLimitation={includeAnswerLimitedQuestion}
        />
      )
    case 'TestRecording':
      return (
        <TestRecording
          onTestRecordingComplete={onTestCaptureComplete}
          onClose={() => setStep('TestRecordingReady')}
          includeAnswerLimitedQuestion={includeAnswerLimitedQuestion}
        />
      )
    case 'TestRecordingConfirm':
      assert(testCaptureBlob)
      return (
        <TestRecordingConfirm
          testRecordingBlob={testCaptureBlob}
          onReRecordRequest={onRecaptureRequest}
          onNext={() => setStep('Complete')}
        />
      )
    case 'Complete':
      return (
        <Complete
          dispatch={dispatch}
          selectionStepGuid={selectionStepGuid}
          includeAnswerLimitedQuestion={includeAnswerLimitedQuestion}
          deadline={deadline}
        />
      )
    default:
      return null
  }
}
