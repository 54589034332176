import React, { useState, useCallback } from 'react'
import { theme, Txt, Icon, Responsive } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  index: number
  content: string
  note?: string
  responsive: Responsive
}>

export const Question: React.VFC<Props> = (props) => {
  const [isQuestionOpened, setIsQuestionOpened] = useState(false)
  const toggleIsQuestionOpened = useCallback(() => {
    setIsQuestionOpened((prev) => !prev)
  }, [])

  const maxContentLength = props.responsive.spPortrait ? 13 : 22

  const shortenedRes = shorten(props.content, maxContentLength)

  const showArrowIcon = shortenedRes.isShortened || props.note

  return isQuestionOpened ? (
    <OpenedWrapper className={props.className} onClick={toggleIsQuestionOpened}>
      <QuestionIndex>問 {props.index + 1}</QuestionIndex>
      <ContentWrapper>
        <ContentTxt>{props.content}</ContentTxt>
        {props.note && <NoteTxt>{props.note}</NoteTxt>}
      </ContentWrapper>
      {showArrowIcon && (
        <ArrowIconWrapper>
          <ArrowIcon isOpened />
        </ArrowIconWrapper>
      )}
    </OpenedWrapper>
  ) : (
    <ClosedWrapper className={props.className} onClick={toggleIsQuestionOpened}>
      <QuestionIndex color={theme.color.white[1]}>
        問 {props.index + 1}
      </QuestionIndex>
      <ContentTxt color={theme.color.white[1]}>{shortenedRes.str}</ContentTxt>
      {showArrowIcon && (
        <ArrowIconWrapper>
          <ArrowIcon />
        </ArrowIconWrapper>
      )}
    </ClosedWrapper>
  )
}

export const shorten = (
  original: string,
  maxLength: number
): { isShortened: boolean; str: string } => {
  // 改行ありの場合は一行目のみ表示
  const splittedStr = original.split(/\n/)
  const hasNewLine = splittedStr.length > 1

  // maxLength+1文字以上の場合はmaxLength文字まで＋'...'
  return splittedStr[0].length > maxLength
    ? {
        isShortened: true,
        str: splittedStr[0].slice(0, maxLength) + '...',
      }
    : {
        isShortened: hasNewLine,
        str: hasNewLine ? splittedStr[0] + '...' : splittedStr[0],
      }
}

const wrapperBaseStyle = css`
  display: inline-flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px 8px;
  cursor: pointer;
`

const OpenedWrapper = styled.div`
  ${wrapperBaseStyle};
  background-color: ${theme.color.white[1]}CC;
  color: ${theme.color.navy[1]};
  text-shadow: 1px 1px 1px ${theme.color.white[1]}80,
    -1px -1px 1px ${theme.color.white[1]}80,
    -1px 1px 1px ${theme.color.white[1]}80,
    1px -1px 1px ${theme.color.white[1]}80;
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        width: 460px;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        width: 400px;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        width: 82vw;
        max-width: 288px;
      `
    }
  }}
  max-height: 200px;
  overflow-y: auto;
`

const ClosedWrapper = styled.div`
  ${wrapperBaseStyle};
  background-color: ${theme.color.navy[1]};
  color: ${theme.color.white[1]};
  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        width: 460px;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        width: 400px;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        width: 82vw;
        max-width: 288px;
      `
    }
  }}
`

const QuestionIndex = styled(Txt)`
  min-width: 34px;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const ContentWrapper = styled.div`
  margin-left: 10px;
  margin-right: 13px;
  flex-grow: 1;
`

const ContentTxt = styled(Txt).attrs({
  bold: true,
})`
  flex-grow: 1;
  word-break: break-all;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const NoteTxt = styled(Txt)`
  margin-top: 10px;
  word-break: break-all;
  ${(props) =>
    props.theme.responsive.pc
      ? css`
          font-size: ${theme.fontSize.l};
        `
      : css`
          font-size: ${theme.fontSize.m};
        `}
`

const ArrowIconWrapper = styled.div``
const ArrowIcon = styled(Icon).attrs({
  width: '21px',
  height: '24px',
  name: 'arrow-down',
})<{ isOpened?: boolean }>`
  margin-left: 4px;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`
