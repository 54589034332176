import React from 'react'
import styled from 'styled-components'
import { VideoRecording } from '@/components/VideoRecording'

type Props = {
  onTestRecordingComplete: (blob: Blob) => void
  onClose: () => void
  includeAnswerLimitedQuestion: boolean
}

export const TestRecording: React.VFC<Props> = (props) => {
  return (
    <Overlay>
      <VideoRecording
        onRecordingComplete={props.onTestRecordingComplete}
        onClose={props.onClose}
        showTutorial
        question={{
          index: -1, //「問0」を表示する
          content:
            'サンプル設問:10秒を目安に自由に喋っていただき、撮影動画を確認してください。\nお試し撮影では、何回でも撮影をお試しいただけます。\n※お試し撮影の内容は、応募先企業に送信されることはありません。',
        }}
        isTest
        hasAnswerLimitation={props.includeAnswerLimitedQuestion}
        onStartLimitationRecording={() => {}}
        limitation={
          props.includeAnswerLimitedQuestion
            ? {
                // NOTE:制限付き設問を含む場合にチュートリアルを出すためサンプルの制限を表示する
                enableAnswer: true,
                maxDurationSeconds: 300,
                remainsDurationSeconds: 300,
                maxRecCount: 3,
                remainsRecCount: 3,
              }
            : undefined
        }
      />
    </Overlay>
  )
}

const Overlay = styled.div`
  width: 100vw;
  height: 100%;
  background-color: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
