import { useEffect } from 'react'
import { State } from '@/pages/SelectionRecInterviewPage/reducer'
import { Guid } from '@/pages/SelectionRecInterviewPage/types'
import { LOCAL_STORAGE_KEY } from '@/services/storageService'

export const useStoreStepInStorage = (
  selectionStepGuid: Guid,
  step: State['step'],
  isLoading: boolean
) => {
  useEffect(() => {
    if (isLoading) {
      return
    }
    const key = LOCAL_STORAGE_KEY.recInterviewStep.replace(
      ':selectionStepGuid',
      selectionStepGuid
    )
    localStorage.setItem(key, JSON.stringify(step))
  }, [selectionStepGuid, step, isLoading])
}
