import * as Sentry from '@sentry/react'
import { LOCAL_STORAGE_KEY } from '@/services/storageService'
import { Guid, TextAnswer, VideoAnswer } from './types'

export function getStoredAnswers(
  selectionStepGuid: Guid
): Record<Guid, TextAnswer | VideoAnswer> {
  const key = buildStorageKey(selectionStepGuid)
  const item = getObjectFromLocalStorage(key)

  if (item === null) {
    return {}
  }
  return item
}

export const storeAnswerOnLocalStorage = (
  selectionStepGuid: Guid,
  questionGuid: Guid,
  answer: TextAnswer | VideoAnswer
) => {
  const key = buildStorageKey(selectionStepGuid)

  const storedValue = getObjectFromLocalStorage(key)

  const newValue = {
    ...storedValue,
    [questionGuid]: answer,
  }
  localStorage.setItem(key, JSON.stringify(newValue))
}

export const clearStoredAnswers = (selectionStepGuid: Guid) => {
  const key = buildStorageKey(selectionStepGuid)
  localStorage.removeItem(key)
}

const buildStorageKey = (selectionStepGuid: Guid) => {
  return LOCAL_STORAGE_KEY.recInterviewAnswers.replace(
    ':selectionStepGuid',
    selectionStepGuid
  )
}

const getObjectFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key)

  if (item === null) {
    return null
  }

  try {
    return JSON.parse(item)
  } catch (e) {
    Sentry.captureException(e)

    return null
  }
}
